import React from "react";
import { Axios } from ".././middleware/axiosInstance";
import { Button, Select, TextField } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import { RenderCellExpand } from "./renderCellExpand";
import Autocomplete from "@material-ui/lab/Autocomplete";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];
function getAllCustomers(hook, customer) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/customer/get/all`,
  }).then((res) => {
    if (!res.data.err) {
      console.log(res.data);
      let dataSort = res.data.data.sort((a, b) => (a.name > b.name ? 1 : -1));
      console.log(dataSort);
      hook.setState({ customers: dataSort });
      let customersIds = res.data.data.map((prod) => {
        return [prod.clientIdSubiekt];
      });
      hook.setState({ allCustomersIds: customersIds });
      if (customer) {
        hook.setState({ customerId: customer.id });
        hook.setState({ customer: customer });
      }
    } else {
      console.log(res.data.msg);
    }
  });
}
function getExtCecha() {
  //hook.setState({ loading: true });
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/product/update/external/unitsByCecha`,
  })
    .then((res) => {
      alert(res.data.msg);
    })
    .catch((err) => {
      alert(err);
    });
}

export default class AdvancedGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      customer: {},
      allCustomersIds: [],
      customerId: 0,
    };
  }
  componentDidMount() {
    getAllCustomers(this);
  }

  render() {
    return (
      <div>
        <h2>Zaawansowane</h2>
        <br />
        <Button
          variant="outlined"
          onClick={() => {
            if (window.confirm("Dotychczasowe jednostki zostaną nadpisane!")) {
              getExtCecha();
            }
          }}
          color="primary"
        >
          Pobierz jednostki z ERP
        </Button>
        &nbsp;
        {/* <Select
          value={this.customers}
          onChange={(e) => {
            console.log(e.target.value);
          }}
          inputProps={{
            name: "age",
            id: "age-native-simple",
          }}
        >
          {this.customers.map((customer) => (
            <option value={customer.id}>{customer.name}</option>
          ))}
        </Select> */}
      </div>
    );
  }
}
