import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";

import InvitationPickUp from "./components/invitation/InvitationPickUp";
import SignUp from "./components/SignUp";
import SignInClient from "./components/SignInClient";
import AppHandler from "./AppHandler";
import ForgottenPassword from "./components/ForgottenPassword";
import ChangePassword from "./components/ChangePassword";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
export default function ParamsExample() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
          action: {
            active: "#ff6634",
          },
          primary: {
            main: "#ff6634",
          },
          // white: {
          //   main: "ffffff",
          // },
        },
        typography: {
          fontFamily: ["Lato", "sans-serif"].join(","),
        },
      }),
    [prefersDarkMode]
  );
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div>
          <Switch>
            <Route path="/invitation/:token" children={<InvitationPickUp />} />
            <Route path="/signup/:token" children={<SignUp />} />
            <Route path="/client" children={<SignInClient />} />
            <Route
              path="/forgottenPassowordClient"
              children={<ForgottenPassword />}
            />{" "}
            <Route
              path="/changePassword/:token"
              children={<ChangePassword />}
            />
            <Route path="/">
              <AppHandler />
            </Route>
          </Switch>
        </div>
      </Router>
    </ThemeProvider>
  );
}
