import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Menu from "./components/Menu";
import Greet from "./components/home/Greet";
import moment from "moment";
import "moment/locale/pl";
import Badge from "@material-ui/core/Badge";
import { Axios, AxiosAlert } from "./components/middleware/axiosInstance";
import SignIn from "./components/SignIn";
import SchedulerWrapper from "./components/SchedulerWrapper";
import InvitationsGrid from "./components/invitation/InvitationsGrid";
import ClientsGrid from "./components/client/ClientsGrid";
import EmployeeGrid from "./components/delivery/employeeGrid";
import DocumentGrid from "./components/delivery/documentsGrid";
import ErrorGrid from "./components/admin/ErrorGrid";
import LogGrid from "./components/admin/LogGrid";
import ChangeLogGrid from "./components/admin/ChangeLogGrid";
import CarsGrid from "./components/delivery/carsGrid";
import Hidden from "@material-ui/core/Hidden";
import NextWindows from "./components/scheduler/NextWindows";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  createMuiTheme,
  ThemeProvider,
  StylesProvider,
} from "@material-ui/core/styles";
import {
  createTheme as createThemeV5,
  ThemeProvider as ThemeProviderV5,
} from "@mui/material/styles";
import CurrentWindow from "./components/scheduler/CurrentWindow";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MobileNavigation from "./components/MobileNavigation";
import WaitingNotificationsGrid from "./components/notification/WaitingNotificationsGrid";
import SentNotificationsGrid from "./components/notification/SentNotificationsGrid";
import SalesClientsGrid from "./components/sales_customers/SalesCustomersGrid";
import SalesSettingsGrid from "./components/sales_settings/SalesSettingsGrid";
import SchedulerPreview from "./components/scheduler_preview/SchedulerPreview";
import { useTheme } from "@material-ui/core/styles";
import AdvancedGrid from "./components/admin/Advanced";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { createGenerateClassName } from "@material-ui/core/styles";

const generateClassName = createGenerateClassName({
  // By enabling this option, if you have non-MUI elements (e.g. `<div />`)
  // using MUI classes (e.g. `.MuiButton`) they will lose styles.
  // Make sure to convert them to use `styled()` or `<Box />` first.
  disableGlobal: true,
  // Class names will receive this seed to avoid name collisions.
  seed: "mui-jss",
});
LicenseInfo.setLicenseKey(require(__dirname + "/config.json")["MUI_LICENCE"]);
require("dotenv").config();
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require(__dirname + "/config.json")["API_HOST"][env];

function LogOut() {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/logout`,
  }).then((res) => {
    ReactDOM.render(
      <React.StrictMode>
        <SignIn />
      </React.StrictMode>,
      document.getElementById("root")
    );
  });
}

function getResources(setResources) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/ramp/get/all`,
  }).then((res) => {
    let resources = [];
    res.data.data.forEach((resource) => {
      resources[resource.id] = resource;
    });
    setResources(resources);
  });
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://bialpak.pl/">
        BPdev
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    //backgroundColor: theme.palette.background.default,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    fontSize: 26,
    fontWeight: 600,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",

    overflow: "overlay",
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: "auto",
  },
  fullWidth: {
    width: "100%",
  },
  tabRoot: {
    width: "100%",
    // boxShadow:
    //   "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    borderRadius: 4,
  },
  tabsAppBar: {
    boxShadow: "none",
  },
}));

const StyledPaper = withStyles((ChipTheme) => ({
  root: {
    background: "linear-gradient(45deg, #ff6634 30%, #FF8E53 90%)",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    height: "auto",
  },
}))(Paper);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

moment.locale("pl");
export default function Dashboard() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [showGreet, setShowGreet] = useState(true);
  const [showScheduler, setShowScheduler] = useState(false);
  const [showDeliveries, setShowDeliveries] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);
  const [showWindowsPreview, setShowWindowsPreview] = useState(false);
  const [showSalesClients, setShowSalesClients] = useState(false);
  const [showSalesSettings, setShowSalesSettings] = useState(false);
  const [resource, setResource] = useState({ id: 1 });
  const [resources, setResources] = useState([]);
  const [resourceUpdateCount, setResourceUpdateCount] = useState(0);
  const [invitationUpdateCount, setInvitationUpdateCount] = useState(0);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const handleSetResource = (value) => {
    let resource = value;
    resource.updateCount = resourceUpdateCount + 1;
    setResourceUpdateCount(resourceUpdateCount + 1);
    setResource(resource);
  };
  const handleSetInvitationCount = () => {
    setInvitationUpdateCount(invitationUpdateCount + 1);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getResources(setResources);
  }, []);

  function toggleBox(box) {
    switch (box) {
      case "windows":
        setShowGreet(false);
        setShowScheduler(true);
        setShowDeliveries(false);
        setShowAdmin(false);
        setShowWindowsPreview(false);
        setShowSalesClients(false);
        setShowSalesSettings(false);
        break;
      case "windows_preview":
        setShowGreet(false);
        setShowScheduler(false);
        setShowDeliveries(false);
        setShowAdmin(false);
        setShowWindowsPreview(true);
        setShowSalesClients(false);
        setShowSalesSettings(false);
        break;
      case "sales_clients":
        setShowGreet(false);
        setShowScheduler(false);
        setShowDeliveries(false);
        setShowAdmin(false);
        setShowWindowsPreview(false);
        setShowSalesClients(true);
        setShowSalesSettings(false);
        break;
      case "sales_settings":
        setShowGreet(false);
        setShowScheduler(false);
        setShowDeliveries(false);
        setShowAdmin(false);
        setShowWindowsPreview(false);
        setShowSalesClients(false);
        setShowSalesSettings(true);
        break;
      case "home":
        setShowGreet(true);
        setShowScheduler(false);
        setShowDeliveries(false);
        setShowAdmin(false);
        setShowWindowsPreview(false);
        setShowSalesClients(false);
        setShowSalesSettings(false);
        break;
      case "deliveries":
        setShowScheduler(false);
        setShowGreet(false);
        setShowDeliveries(true);
        setShowAdmin(false);
        setShowSalesClients(false);
        setShowWindowsPreview(false);
        setShowSalesSettings(false);
        break;
      case "admin":
        setShowScheduler(false);
        setShowGreet(false);
        setShowDeliveries(false);
        setShowAdmin(true);
        setShowSalesClients(false);
        setShowWindowsPreview(false);
        setShowSalesSettings(false);
        break;
      default:
        break;
    }
  }

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
          primary: {
            main: "#ff6634",
          },
          white: {
            main: "ffffff",
          },
        },
        typography: {
          fontFamily: ["Lato", "sans-serif"].join(","),
        },
      }),
    [prefersDarkMode]
  );

  const themeV5 = React.useMemo(
    () =>
      createThemeV5({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
          primary: {
            main: "#ff6634",
          },
          white: {
            main: "ffffff",
          },
        },
        typography: {
          fontFamily: ["Lato", "sans-serif"].join(","),
        },
      }),
    [prefersDarkMode]
  );

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="absolute"
            className={clsx(classes.appBar, open && classes.appBarShift)}
            color={prefersDarkMode ? "#121212" : "#fff"}
            sx={{ backgroundColor: prefersDarkMode ? "#121212" : "#fff" }}
          >
            <Toolbar className={classes.toolbar}>
              <Hidden xsDown>
                <IconButton
                  edge="start"
                  color="primary"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  className={clsx(
                    classes.menuButton,
                    open && classes.menuButtonHidden
                  )}
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>

              <Typography
                component="h1"
                variant="h6"
                color="primary"
                noWrap
                className={classes.title}
              >
                Bialpak - Platforma
              </Typography>

              <IconButton aria-label="logout" onClick={LogOut} color="primary">
                <ExitToAppIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Hidden xsDown>
            <Drawer
              variant="permanent"
              classes={{
                paper: clsx(
                  classes.drawerPaper,
                  !open && classes.drawerPaperClose
                ),
              }}
              open={open}
            >
              <div className={classes.toolbarIcon}>
                <IconButton onClick={handleDrawerClose}>
                  <ChevronLeftIcon />
                </IconButton>
              </div>
              <Divider />
              <List>
                <Menu showBox={(box) => toggleBox(box)} />
              </List>
            </Drawer>
          </Hidden>

          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="xl" className={classes.container}>
              <Grid container spacing={0}>
                {showGreet && (
                  <Grid item xs={12} md={12} lg={12}>
                    <Greet />
                  </Grid>
                )}
                {showScheduler && (
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4} lg={4}>
                        <Paper className={fixedHeightPaper}>
                          <CurrentWindow
                            resource={resource}
                            resourceSetter={handleSetResource}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={4} lg={4}>
                        <Paper className={fixedHeightPaper}>
                          <NextWindows resource={resource} />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={4} lg={4}>
                        {/* <StyledPaper className={fixedHeightPaper}>
                        <WindowsStats />
                      </StyledPaper> */}
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <Paper className={fixedHeightPaper}>
                          <SchedulerWrapper
                            refreshResource={() => {
                              handleSetResource(resource);
                            }}
                            refreshInvitations={() => {
                              handleSetInvitationCount();
                            }}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <Paper className={fixedHeightPaper}>
                          <ClientsGrid />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <div
                          className={classes.tabRoot}
                          style={{
                            backgroundColor:
                              theme.palette.type === "dark"
                                ? "#424242"
                                : "#fff",
                          }}
                        >
                          <AppBar
                            position="static"
                            color="default"
                            className={classes.tabsAppBar}
                          >
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              aria-label="full width tabs example"
                            >
                              <Tab
                                label={
                                  <Typography>Aktywne zaproszenia</Typography>
                                }
                                {...a11yProps(0)}
                              />
                              <Tab
                                label="Oczekujące powiadomienia"
                                {...a11yProps(1)}
                              />
                              <Tab
                                label="Wysłane powiadomienia"
                                {...a11yProps(2)}
                              />
                            </Tabs>
                          </AppBar>
                          <SwipeableViews
                            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                          >
                            <TabPanel
                              value={value}
                              index={0}
                              dir={theme.direction}
                            >
                              <InvitationsGrid
                                resources={resources}
                                updateCount={invitationUpdateCount}
                              />
                            </TabPanel>
                            <TabPanel
                              value={value}
                              index={1}
                              dir={theme.direction}
                            >
                              <WaitingNotificationsGrid
                                resources={resources}
                                updateCount={invitationUpdateCount}
                              />
                            </TabPanel>
                            <TabPanel
                              value={value}
                              index={2}
                              dir={theme.direction}
                            >
                              <SentNotificationsGrid
                                resources={resources}
                                updateCount={invitationUpdateCount}
                              />
                            </TabPanel>
                          </SwipeableViews>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                )}
                {showDeliveries && (
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6} lg={6}>
                      <Paper className={fixedHeightPaper}>
                        <DocumentGrid />
                      </Paper>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <Paper className={fixedHeightPaper}>
                        <EmployeeGrid driver={true} />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Paper className={fixedHeightPaper}>
                        <CarsGrid />
                      </Paper>
                    </Grid>
                  </Grid>
                )}
                {showSalesClients && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Paper className={fixedHeightPaper}>
                        <SalesClientsGrid />
                      </Paper>
                    </Grid>
                  </Grid>
                )}
                {showSalesSettings && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Paper className={fixedHeightPaper}>
                        <SalesSettingsGrid />
                      </Paper>
                    </Grid>
                  </Grid>
                )}
                {showWindowsPreview && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Paper className={fixedHeightPaper}>
                        <SchedulerPreview />
                      </Paper>
                    </Grid>
                  </Grid>
                )}
                {showAdmin && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Paper className={fixedHeightPaper}>
                        <ChangeLogGrid />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Paper className={fixedHeightPaper}>
                        <ErrorGrid />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Paper className={fixedHeightPaper}>
                        <LogGrid />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Paper className={fixedHeightPaper}>
                        <EmployeeGrid driver={false} />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Paper className={fixedHeightPaper}>
                        <AdvancedGrid />
                      </Paper>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Box pt={4}>
                <Copyright />
              </Box>
            </Container>
          </main>
          <Hidden smUp>
            <MobileNavigation showBox={(box) => toggleBox(box)} />
          </Hidden>
        </div>
        <AxiosAlert />
      </ThemeProvider>
    </StylesProvider>
  );
}
