import React from "react";
import moment from "moment";
import { Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { PieChart } from "react-minimal-pie-chart";
import { Axios } from ".././middleware/axiosInstance";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];

function getCurrentWindow(hook, current) {
  let currentResource = { id: 1 };
  if (current) {
    currentResource.id = current.id;
  } else {
    currentResource.id = hook.state.currentResource.id;
  }
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/window/get/current`,
    params: { resourceId: currentResource.id, type: "Rozładunek" },
  }).then((res) => {
    if (!res.data.err && res.data.data[0]) {
      hook.setState({ isCurrent: true });
      hook.setState({ start: res.data.data[0].start });
      hook.setState({ end: res.data.data[0].end });
      hook.setState({ type: res.data.data[0].type });
      hook.setState({ client: res.data.data[0].client });
    } else {
      hook.setState({ isCurrent: false });
    }
  });
}
function getResources(hook) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/ramp/get/all`,
  }).then((res) => {
    let resources = [];
    let resourcesMax = 0;
    res.data.data.forEach((resource) => {
      resources[resource.id] = resource;
      if (resource.id > resourcesMax) {
        resourcesMax = resource.id;
      }
    });
    hook.setState({
      resources: resources,
      resourcesMax: resourcesMax,
    });
  });
}
const styles = (theme) => ({
  circle: {
    border: "0.5em solid black",
    borderRadius: "100%",
    height: "2em",
    width: "2em",
    textAlign: "center",
  },
  number: {
    marginTop: "0.10em",
    fontSize: "1.5em",
    fontWeight: "bold",
    fontFamily: "sans-serif",
    color: "black",
  },
  fill: {
    fill: "#ffffff",
  },
});

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    width: "100%",
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#ff6634",
  },
}))(LinearProgress);

function getPercentage(hook) {
  let start = moment(hook.state.start);
  let end = moment(hook.state.end);
  let now = moment();
  let per_complete = ((now - start) / (end - start)) * 100;
  let final = Math.round(per_complete * 100) / 100;
  if (final >= 100) {
    getCurrentWindow(hook);
    hook.props.resourceSetter(
      hook.state.resources[hook.state.currentResource.id]
    );
  }
  return final;
}
function changeResource(hook) {
  let current = parseInt(hook.state.currentResource.id) + 1;
  if (current > hook.state.resourcesMax) {
    current = 1;
  }
  let tempResource = {};
  Object.assign(tempResource, hook.state.resources[current]);
  hook.setState({
    currentResource: tempResource,
  });
  hook.props.resourceSetter(hook.state.resources[current]);
  getCurrentWindow(hook, current);
}

class Clock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCurrent: true,
      time: moment().format("LTS"),
      start: moment().set({ minute: 0, second: 0 }),
      end: moment().set({ minute: 0, second: 0 }).add(1, "hours"),
      type: "",
      client: {},
      currentResource: { id: 1, updateCount: 0 },
      resources: [{}, { id: 1, name: "Biała Podlaska" }],
      resourcesCount: 0,
      resourcesMax: 0,
    };
  }

  timeUpdate() {
    this.setState({
      time: moment().format("LTS"),
    });
  }
  componentDidMount() {
    getCurrentWindow(this);
    getResources(this);
    this.timerID = setInterval(() => this.timeUpdate(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  componentDidUpdate() {
    if (
      this.state.currentResource.updateCount !== this.props.resource.updateCount
    ) {
      let tempResource = {};
      Object.assign(tempResource, this.props.resource);
      this.setState({
        currentResource: tempResource,
      });
      getCurrentWindow(this, tempResource);
    }
  }

  // static getDerivedStateFromProps(props, state) {
  //   if (state.currentResource.updateCount !== props.resource.updateCount) {
  //   Axios({
  //       method: "GET",
  //       withCredentials: true,
  //       url: `${API_HOST}/window/get/current`,
  //       params: { resourceId: props.resource.id, type: "Rozładunek" },
  //     }).then((res) => {
  //       if (!res.data.err && res.data.data[0]) {
  //         return {
  //           isCurrent: true,
  //           start: res.data.data[0].start,
  //           end: res.data.data[0].end,
  //           type: res.data.data[0].type,
  //           client: res.data.data[0].client,
  //         };
  //       } else {
  //         return {
  //           isCurrent: false,
  //         };
  //       }
  //     });
  //     let tempResource = {};
  //     Object.assign(tempResource, props.resource);
  //     return {
  //       currentResource: tempResource,
  //     };
  //   }
  //   return null;
  // }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div style={{ height: 135 }}>
          <Typography variant="h5" style={{ float: "left", color: "#ff6634" }}>
            Aktualna awizacja:
          </Typography>

          <Chip
            size="small"
            label={this.state.time}
            color="white"
            style={{ float: "right" }}
          />
          <Chip
            size="small"
            label={this.state.resources[this.state.currentResource.id]?.name}
            variant="outlined"
            onClick={() => {
              changeResource(this);
            }}
            color="primary"
            style={{
              float: "right",
              borderWidth: 1,
              marginRight: 10,
            }}
          />
          <div
            style={{
              width: "100%",
              height: 65,
              float: "left",
              marginTop: 10,
            }}
          >
            <div className={classes.root}>
              <div
                style={{ float: "left", width: "100%", marginBottom: "20px" }}
              ></div>
              <div
                style={{ float: "left", width: "100%", marginBottom: "10px" }}
              >
                <Typography style={{ float: "left" }} variant="h6">
                  Kontrahent:
                  <Box component="span" fontWeight={600}>
                    {" "}
                    {(this.state.isCurrent && this.state.client?.name) || "---"}
                  </Box>
                </Typography>
                <Typography style={{ float: "right" }} variant="h6">
                  Typ:
                  <Box component="span" fontWeight={600}>
                    {" "}
                    {(this.state.isCurrent && this.state.type) || "---"}
                  </Box>
                </Typography>
              </div>

              <Typography variant="h7">
                {(this.state.isCurrent && (
                  <Box component="span" fontWeight={600}>
                    {moment(this.state.start).format("HH:mm")}
                  </Box>
                )) ||
                  " "}
                {}
              </Typography>
              <Typography variant="h7" style={{ float: "right" }}>
                {(this.state.isCurrent && (
                  <Box component="span" fontWeight={600}>
                    {moment(this.state.end).format("HH:mm")}
                  </Box>
                )) ||
                  " "}
                {}
              </Typography>
              {this.state.isCurrent && (
                <BorderLinearProgress
                  variant="determinate"
                  value={getPercentage(this)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(Clock);
