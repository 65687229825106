import { DataGrid, GridOverlay } from "@material-ui/data-grid";
import { Axios } from ".././middleware/axiosInstance";
import ReactVirtualizedTable from "./VirtualizedTable";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import DirectionsIcon from "@material-ui/icons/Directions";
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Box,
  Button,
  ButtonBase,
  Drawer,
  FormControl,
  Grid,
  Hidden,
  Input,
  LinearProgress,
  ListSubheader,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { Add, Check, Clear, Edit, FilterList } from "@material-ui/icons";
import DropdownTreeSelect from "react-dropdown-tree-select";
import "./dropdownTreeSelect.css";
import CategoryPicker from "./CategoryPicker";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ProductDrawer from "./ProductDrawer";
import Alert from "@material-ui/lab/Alert";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];
function getAllProducts(hook) {
  hook.setState({ loading: true });
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/product/get/all/categoryHierarchy`,
  }).then((res) => {
    if (!res.data.err) {
      let dataSort = res.data.data.sort((a, b) =>
        a.lastModification < b.lastModification ? 1 : -1
      );
      hook.setState({ products: dataSort });
      let productsIds = res.data.data.map((prod) => {
        return [prod.towarIdSubiekt];
      });
      hook.setState({ allProductsIds: productsIds });
      hook.setState({ loading: false });
    } else {
      console.log(res.data.msg);
    }
  });
}
function getUnassignedProducts(hook) {
  hook.setState({ loading: true });
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/product/get/all/unassigned`,
  }).then((res) => {
    if (!res.data.err) {
      let dataSort = res.data.data.sort((a, b) =>
        a.lastModification < b.lastModification ? 1 : -1
      );
      hook.setState({ products: dataSort });
      let productsIds = res.data.data.map((prod) => {
        return [prod.towarIdSubiekt];
      });
      hook.setState({ allProductsIds: productsIds });
      hook.setState({ loading: false });
    } else {
      console.log(res.data.msg);
    }
  });
}
function getProducts(hook, ids) {
  hook.setState({ loading: true });
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/product/get/byCategory/categoryHierarchy/false`,
    params: { ids: ids },
  }).then((res) => {
    if (!res.data.err) {
      let dataSort = res.data.data.sort((a, b) =>
        a.lastModification < b.lastModification ? 1 : -1
      );
      hook.setState({ products: dataSort });
      hook.setState({ loading: false });
    } else {
      console.log(res.data.msg);
    }
  });
}

function addProduct(hook, product) {
  Axios({
    method: "POST",
    withCredentials: true,
    url: `${API_HOST}/product/add`,
    data: product,
  }).then((res) => {
    if (!res.data.err) {
      getChildCategories(hook, product.categoryId);
      hook.setState({ successMsg: res.data.msg });
      hook.setState({ openDrawer: false });
    } else {
      console.log(res.data.msg);
    }
  });
}
function editProduct(hook, product) {
  Axios({
    method: "PUT",
    withCredentials: true,
    url: `${API_HOST}/product/update`,
    data: product,
  }).then((res) => {
    if (!res.data.err) {
      getChildCategories(hook, product.categoryId);
      hook.setState({ successMsg: res.data.msg });
      hook.setState({ openDrawer: false });
    } else {
      console.log(res.data.msg);
    }
  });
}
function getCecha(hook) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/product/get/all/cecha`,
  }).then((res) => {
    if (!res.data.err) {
      let cechaSort = res.data.data.sort((a, b) => (a.name > b.name ? 1 : -1));
      hook.setState({ cecha: cechaSort });
    } else {
      console.log(res.data.msg);
    }
  });
}
function getCategories(hook) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/product_categories/get/all/tree`,
  }).then((res) => {
    if (!res.data.err) {
      let data = res.data.data.tree.sort((a, b) =>
        a.label > b.label ? 1 : -1
      );
      //assignObjectPaths(data);
      hook.setState({ categories: data });
      hook.setState({ flatCategories: res.data.data.flat });
    } else {
      console.log(res.data.msg);
    }
  });
}
function getChildCategories(hook, id) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/product_categories/get/tree/ids`,
    params: { id: id },
  }).then((res) => {
    if (!res.data.err) {
      if (res.data.data.length > 0) {
        getProducts(hook, [...res.data.data[0].categoryHierarchyIds, id]);
      } else {
        getProducts(hook, [id]);
      }
    } else {
      console.log(res.data.msg);
    }
  });
}

function productColumns(hook) {
  //const { classes } = hook.props;
  return [
    {
      field: "symbol",
      headerName: "Symbol",
      width: 250,
      type: "string",
      sortable: true,
    },
    {
      field: "cechaIdSubiekt",
      headerName: "cechaId",
      width: 100,
      type: "string",
      sortable: true,
      hide: true,
    },
    {
      field: "towarIdSubiekt",
      headerName: "towarId",
      width: 100,
      type: "string",
      sortable: true,
      hide: true,
    },
    {
      field: "unitCount",
      headerName: "Wielkość",
      width: 100,
      type: "string",
      sortable: true,
      //hide: true,
      renderCell: (params) => {
        if (params.row.unitCount) {
          return (
            <div>
              {params.row.unitCount} {params.row.unit}
              {"/"}
              {params.row.baseUnit}
            </div>
          );
        } else {
          return <div style={{ color: "red" }}>Brak</div>;
        }
      },
    },
    {
      field: "categoryId",
      headerName: "categoryId",
      width: 100,
      type: "string",
      sortable: true,
      hide: true,
    },
    {
      field: "name",
      headerName: "Nazwa",
      width: 600,
      type: "string",
      sortable: true,
    },
    {
      field: "description",
      headerName: "Opis",
      width: 100,
      type: "string",
      sortable: true,
    },

    {
      field: "lastModification",
      headerName: "Ostatnia modyfikacja",
      width: 300,
      type: "dateTime",
      sortable: true,
      hide: true,
      renderCell: (params) => (
        <strong>{new Date(Date.parse(params.value)).toLocaleString()}</strong>
      ),
    },
    {
      field: "imgUrl",
      headerName: "Zdjęcie",
      width: 100,
      type: "string",
      sortable: true,
      cellClassName: "cellBackround",
      renderCell: (params) => {
        let icon = <Clear value={false} />;
        if (params.row.imgUrl) {
          //icon = <Check value={true} />;
          icon = (
            <div
              style={{
                backgroundColor: "white",
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <img
                src={`images/products/${params.row.id}/${params.row.imgUrl}`}
                alt="Brak"
                height={50}
              ></img>
            </div>
          );
        }
        return (
          <div
            style={{
              backgroundColor: "white",
              width: "100%",
              color: "red",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            {icon}
          </div>
        );
      },
    },
    {
      field: "ignoreAvailability",
      headerName: "Ignorowanie dostępności",
      width: 200,
      type: "string",
      sortable: true,
      renderCell: (params) => {
        let icon = <Clear value={false} />;
        if (params.row.ignoreAvailability) {
          icon = <Check value={true} />;
        }
        return icon;
      },
      hide: true,
    },
    {
      field: "categoryHierarchy",
      headerName: "Kategoria",
      width: 250,
      type: "string",
      sortable: true,
      renderCell: (params) => {
        if (!params.row.categoryHierarchy) return <Typography>Brak</Typography>;
        let hierarchy = [];
        params.row.categoryHierarchy.forEach((category) => {
          hierarchy.push(category.name);
        });
        hierarchy = hierarchy.reverse();
        let hierarchyString = hierarchy.join(" > ");
        return (
          <Typography value={hierarchyString}>{hierarchyString}</Typography>
        );
      },
    },
    {
      field: "_id",
      headerName: " ",
      renderCell: (params) => {
        return (
          <Button
            color="primary"
            startIcon={<Edit />}
            onClick={() => {
              hook.setState({ product: params.row });
              hook.setState({ productEditMode: true });
              hook.setState({ openDrawer: true });
            }}
          ></Button>
        );
      },
    },
  ];
}

const styles = (theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: "10px",
    border: "solid 1px #cecece",
  },
  shadow: {
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  formControl: {
    margin: theme.spacing(1),

    minWidth: 120,
  },
  inputBaseHeight: {
    height: 35,
  },
  cellBackround: {
    backgroundColor: "white",
  },
});
function filterModels(hook, type, value) {
  let models = {
    text: {
      columnField: hook.state.filterColumn,
      operatorValue: "contains",
      value: value,
    },
    cecha: {
      columnField: "cechaIdSubiekt",
      operatorValue: "startsWith",
      value: value,
    },
  };
  if (type === "text") {
    return models.text;
  } else if (type === "cecha") {
    return models.cecha;
  }
}

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

class SalesSettingsGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      allProductsIds: [],
      product: {},
      cecha: [],
      categories: [],
      filterText: "",
      filterColumn: "name",
      filterCecha: "name",
      filterCategory: "name",
      filterModel: {},
      test: "hello",
      openDrawer: false,
      flatCategories: [],
      productEditMode: false,
      successMsg: false,
      loading: true,
      showCategories: false,
      gridPage: 0,
    };
  }

  componentDidMount() {
    this.setState({ filterModel: filterModels(this, "text") });
    getAllProducts(this);
    getCecha(this);
    getCategories(this);
  }

  onCategoryChange = (currentNode, selectedNodes) => {
    this.setState({ test: "something else" });
    console.log("onChange::", currentNode, selectedNodes);
  };

  render() {
    const { classes } = this.props;
    const cecha = this.state.cecha;
    return (
      <div style={{ minHeight: 1800 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={2}>
            <h2>Produkty</h2>
          </Grid>
          <Grid item xs={12} md={12} lg={7}>
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Wyszukaj produkt"
                inputProps={{ "aria-label": "wyszukaj produkt" }}
                onChange={(event) => {
                  setTimeout(() => {
                    //this.setState({ filterColumn: event.target.value });
                    this.setState({
                      filterModel: filterModels(
                        this,
                        "text",
                        event.target.value
                      ),
                    });
                  }, 200);
                }}
              />
              <Divider className={classes.divider} orientation="vertical" />
              <FormControl className={classes.formControl} variant="outlined">
                <InputLabel htmlFor="my-column">Kolumna</InputLabel>
                <Select
                  value={this.state.filterColumn}
                  onChange={(event) => {
                    this.setState({ filterColumn: event.target.value });
                    this.setState({
                      filterModel: filterModels(this, "text", ""),
                    });
                  }}
                  inputProps={{
                    name: "kolumna",
                    id: "my-column",
                  }}
                  label="Kolumna"
                  className={classes.inputBaseHeight}
                >
                  <MenuItem value={"name"}>Nazwa</MenuItem>
                  <MenuItem value={"symbol"}>Symbol</MenuItem>
                </Select>
              </FormControl>
            </Paper>
          </Grid>
          <Grid item xs={6} md={6} lg={2}>
            <Hidden lgUp>
              <Button
                variant={this.state.showCategories ? "contained" : "outlined"}
                style={{
                  color: this.state.showCategories ? "white" : "#ff6634",
                  marginRight: 10,
                  width: "100%",
                }}
                startIcon={<FilterList />}
                color="primary"
                onClick={() => {
                  if (this.state.showCategories) {
                    this.setState({ showCategories: false });
                  } else {
                    this.setState({ showCategories: true });
                  }
                }}
              >
                Kategorie
              </Button>
            </Hidden>
            {/* <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              className={classes.shadow}
            >
              <InputLabel id="demo-mutiple-name-label">Cecha</InputLabel>
              <Select
                labelId="demo-mutiple-name-label"
                id="demo-mutiple-name"
                value={this.state.filterCecha}
                onChange={(event) => {
                  this.setState({ filterCecha: event.target.value });
                  this.setState({
                    filterModel: filterModels(
                      this,
                      "cecha",
                      event.target.value
                    ),
                  });
                }}
                label="Cecha"
                placeholder="Cecha"
                style={{ height: 36.5 }}
              >
                <MenuItem value={""}>Wszystkie</MenuItem>
                <Divider />
                {cecha.map((c) => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </Grid>
          <Grid item xs={6} md={6} lg={1}>
            <Button
              variant="contained"
              style={{ color: "white", marginRight: 10, width: "100%" }}
              startIcon={<Add />}
              color="primary"
              onClick={() => {
                this.setState({ productEditMode: false });
                this.setState({ product: {} });
                this.setState({ openDrawer: true });
              }}
            >
              Dodaj
            </Button>
          </Grid>
          <Grid item xs={12} md={12} lg={2}>
            {this.state.showCategories && (
              <CategoryPicker
                data={{
                  categoriesTree: this.state.categories,
                  flatCategories: this.state.flatCategories,
                }}
                maxHeight={600}
                editMode={true}
                onEdit={() => {
                  getCategories(this);
                }}
                pickMode={false}
                selected={0}
                onSelect={(selected) => {
                  this.setState({ gridPage: 0 });
                  if (selected === "0") {
                    getAllProducts(this);
                  } else if (selected === null) {
                    getUnassignedProducts(this);
                  } else {
                    getChildCategories(this, selected);
                  }
                }}
              />
            )}
            <Hidden mdDown>
              <CategoryPicker
                data={{
                  categoriesTree: this.state.categories,
                  flatCategories: this.state.flatCategories,
                }}
                maxHeight={600}
                editMode={true}
                onEdit={() => {
                  getCategories(this);
                }}
                pickMode={false}
                selected={0}
                onSelect={(selected) => {
                  this.setState({ gridPage: 0 });
                  if (selected === "0") {
                    getAllProducts(this);
                  } else if (selected === null) {
                    getUnassignedProducts(this);
                  } else {
                    getChildCategories(this, selected);
                  }
                }}
              />
            </Hidden>
          </Grid>
          <Grid item xs={12} md={12} lg={10}>
            <DataGrid
              filterModel={{
                items: [this.state.filterModel],
              }}
              disableSelectionOnClick
              columnBuffer={this.state.products.length}
              rows={this.state.products}
              columns={productColumns(this)}
              onRowSelection={this.handleRowSelection}
              disableMultipleSelection={false}
              pageSize={25}
              autoHeight={true}
              components={{
                LoadingOverlay: CustomLoadingOverlay,
              }}
              loading={this.state.loading}
              getRowId={(row) => row._id}
              onSelectionModelChange={(newSelection) => {
                this.setState({ rowSelected: newSelection.selectionModel });
              }}
              pagination
              page={this.state.gridPage}
              onPageChange={(newPage) => {
                console.log(newPage);
                this.setState({ gridPage: newPage.page });
              }}
            />
          </Grid>
        </Grid>
        <ProductDrawer
          open={this.state.openDrawer}
          edit={this.state.productEditMode}
          data={{
            cecha: cecha,
            categories: this.state.categories,
            flatCategories: this.state.flatCategories,
            productsIds: this.state.allProductsIds,
            product: this.state.product,
          }}
          onClose={() => {
            this.setState({ openDrawer: false });
          }}
          onSave={(product, edit) => {
            if (edit) {
              editProduct(this, product);
            } else {
              addProduct(this, product);
            }
          }}
        />
        <Snackbar
          open={Boolean(this.state.successMsg)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={6000}
          onClose={() => {
            this.setState({ successMsg: false });
          }}
        >
          <Alert
            onClose={() => {
              this.setState({ successMsg: false });
            }}
            severity="success"
            variant="filled"
          >
            {this.state.successMsg}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SalesSettingsGrid);
