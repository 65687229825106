import { Axios } from ".././middleware/axiosInstance";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];
export default async function isAuthenticated(callback) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/user`,
  })
    .then((res) => {
      callback(true);
    })
    .catch((res) => {
      console.log(res);
      callback(false);
    });
}
