import {
  Button,
  Chip,
  FormControl,
  FormHelperText,
  Input,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { DataGrid, GridOverlay } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { Axios } from ".././middleware/axiosInstance";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Theme, styled } from "@mui/material/styles";
import { Face, SignalCellularNull } from "@material-ui/icons";
//import { renderSelectEditCell } from "./SelectField";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];
function getCustomerProducts(
  setCustomerProducts,
  id,
  setSelectionModel,
  setProducts,
  setLoading
) {
  setLoading(true);
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/customer/products/get/all/true`,
    params: { id: id },
  }).then((res) => {
    if (!res.data.err) {
      let data = res.data.data;
      data.forEach((prod) => {
        if (prod.limits != null) {
          prod.limits.forEach((e, index) => {
            Object.assign(prod, { [`limit${index}`]: e });
          });
        }
      });

      let dataSort = data.sort((a, b) => (a.name < b.name ? 1 : -1));
      console.log(dataSort);
      setCustomerProducts(dataSort);
      setProducts(dataSort);
      let ids = [];
      dataSort.forEach((data) => {
        ids.push(data.id);
      });
      setSelectionModel(ids);
      setLoading(false);
    } else {
      console.log(res.data.msg);
    }
  });
}
function getProducts(setProducts, ids, customerProducts, setLoading) {
  setLoading(true);
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/product/get/byCategory/categoryHierarchy/true`,
    params: { ids: ids },
  }).then((res) => {
    if (!res.data.err) {
      let dataSort = res.data.data.sort((a, b) => (a.name < b.name ? 1 : -1));
      let customerProductsMap = new Map(
        customerProducts.map((prod) => {
          return [prod.productId, prod];
        })
      );
      let keys = Array.from(customerProductsMap.keys());
      let finalProducts = dataSort.map((prod) => {
        if (keys.includes(prod.productId)) {
          return customerProductsMap.get(prod.productId);
        } else return prod;
      });
      setProducts(finalProducts);
      setLoading(false);
    } else {
      console.log(res.data.msg);
    }
  });
}

function getChildCategories(setProducts, id, customerProducts, setLoading) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/product_categories/get/tree/ids`,
    params: { id: id },
  }).then((res) => {
    if (!res.data.err) {
      if (res.data.data.length > 0) {
        getProducts(
          setProducts,
          [...res.data.data[0].categoryHierarchyIds, id],
          customerProducts,
          setLoading
        );
      } else {
        getProducts(setProducts, [id], customerProducts, setLoading);
      }
    } else {
      console.log(res.data.msg);
    }
  });
}

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fefefe" : "#303030",
  border: 0,
  color:
    theme.palette.mode === "dark"
      ? "rgba(255,255,255,0.85)"
      : "rgba(0,0,0,.85)",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor:
      theme.palette.mode === "light" ? "rgba(0,0,0,.85)" : "rgba(0,0,0,.85)",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid ${
      theme.palette.mode === "light" ? "#8d8b8b" : "#f0f0f0"
    }`,
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${
      theme.palette.mode === "light" ? "#303030" : "#f0f0f0"
    }`,
  },
  "& .MuiDataGrid-cell": {
    color: theme.palette.mode === "light" ? "#303030" : "#f0f0f0",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },
  "& .MuiDataGrid-pinnedColumns": {
    backgroundColor: theme.palette.mode === "light" ? "#f0f0f0" : "#f0f0f0",
    color: theme.palette.mode === "light" ? "#303030" : "#f0f0f0",
  },
  "& .MuiDataGrid-pinnedColumnHeaders": {
    backgroundColor: theme.palette.mode === "light" ? "#f0f0f0" : "#f0f0f0",
    color: theme.palette.mode === "light" ? "#303030" : "#f0f0f0",
  },
  "& .MuiDataGrid-columnHeaders": {
    color: theme.palette.mode === "light" ? "#303030" : "#f0f0f0",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  "& .MuiDataGrid-iconSeparator": {
    borderBottom: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    backgroundColor: "#fff0f0",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function updatePriceType(get, set, prodId, value) {
  let newCustomerProducts = get.map((prod) => {
    if (prod.id === prodId) {
      let newProd = prod;
      prod.isExact = value;
      return newProd;
    } else return prod;
  });
  set(newCustomerProducts);
}

function customerProductUpdate(get, set, model) {
  let newCustomerProducts = get.map((prod) => {
    if (prod.id === model.id) {
      let newProd = prod;
      prod[model.field] = model.props.value;
      return newProd;
    } else return prod;
  });
  set(newCustomerProducts);
}

function customerProductRemoveHandler(get, set, prodId) {
  let newCustomerProducts = get.map((prod) => {
    if (prod.id === prodId) {
      return false;
    } else return prod;
  });
  set(newCustomerProducts);
}

function customerProductAddHandler(get, set, prodId, value) {
  set(get.push(value));
}
let onPriceTypeChange = (id, value, setCustomerProducts, customerProducts) => {
  let change = false;
  let newCustomerProducts = customerProducts.map((prod) => {
    if (prod.id === Number(id)) {
      prod.isExact = value;
      change = true;
      console.log(prod);
      return prod;
    } else return prod;
  });
  if (change) {
    setCustomerProducts(newCustomerProducts);
  }
};
const SelectField = (props) => {
  const { id, value, api, field } = props;
  const classes = useStyles();
  const handleChange = React.useCallback(
    (event) => {
      const editProps = {
        value: Number(event.target.value),
      };

      api.commitCellChange({ id, field, props: editProps });
      api.setCellMode(id, field, "view");
      event.stopPropagation();
      props.onChange(
        id,
        event.target.value,
        props.setCustomerProducts,
        props.customerProducts
      );
    },
    [api, field, id]
  );

  return (
    <FormControl className={classes.formControl}>
      <Select
        //ref={handleRef}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={Boolean(value)}
        onChange={handleChange}
      >
        <MenuItem value={true}>Własna</MenuItem>
        <MenuItem value={false}>Subiekt</MenuItem>
      </Select>
    </FormControl>
  );
};

const columnGroupingModel = [
  {
    groupId: "limits",
    headerName: "Limity ilościowe",
    description: "",
    children: [
      { field: "limit1" },
      { field: "limit2" },
      { field: "limit3" },
      { field: "limit4" },
      { field: "limit5" },
      { field: "limit6" },
      { field: "limit7" },
      { field: "limit8" },
      { field: "limit9" },
      { field: "limit10" },
      { field: "limit11" },
      { field: "limit12" },
    ],
  },
];

function renderSelectEditCell(
  params,
  onPriceChange,
  setCustomerProducts,
  customerProducts
) {
  return (
    <SelectField
      {...params}
      onChange={onPriceChange}
      setCustomerProducts={setCustomerProducts}
      customerProducts={customerProducts}
    />
  );
}

function customerProductsColumns(
  setCustomerProducts,
  customerProducts,
  customerPriceFactor
) {
  return [
    {
      field: "symbol",
      headerName: "Symbol",
      width: 155,
      type: "string",
      sortable: true,
      pinnable: true,
    },
    {
      field: "cechaIdSubiekt",
      headerName: "cechaId",
      width: 100,
      type: "string",
      sortable: true,
      hide: true,
    },
    {
      field: "categoryId",
      headerName: "categoryId",
      width: 100,
      type: "string",
      sortable: true,
      hide: true,
    },

    {
      field: "isExact",
      headerName: "Typ ceny",
      width: 120,
      sortable: true,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Własna", "Auto"],
      valueParser: (val) => {
        if (val === "Własna") {
          return true;
        } else return false;
      },
      renderCell: (params) => {
        if (params.row.isExact) {
          return (
            <Chip
              label="Własna"
              size="small"
              color="secondary"
              variant="outlined"
            />
          );
        } else {
          return (
            <Chip
              label="Auto"
              size="small"
              color="primary"
              variant="outlined"
            />
          );
        }
      },
    },
    {
      field: "cena",
      headerName: "Cena",
      width: 100,
      type: "number",
      sortable: true,
      resizable: true,
      editable: true,
      renderCell: (params) => {
        let number = Number(params.row.exactPrice);
        let cena =
          Number.parseFloat(params.row.cena) +
          Number.parseFloat(params.row.cena) *
            (Number(customerPriceFactor) / 100);
        if (params.row.isExact) {
          if (isNaN(number)) {
            return <Typography>{`Brak`}</Typography>;
          } else
            return (
              <Typography>{`${Number.parseFloat(number).toFixed(
                2
              )} zł`}</Typography>
            );
        } else {
          return <Typography>{`${cena.toFixed(2)} zł`}</Typography>;
        }
      },
      valueGetter: (params) => {
        let cena =
          Number.parseFloat(params.row.cena) +
          Number.parseFloat(params.row.cena) *
            (Number(customerPriceFactor) / 100);
        return cena.toFixed(2);
      },
    },
    {
      field: "isPromotion",
      headerName: "Promocja",
      width: 120,
      type: "boolean",
      editable: true,
      sortable: true,
    },
    {
      field: "name",
      headerName: "Nazwa",
      width: 300,
      type: "string",
      sortable: true,
      pinnable: true,
    },
    {
      field: "limit1",
      headerName: "1",
      width: 70,
      type: "number",
      editable: true,
      sortable: false,
    },
    {
      field: "limit2",
      headerName: "2",
      width: 70,
      type: "number",
      editable: true,
      sortable: false,
    },
    {
      field: "limit3",
      headerName: "3",
      width: 70,
      type: "number",
      editable: true,
      sortable: false,
    },
    {
      field: "limit4",
      headerName: "4",
      width: 70,
      type: "number",
      editable: true,
      sortable: false,
    },
    {
      field: "limit5",
      headerName: "5",
      width: 70,
      type: "number",
      editable: true,
      sortable: false,
    },
    {
      field: "limit6",
      headerName: "6",
      width: 70,
      type: "number",
      editable: true,
      sortable: false,
    },
    {
      field: "limit7",
      headerName: "7",
      width: 70,
      type: "number",
      editable: true,
      sortable: false,
    },
    {
      field: "limit8",
      headerName: "8",
      width: 70,
      type: "number",
      editable: true,
      sortable: false,
    },
    {
      field: "limit9",
      headerName: "9",
      width: 70,
      type: "number",
      editable: true,
      sortable: false,
    },
    {
      field: "limit10",
      headerName: "10",
      width: 70,
      type: "number",
      editable: true,
      sortable: false,
    },
    {
      field: "limit11",
      headerName: "11",
      width: 70,
      type: "number",
      editable: true,
      sortable: false,
    },
    {
      field: "limit12",
      headerName: "12",
      width: 70,
      type: "number",
      editable: true,
      sortable: false,
    },
    {
      field: "lastModification",
      headerName: "Ostatnia modyfikacja",
      width: 300,
      type: "dateTime",
      sortable: true,
      hide: true,
      renderCell: (params) => (
        <strong>{new Date(Date.parse(params.value)).toLocaleString()}</strong>
      ),
    },
  ];
}

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

function CustomerGrid(props) {
  const classes = useStyles();
  const [customerProducts, setCustomerProducts] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [editRowsModel, setEditRowsModel] = React.useState({});
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const handleNewSelectionModel = (model) => {
    console.log(model);
    let oldModel = selectionModel;
    let newModel;
    if (model.selectionModel) {
      newModel = model.selectionModel;
    } else {
      newModel = model;
    }
    let isRemoved = oldModel.filter((x) => !newModel.includes(x));
    let isAdded = newModel.filter((x) => !oldModel.includes(x));
    if (isRemoved.length > 0) {
      let newCustomerProducts = customerProducts.filter(
        (prod) => !isRemoved.includes(prod.id)
      );
      let newSelectionModel = selectionModel.filter((prod) =>
        newModel.includes(prod)
      );
      setCustomerProducts(newCustomerProducts);
      setSelectionModel(newSelectionModel);
    }
    if (isAdded.length > 0) {
      let newCustomerProducts = Object.assign([], customerProducts);
      let newSelectionModel = Object.assign([], selectionModel);
      products.forEach((prod) => {
        if (isAdded.includes(prod.id)) {
          newCustomerProducts.push(prod);
          newSelectionModel.push(prod.id);
        }
      });
      setCustomerProducts(newCustomerProducts);
      setSelectionModel(newSelectionModel);
    }
  };

  const handleEditRowsModelChange = (rowModel) => {
    let model = rowModel;
    let id = Object.keys(model)[0];
    if (id && model) {
      let field = Object.keys(model[id])[0];
      if (field === "cena") {
        let value = model[id][field].value;
        let change = false;
        let newCustomerProducts = customerProducts.map((prod) => {
          if (prod.id === Number(id) && prod.isExact) {
            prod.exactPrice = value;
            change = true;
            return prod;
          } else {
            return prod;
          }
        });
        if (change) {
          setCustomerProducts(newCustomerProducts);
        }
      }
      if (field === "isPromotion") {
        let value = model[id][field].value;
        let change = false;
        let newCustomerProducts = customerProducts.map((prod) => {
          if (prod.id === Number(id)) {
            prod.isPromotion = value;
            change = true;
            return prod;
          } else return prod;
        });
        if (change) {
          setCustomerProducts(newCustomerProducts);
        }
      }
      if (field === "isExact") {
        let value = model[id][field].value;
        console.log(value);
        let change = false;
        let newCustomerProducts = customerProducts.map((prod) => {
          if (prod.id === Number(id)) {
            prod.isExact = value;
            change = true;
            return prod;
          } else return prod;
        });
        if (change) {
          setCustomerProducts(newCustomerProducts);
        }
      }
      if (field.startsWith("limit")) {
        console.log(field);
        let index = field.substring(5);
        let value = model[id][field].value;
        console.log(value);
        console.log(index);
        let change = false;
        let newCustomerProducts = customerProducts.map((prod) => {
          if (prod.id === Number(id)) {
            if (!prod.limits) {
              prod.limits = [];
            }
            prod.limits[index] = value;
            prod[field] = value;
            change = true;
            return prod;
          } else return prod;
        });
        if (change) {
          setCustomerProducts(newCustomerProducts);
        }
      }
    }
  };

  const handleBlur = (params, event) => {
    event.stopPropagation();
  };
  React.useEffect(() => {
    getCustomerProducts(
      setCustomerProducts,
      props.customerId,
      setSelectionModel,
      setProducts,
      setLoading
    );
  }, [props.customerId]);

  React.useEffect(() => {
    getChildCategories(
      setProducts,
      props.categoryId,
      customerProducts,
      setLoading
    );
  }, [props.categoryId]);

  React.useEffect(() => {
    props.onProductModelChange(customerProducts);
  }, [customerProducts, setSelectionModel]);

  React.useEffect(() => {
    let ids = [];
    customerProducts.forEach((data) => {
      ids.push(data.id);
    });
    let currentSelection = [];
    products.forEach((prod) => {
      if (ids.includes(prod.id)) {
        currentSelection.push(prod.id);
      }
    });
    setSelectionModel(currentSelection);
  }, [products, customerProducts]);

  return (
    <div style={{ height: 943, width: "100%" }}>
      <DataGridPro
        filterModel={{
          items: [
            {
              columnField: props.filterColumn,
              operatorValue: "contains",
              value: props.filterValue,
            },
          ],
        }}
        density="compact"
        className={classes.root}
        disableSelectionOnClick
        //columnBuffer={products.length}
        rows={products}
        columns={customerProductsColumns(
          setCustomerProducts,
          customerProducts,
          props.customerPriceFactor
        )}
        onEditRowsModelChange={(model) => {
          handleEditRowsModelChange(model);
        }}
        onCellBlur={handleBlur}
        disableMultipleSelection={false}
        // pageSize={25}
        //autoHeight={true}
        checkboxSelection
        getRowId={(row) => row.id}
        loading={loading}
        // components={{
        //   LoadingOverlay: CustomLoadingOverlay,
        // }}
        onSelectionModelChange={(newSelectionModel) => {
          handleNewSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
        // initialState={{
        //   pinnedColumns: {
        //     left: ["name"],
        //   },
        // }}
        // experimentalFeatures={{ columnGrouping: true }}
        // columnGroupingModel={columnGroupingModel}
      />
    </div>
  );
}

export default CustomerGrid;
