import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import validator from "validator";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import { Axios } from ".././middleware/axiosInstance";
import { CheckBoxOutlineBlank } from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];

function saveData(
  firstName,
  surname,
  phone,
  email,
  employeeTypes,
  employeeAccess,
  login,
  password,
  close,
  updateData
) {
  let employee = {};
  employee.firstName = firstName;
  employee.surname = surname;
  employee.phone = phone;
  employee.email = email;
  employee.typeUser = employeeTypes;
  employee.accessUser = employeeAccess;
  employee.login = login;
  employee.password = password;
  Axios({
    method: "POST",
    withCredentials: true,
    url: `${API_HOST}/employee/add`,
    data: employee,
  })
    .then((res) => {
      console.log(res.data);

      updateData();
      close();
    })
    .catch((error) => {
      console.log(error.response.data.msg);
    });
}
function notEmpty(
  firstName,
  surname,
  phone,
  email,
  employeeTypes,
  employeeAccess,
  login,
  password,
  close,
  updateData,
  setErrorMessage,
  setError
) {
  if (
    !firstName ||
    !surname ||
    !phone ||
    !email ||
    !employeeTypes ||
    !password
  ) {
    setError(true);
    setErrorMessage("Wszystkie pola muszą być wypełnione!");
  } else {
    setError(false);
    setErrorMessage(null);
    saveData(
      firstName,
      surname,
      phone,
      email,
      employeeTypes,
      employeeAccess,
      login,
      password,
      close,
      updateData
    );
  }
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: 20,
  },
  marginRight: {
    marginRight: 20,
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({
  employee,
  open,
  onClose = (f) => f,
  updateData = (f) => f,
  driver,
  types,
  access,
}) {
  const [state, setState] = React.useState({ checkedB: true });
  const [firstName, setFirstName] = React.useState();
  const [surname, setSurname] = React.useState();
  const [login, setLogin] = React.useState();
  const [phone, setPhone] = React.useState();
  const [employeeTypes, setEmployeeTypes] = React.useState();
  const [employeeAccess, setEmployeeAccess] = React.useState();
  const [email, setEmail] = React.useState();
  const [repeatpasswordCorrect, setRepeatPasswordCorrect] =
    React.useState(false);
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [passwordCorrect, setPasswordCorrect] = React.useState(false);

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };
  const handleSurnameChange = (event) => {
    setSurname(event.target.value);
  };
  const handleLoginChange = (event) => {
    setLogin(event.target.value);
  };
  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };
  const handleEmailChange = (event) => {
    let emailValue = event.target.value;
    validateEmail(emailValue);
    setEmail(emailValue);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    validate(event.target.value);
  };
  const handleEmployeeTypeChange = (value) => {
    if (value) {
      setEmployeeTypes(value);
    }
  };
  const validateEmail = (value) => {
    var email = value;

    if (validator.isEmail(email)) {
      setError(false);
    } else {
      setError(true);
      setErrorMessage("Nie poprawny adres email!");
    }
  };
  const handleRepeatPasswordChange = (event) => {
    let passwordRepeat = event.target.value;
    if (password === passwordRepeat) {
      setError(false);
      setErrorMessage(null);
      setRepeatPasswordCorrect(true);
    } else {
      setError(true);
      setErrorMessage(" Hasła nie są takie same !");
      setRepeatPasswordCorrect(false);
    }
  };

  const validate = (value) => {
    if (value.length == "") {
      setErrorMessage("");
      setPasswordCorrect(true);
    } else if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setError(false);
      setErrorMessage(null);
      setPasswordCorrect(true);
    } else {
      setError(true);
      setErrorMessage(
        "Hasło nie jest wystarczająco silne! Minimum 8 znaków, jedna cyfra, znak specjalny, mała oraz duża litera!"
      );
      setPasswordCorrect(false);
    }
  };

  React.useEffect(() => {
    setFirstName(null);
    setSurname(null);
    setPhone(null);
    setEmail(null);
    setLogin(null);
    setPassword(null);
    setPassword(null);
    setErrorMessage(null);
    setError(false);
    setRepeatPasswordCorrect(false);
    setPasswordCorrect(false);
    if (driver) {
      setEmployeeTypes(types[0]);
    } else {
      setEmployeeTypes(null);
    }
    setEmployeeAccess(access);
  }, [open]);
  const classes = useStyles();
  return (
    <div>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          Dodaj Nowego Pracownika
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            required
            id="firstName"
            label="Imię:"
            className={(classes.marginBottom, classes.marginRight)}
            onChange={handleFirstNameChange}
            style={{ width: "48%" }}
          />

          <TextField
            required
            id="surname"
            label="Nazwisko:"
            className={classes.marginBottom}
            onChange={handleSurnameChange}
            style={{ width: "48%" }}
          />

          <TextField
            required
            id="phone"
            label="Numer telefonu"
            type="phone"
            fullWidth={true}
            className={classes.marginBottom}
            onChange={handlePhoneChange}
          />
          <TextField
            required
            fullWidth
            name="email"
            label="Email"
            type="text"
            id="email"
            className={classes.marginBottom}
            onChange={handleEmailChange}
          />
          <Autocomplete
            required
            onChange={(event, value) => handleEmployeeTypeChange(value)}
            options={types}
            defaultValue={employeeTypes}
            disabled={driver}
            getOptionLabel={(option) => option}
            className={classes.marginBottom}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Stanowisko: "
                placeholder={types}
                value={types}
              />
            )}
          />
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={access}
            onChange={(event, value) => setEmployeeAccess(value)}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </React.Fragment>
            )}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Dostęp"
                placeholder="Dostęp"
              />
            )}
          />
          <TextField
            required
            id="login"
            label="Login:"
            type="text"
            fullWidth={true}
            className={classes.marginBottom}
            onChange={handleLoginChange}
          />
          <TextField
            required
            fullWidth
            name="password"
            label="Hasło:"
            type="password"
            id="password"
            className={classes.marginBottom}
            onChange={handlePasswordChange}
          />
          <TextField
            required
            fullWidth
            name="repeatpassword"
            label="Powtórz Hasło:"
            type="password"
            id="repeatedPassword"
            className={classes.marginBottom}
            onChange={handleRepeatPasswordChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            autoFocus
            color="primary"
            disabled={!passwordCorrect || !repeatpasswordCorrect}
            onClick={() => {
              notEmpty(
                firstName,
                surname,
                phone,
                email,
                employeeTypes,
                employeeAccess,
                login,
                password,
                onClose,
                updateData,
                setErrorMessage,
                setError
              );
            }}
          >
            Zapisz
          </Button>
        </DialogActions>
        {error && <Alert severity="error">{errorMessage}</Alert>}
      </Dialog>
    </div>
  );
}
