import React from "react";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as EmailValidator from "email-validator";
import Checkbox from "@material-ui/core/Checkbox";
import { countries } from "../locales.json";
import moment from "moment";
import validator from "validator";
import Alert from "@material-ui/lab/Alert";
import { Axios } from ".././middleware/axiosInstance";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];

function saveData(cars, driver, loadPlace, date, creator, close, updateData) {
  let documents = {};
  documents.car = cars;
  documents.driver = driver;
  documents.loadingPlace = loadPlace;
  documents.deliveryDate = date;
  documents.creator = creator;

  Axios({
    method: "POST",
    withCredentials: true,
    url: `${API_HOST}/document/add`,
    data: documents,
  })
    .then((res) => {
      updateData();
      close();
    })
    .catch((error) => {
      console.log(error.response.data.msg);
    });
}
function notEmpty(
  car,
  driver,
  loadPlace,
  date,
  creator,
  onClose,
  updateData,
  setError,
  setErrorMessage
) {
  if (!car || !driver || !loadPlace || !date) {
    setError(true);
    setErrorMessage("Wszystkie pola muszą być wypełnione!");
  } else {
    setError(false);
    setErrorMessage(null);
    saveData(car, driver, loadPlace, date, creator, onClose, updateData);
  }
}
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: 20,
  },
  marginRight: {
    marginRight: 20,
  },
  marginTop: {
    marginTop: 20,
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({
  documents,
  open,
  onClose = (f) => f,
  updateData = (f) => f,
  types,
  cars,
  employee,
  loadUser,
  placed,
}) {
  const [car, setCar] = React.useState();
  const [driver, setDriver] = React.useState();
  const [loadPlace, setLoadPlace] = React.useState();
  const [date, setDate] = React.useState();
  const [creator, setCreator] = React.useState();

  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const handleCarChange = (value) => {
    if (value) {
      setCar(value);
    }
  };
  const handleDriverChange = (value) => {
    if (value) {
      setDriver(value);
    }
  };
  const handleLoadingPlaceChange = (value) => {
    if (value) {
      setLoadPlace(value);
    }
  };
  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  React.useEffect(() => {
    setError(false);
    setCar(null);
    setDriver(null);
    setLoadPlace(null);
    setDate(null);
    setErrorMessage(null);
    setCreator(loadUser);
  }, [open]);
  const classes = useStyles();

  return (
    <div>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          Utwórz nowy dokument
        </DialogTitle>
        <DialogContent dividers>
          <Autocomplete
            required
            onChange={(event, value) => handleCarChange(value)}
            options={cars}
            getOptionLabel={(option) => {
              return `${option.carBrand} ${option.registrationNumber}`;
            }}
            className={classes.marginBottom}
            getRowId={(row) => row._id}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Pojazd: "
                placeholder={cars.carBrand}
                value={cars}
              />
            )}
          />
          <Autocomplete
            required
            onChange={(event, value) => handleDriverChange(value)}
            options={employee.employee}
            getOptionLabel={(option) => {
              return `${option.firstName} ${option.surname}`;
            }}
            className={classes.marginBottom}
            getRowId={(row) => row._id}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Kierowca: "
                value={employee.employee}
              />
            )}
          />
          <Autocomplete
            required
            onChange={(event, value) => handleLoadingPlaceChange(value)}
            options={placed}
            // defaultValue={types[0]}
            getOptionLabel={(option) => {
              return `${option.name}`;
            }}
            className={classes.marginBottom}
            getRowId={(row) => row._id}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Miejsce załadunku: "
                value={placed}
              />
            )}
          />
          <label>Data załadunku:</label>
          <TextField
            required
            id="loadingDate"
            type="date"
            fullWidth={true}
            className={classes.marginBottom}
            onChange={handleDateChange}
          />
          <label>Wystawca:</label>
          <TextField
            disabled
            id="creator"
            // label="Zlecenie Przygotował "
            type="string"
            fullWidth={true}
            className={classes.marginBottom}
            value={loadUser.firstName + " " + loadUser.surname}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant="outlined"
            color="primary"
            className={(classes.marginTop, classes.marginRight)}
            onClick={() => {
              notEmpty(
                car,
                driver,
                loadPlace,
                date,
                creator,
                onClose,
                updateData,
                setError,
                setErrorMessage
              );
            }}
          >
            Dalej
          </Button>
        </DialogActions>
        {error && <Alert severity="error">{errorMessage}</Alert>}
      </Dialog>
    </div>
  );
}
