import React, { useState } from "react";
import ReactDOM from "react-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Toolbar from "@material-ui/core/Toolbar";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Axios } from "./middleware/axiosInstance";
import App from "../App";
import Alert from "@material-ui/lab/Alert";
import isAuthenticated from "./middleware/isAuthenticated";
import { countries } from "./locales.json";
import {
  createMuiTheme,
  Paper,
  ThemeProvider,
  useMediaQuery,
} from "@material-ui/core";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../config.json")["API_HOST"][env];
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://bialpak.pl/">
        BPdev
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}
const autocompleteStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      // Default transform is "translate(14px, 20px) scale(1)""
      // This lines up the label with the initial cursor position in the input
      // after changing its padding-left.
      transform: "translate(14px, 20px) scale(1);",
    },
  },
  inputRoot: {
    color: "black",
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 6,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
  },
  clearIndicator: {
    color: "black",
  },
  popupIndicator: {
    color: "black",
  },
}));

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const autocompClass = autocompleteStyles();
  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [wrongPasswordAlert, setWrongPasswordAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [lang] = React.useState(countries);
  const [choosenLang, setChoosenLang] = React.useState(lang[0]);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
          action: {
            active: "#ff6634",
          },
          primary: {
            main: "#ff6634",
          },
          // white: {
          //   main: "ffffff",
          // },
        },
        typography: {
          fontFamily: ["Lato", "sans-serif"].join(","),
        },
      }),
    [prefersDarkMode]
  );
  const handleLangChange = (event, value) => {
    setChoosenLang(value);
  };
  const login = () => {
    Axios({
      method: "POST",
      data: {
        username: loginUsername,
        password: loginPassword,
      },
      withCredentials: true,
      url: `${API_HOST}/login`,
    }).then((res) => {
      console.log(res);
      if (res.status === 200) {
        if (res.data.error) {
          setWrongPasswordAlert(true);
          setAlertMessage(res.data.error);
          setTimeout(() => {
            setWrongPasswordAlert(false);
          }, 8000);
        } else {
          ReactDOM.render(
            <React.StrictMode>
              <App />
            </React.StrictMode>,
            document.getElementById("root")
          );
        }
      }
    });
  };
  // React.useEffect(()=>{
  //   isAuthenticated((res)=>{
  //     if(res){
  //       ReactDOM.render(
  //         <React.StrictMode>
  //           <App />
  //         </React.StrictMode>,
  //         document.getElementById('root')
  //       );
  //     }
  //   })
  // },[])
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Toolbar>
          {/* <Typography variant="h6" className={classes.width100FL}>
      {choosenLang.translation.advice} - Bialpak
    </Typography> */}
          <Autocomplete
            id="country-select-demo"
            style={{
              width: 300,
              color: "black",
              position: "absolute",
              right: 20,
            }}
            options={lang}
            classes={autocompClass}
            onChange={handleLangChange}
            autoHighlight
            size="small"
            value={choosenLang}
            getOptionLabel={(option) => option.label}
            renderOption={(option) => (
              <React.Fragment>
                <span>{countryToFlag(option.code)}&nbsp;</span>
                {option.label}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`${choosenLang.translation.choose_language}`}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
        </Toolbar>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {choosenLang.translation.login}
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="login"
                label={`Login`}
                name="login"
                autoComplete="login"
                autoFocus
                onChange={(e) => setLoginUsername(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={`${choosenLang.translation.password}`}
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setLoginPassword(e.target.value)}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label={`${choosenLang.translation.remember_me}`}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={login}
              >
                {`${choosenLang.translation.log_in}`}
              </Button>
              {wrongPasswordAlert && (
                <Alert style={{ marginBottom: "10px" }} severity="error">
                  {alertMessage}
                </Alert>
              )}
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    {`${choosenLang.translation.forgotten_password}`}
                  </Link>
                </Grid>
                {/* <Grid item>
              <Link href="#" variant="body2">
                {"Nie masz konta? Załóż tutaj"}
              </Link>
            </Grid> */}
              </Grid>
            </form>
          </div>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Container>
      </div>
    </ThemeProvider>
  );
}
