import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import ReactDOM from "react-dom";
import SignIn from ".././SignIn";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Snackbar } from "@material-ui/core";
const Axios = axios.create({
  baseURL: "",
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function AxiosAlert() {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [errorSeverity, setErrorSeverity] = useState("error");
  Axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        ReactDOM.render(
          <React.StrictMode>
            <SignIn />
            <Snackbar
              open={true}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              autoHideDuration={6000}
              onClose={() => {
                setError(false);
              }}
            >
              <Alert
                variant="filled"
                onClose={() => {
                  setError(false);
                }}
                severity="error"
              >
                {"Twoja sesja wygasła"}
              </Alert>
            </Snackbar>
          </React.StrictMode>,
          document.getElementById("root")
        );
      }
      if (error.response) {
        setError(`${error.response.data.msg}: ${error.response.data.err}`);
        if (error.response.data.level === 2) {
          setErrorSeverity("warning");
        }
      } else {
        setError(`${error}`);
      }
      return Promise.reject(error);
    }
  );
  return (
    <div className={classes.root}>
      {error && (
        <Snackbar
          open={Boolean(error)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={6000}
          onClose={() => {
            setError(false);
          }}
        >
          <Alert
            onClose={() => {
              setError(false);
            }}
            severity={errorSeverity}
            variant="filled"
          >
            {error}
          </Alert>
        </Snackbar>
        // <Alert
        //   onClose={() => {
        //     setError(false);
        //   }}
        // >
        //   {error}
        // </Alert>
      )}
    </div>
  );
}

export { Axios, AxiosAlert };
