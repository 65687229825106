import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Drawer,
  Typography,
  IconButton,
  ListSubheader,
  ListItemSecondaryAction,
  Snackbar,
} from "@material-ui/core";
import React from "react";
import { Axios } from "../middleware/axiosInstance";
import { withStyles } from "@material-ui/core/styles";
import AnalyticsChart from "./GoogleAnalyticsChart";
import {
  Add,
  ArrowBack,
  ArrowLeft,
  Close,
  LocalShipping,
} from "@material-ui/icons";
import CustomerDrawer from "../sales_customers/CustomerDrawer";
import Alert from "@material-ui/lab/Alert";
require("dotenv").config();
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];
function addCustomer(hook, customer) {
  Axios({
    method: "POST",
    withCredentials: true,
    url: `${API_HOST}/customer/add`,
    data: customer,
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ successMsg: res.data.msg });
      hook.setState({ openCustomerDrawer: false });
      getAllCustomers(hook, res.data.customer);
    } else {
      console.log(res.data.msg);
    }
  });
}
function getAllCustomers(hook) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/customer/get/all`,
  }).then((res) => {
    if (!res.data.err) {
      let dataSort = res.data.data.sort((a, b) => (a.name > b.name ? 1 : -1));
      hook.setState({ customers: dataSort });
      let customersIds = res.data.data.map((prod) => {
        return [prod.clientIdSubiekt];
      });
      hook.setState({ allCustomersIds: customersIds });
    } else {
      console.log(res.data.msg);
    }
  });
}

const styles = (theme) => ({
  root: {
    width: "100%",
    position: "relative",
    overflow: "auto",
    maxHeight: "80vh",
  },
  listSection: {
    backgroundColor: theme.palette.background.default,
  },
  ul: {
    backgroundColor: theme.palette.background.paper,
    padding: 0,
  },
  subheader: {
    backgroundColor: theme.palette.background.default,
  },
  orderInfo: {
    padding: 15,
    backgroundColor: theme.palette.background.default,
  },
});

const StyledPaper = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(Paper);

const GroupListItems = (props) => {
  return props.group.orders.map((order) => {
    const labelId = `checkbox-list-label-${order.id}`;

    return (
      <ListItem
        style={{ width: "100%" }}
        key={order.id}
        role={undefined}
        button
        onClick={() => props.clickHandler(order)}
      >
        <ListItemText
          id={labelId}
          primary={`${order.customer[0].name}`}
          secondary={`${order.orderIdSubiekt}`}
        />
        <ListItemSecondaryAction>
          <Typography variant="subtitle1" color="primary">
            {order.amount ? Number(order.amount).toFixed(2) : 0} zł
          </Typography>
        </ListItemSecondaryAction>
      </ListItem>
    );
  });
};

class Greet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      ordersValue: 0,
      orders: [],
      openCustomerDrawer: false,
      openCustomerOrderDrawer: false,
      openOrdersDrawer: false,
      allCustomersIds: [],
      successMsg: false,
      shownOrder: { orderIdSubiekt: "", products: [], customer: { name: "" } },
    };
  }
  componentDidMount() {
    Axios({
      method: "GET",
      withCredentials: true,
      url: `${API_HOST}/user`,
    }).then((res) => {
      const username = res.data.user.firstName;
      const ordersValue = res.data.customersOrdersValue;
      const orders = res.data.orders;
      console.log(res.data);
      this.setState({ username });
      this.setState({ ordersValue });
      this.setState({ orders });
    });
    getAllCustomers(this);
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item xs={12} md={12} lg={6}>
          <StyledPaper>
            <Typography
              variant="h6"
              style={{ width: "100%", float: "left", marginBottom: 20 }}
            >
              Witaj, {this.state.username}
            </Typography>
            <Box style={{ width: "100%" }}>
              <Typography
                variant="subtitle1"
                style={{
                  color: "gray",
                  lineHeight: 1,
                }}
                align="right"
                gutterBottom
                component="div"
              >
                Suma zamówień twoich klientów:
              </Typography>
              <Typography
                variant="h4"
                align="right"
                gutterBottom
                component="div"
              >
                {Number(this.state.ordersValue).toFixed(2)} zł
              </Typography>
            </Box>
            <Grid
              container
              direction="row"
              justifyContent="end"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={6} md={6} lg={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth={true}
                  startIcon={<LocalShipping />}
                  onClick={() => {
                    this.setState({ openOrdersDrawer: true });
                  }}
                >
                  Zamówienia
                </Button>
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ color: "white" }}
                  fullWidth={true}
                  startIcon={<Add />}
                  onClick={() => {
                    this.setState({ openCustomerDrawer: true });
                  }}
                >
                  Dodaj klienta
                </Button>
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <StyledPaper>
            <AnalyticsChart />
          </StyledPaper>
        </Grid>
        <CustomerDrawer
          open={this.state.openCustomerDrawer}
          edit={false}
          data={{
            customersIds: this.state.allCustomersIds,
          }}
          onClose={() => {
            this.setState({ openCustomerDrawer: false });
          }}
          onSave={(customer, edit) => {
            if (edit) {
            } else {
              addCustomer(this, customer);
            }
          }}
        />
        <Drawer
          anchor={"right"}
          open={this.state.openOrdersDrawer}
          onClose={() => {
            this.setState({ openOrdersDrawer: false });
          }}
        >
          <Box
            m={2}
            style={{
              maxWidth: 600,
              width: "100vw",
              padding: 0,
              margin: 0,
            }}
          >
            <Grid
              container
              spacing={2}
              style={{ flex: "none", height: 75, width: "100%" }}
            >
              <Grid item xs={12} md={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <IconButton
                    onClick={() => {
                      this.setState({ openOrdersDrawer: false });
                    }}
                    style={{ float: "float" }}
                  >
                    <ArrowBack fontSize="1.5rem" />
                  </IconButton>
                  <Typography style={{ padding: 15 }} variant="h5">
                    Zamówienia klientów
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <List
              style={{
                width: "100%",
                overflow: "overlay",
                padding: 0,
                flex: "auto",
                maxHeight: "calc(100vh - 75px)",
              }}
              className={classes.root}
              subheader={<li />}
              box
            >
              {this.state.orders.map((orderGroup) => {
                let group = orderGroup;
                return (
                  <div>
                    <li
                      key={`section-${group._id}`}
                      className={classes.listSection}
                    >
                      <ul className={classes.ul}>
                        <ListSubheader className={classes.subheader}>
                          {new Date(group._id).toLocaleDateString()}
                        </ListSubheader>
                        <GroupListItems
                          group={group}
                          clickHandler={(order) => {
                            console.log(order);
                            this.setState({
                              shownOrder: order,
                              openCustomerOrderDrawer: true,
                            });
                          }}
                        />
                      </ul>
                    </li>
                  </div>
                );
              })}
            </List>
          </Box>
        </Drawer>
        <Drawer
          anchor={"right"}
          open={this.state.openCustomerOrderDrawer}
          onClose={() => {
            this.setState({ openCustomerOrderDrawer: false });
          }}
        >
          <Box
            m={2}
            style={{
              maxWidth: 600,
              width: "100vw",
              padding: 0,
              margin: 0,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <IconButton
                    onClick={() => {
                      this.setState({ openCustomerOrderDrawer: false });
                    }}
                    style={{ float: "float" }}
                  >
                    <ArrowBack fontSize="1.5rem" />
                  </IconButton>
                  <Typography style={{ padding: 15 }} variant="h5">
                    Zamówienie {this.state.shownOrder?.orderIdSubiekt}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Box boxShadow={2}>
              <Box className={classes.orderInfo}>
                <Typography variant="h5">
                  {this.state.shownOrder?.customer[0]?.name}...
                </Typography>
                <Typography variant="h5" color="primary">
                  {Number(this.state.shownOrder?.amount).toFixed(2)} zł
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "gray",
                    lineHeight: 1,
                  }}
                >
                  Data zamówienia
                </Typography>
                <Typography variant="h6">
                  {new Date(this.state.shownOrder?.date).toLocaleDateString()}
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "gray",
                    lineHeight: 1,
                  }}
                >
                  Uwagi
                </Typography>
                <Typography variant="h6">
                  {this.state.shownOrder?.comment
                    ? this.state.shownOrder?.comment
                    : "Brak"}
                </Typography>
              </Box>

              <List
                style={{ width: "100%", overflow: "overlay" }}
                className={classes.root}
              >
                {this.state.shownOrder?.productsData?.map((prod) => {
                  if (prod.name) {
                    const labelId = `checkbox-products-list-label-${prod.id}`;
                    return (
                      <ListItem
                        style={{ width: "100%", padding: 0 }}
                        key={prod.id}
                        role={undefined}
                      >
                        <ListItemText
                          id={labelId}
                          style={{ marginLeft: 15 }}
                          primary={`${prod.name.slice(0, 30)}...`}
                          secondary={`${prod.qty} x ${prod.price.toFixed(2)}zł`}
                        />
                        <ListItemSecondaryAction>
                          <Typography variant="subtitle1" color="primary">
                            {Number(prod.qty * prod.price.toFixed(2)).toFixed(
                              2
                            )}{" "}
                            zł
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  } else return null;
                })}
              </List>
            </Box>
          </Box>
        </Drawer>
        <Snackbar
          open={Boolean(this.state.successMsg)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={6000}
          onClose={() => {
            this.setState({ successMsg: false });
          }}
        >
          <Alert
            onClose={() => {
              this.setState({ successMsg: false });
            }}
            severity="success"
            variant="filled"
          >
            {this.state.successMsg}
          </Alert>
        </Snackbar>
      </Grid>
    );
  }
}
export default withStyles(styles, { withTheme: true })(Greet);
