import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import { Button, Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import EmployeeAdd from "./employeeAddModal";
import EmployeeEdit from "./employeeEditModal";
import { makeStyles } from "@material-ui/core/styles";
import { Axios } from ".././middleware/axiosInstance";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];

function getEmployees(hook) {
  let param;
  if (hook.props.driver) {
    param = "Kierowca";
    hook.setState({ employeesType: "Kierowcy" });
  } else {
    hook.setState({ employeesType: "Pracownicy" });
  }
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/users/get/all`,
    params: { userType: param },
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ employees: res.data.data.employee });
      hook.setState({ types: res.data.data.types });
      hook.setState({ access: res.data.data.access });
      hook.setState({ additionalEmails: res.data.data.additionalEmails });
    } else {
      console.log(res.data.msg);
    }
  });
}
function deleteEmployee(hook, data) {
  Axios({
    method: "DELETE",
    withCredentials: true,
    url: `${API_HOST}/user/delete`,
    params: { ids: data },
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ databaseState: true });
      getEmployees(hook);
    } else {
      hook.setState({ databaseState: false });
      console.log(res.data.msg);
    }
  });
}
function openEditEmployeeWindow(hook, employee) {
  hook.setState({ editEmployee: true, employeesEvent: employee });
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: 20,
  },
  marginRight: {
    marginRight: 20,
  },
}));

function employeesColumns(hook) {
  return [
    {
      field: "firstName",
      headerName: "Imię ",
      width: 200,
      type: "string",
      sortable: true,
    },
    {
      field: "surname",
      headerName: "Nazwisko ",
      width: 200,
      type: "string",
      sortable: true,
    },
    {
      field: "phone",
      headerName: "Numer Telefonu",
      width: 200,
      type: "string",
      sortable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      type: "string",
      sortable: true,
    },
    {
      field: "typeUser",
      headerName: "Stanowisko",
      width: 250,
      type: "string",
      sortable: true,
    },
    {
      field: "accessUser",
      headerName: "Dostęp",
      width: 250,
      type: "string",
      sortable: true,
      // renderCell: (params) => {
      //   console.log(params.row);
      //   return <Typography>{params.row}</Typography>;
      // },
    },

    {
      field: "_id",
      headerName: " ",

      renderCell: (params) => {
        if (!hook.props.driver) {
          return (
            <Button
              color="primary"
              startIcon={<EditIcon />}
              onClick={() => {
                console.log(params.row);
                openEditEmployeeWindow(hook, params.row);
              }}
            ></Button>
          );
        } else {
          return " ";
        }
      },
    },
  ];
}

export default class employeeGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      employees: [
        {
          _id: "0",
          firstName: "asda",
          surname: "ss@wp.pl",
          phone: 7289123111,
          typeUser: "Pracownik",
          username: "pracowmik",
        },
      ],
      rowSelected: [
        {
          _id: "0",
          firstName: "asda",
          surname: "ss@wp.pl",
          phone: 7289123111,
          typeUser: "Pracownik",
          username: "pracowmik",
        },
      ],
      employeesEvent: {},
      types: [],
      access: [],
      additionalEmails: [],
      clickedRowIds: [],
      employeesType: [],
    };
  }
  componentDidMount() {
    getEmployees(this);
  }

  render() {
    const { viewModel, employeesEvent } = this.state;
    console.log();
    return (
      <div>
        <h2>{this.state.employeesType}</h2>

        <DataGrid
          // rowKey="id"
          rows={this.state.employees}
          columns={employeesColumns(this)}
          onRowSelection={this.handleRowSelection}
          disableMultipleSelection={false}
          pageSize={10}
          autoHeight={true}
          checkboxSelection
          disableSelectionOnClick
          getRowId={(row) => row._id}
          onSelectionModelChange={(newSelection) => {
            this.setState({ rowSelected: newSelection.selectionModel });
          }}
        />
        <EmployeeAdd
          employees={employeesEvent}
          open={this.state.addEmployeeWindow}
          onClose={this.closeAddDialog}
          updateData={this.updateData}
          driver={this.props.driver}
          types={this.state.types}
          access={this.state.access}
          additionalEmails={this.state.additionalEmails}
        />
        <EmployeeEdit
          open={this.state.editEmployee}
          onClose={this.closeEditorDialog}
          updateData={this.updateData}
          types={this.state.types}
          access={this.state.access}
          additionalEmails={this.state.additionalEmails}
          employees={employeesEvent}
        />
        <br />

        <Button
          variant="outlined"
          color="primary"
          style={{ marginRight: 10 }}
          onClick={() => {
            this.setState({ addEmployeeWindow: true });
          }}
        >
          Dodaj
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            deleteEmployee(this, this.state.rowSelected);
          }}
          color="primary"
          style={{ marginRight: 20 }}
        >
          Usuń
        </Button>
      </div>
    );
  }
  closeAddDialog = () => {
    this.setState({ addEmployeeWindow: false });
  };
  closeEditorDialog = () => {
    this.setState({ editEmployee: false });
  };
  updateData = () => {
    getEmployees(this);
  };
}
