import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import * as EmailValidator from "email-validator";
import Checkbox from "@material-ui/core/Checkbox";
import { countries } from ".././locales.json";
import { Axios } from ".././middleware/axiosInstance";
import Alert from "@material-ui/lab/Alert";
import validator from "validator";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];

function saveData(name, email, phone, password, close, updateData) {
  console.log(password);
  let client = {};
  client.name = name;
  client.email = email;
  client.phone = phone;
  client.password = password;
  Axios({
    method: "POST",
    withCredentials: true,
    url: `${API_HOST}/client/add`,
    data: client,
  }).then((res) => {
    console.log(res.data);
    updateData();

    close();
  });
}

function notEmpty(
  name,
  email,
  phone,
  password,
  close,
  updateData,
  setErrorMessage,
  setError,
  addPassword
) {
  if (addPassword) {
    if (!name || !email || !phone || !password) {
      setError(true);
      setErrorMessage("Wszystkie pola muszą być wypełnione!");
    } else {
      setError(false);
      setErrorMessage(null);
      saveData(name, email, phone, password, close, updateData);
    }
  } else {
    if (!name || !email || !phone) {
      setError(true);
      setErrorMessage("Wszystkie pola muszą być wypełnione!");
    } else {
      setError(false);
      setErrorMessage(null);
      saveData(name, email, phone, password, close, updateData);
    }
  }
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: 20,
  },
  marginRight: {
    marginRight: 20,
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({
  client,
  open,
  onClose = (f) => f,
  updateData = (f) => f,
}) {
  const [state, setState] = React.useState({ checkedB: true });
  const [name, setName] = React.useState();
  const [email, setEmail] = React.useState();
  const [phone, setPhone] = React.useState();
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [addPassword, setaddPassword] = React.useState(false);
  const [lang] = React.useState(countries);
  const [choosenLang, setChoosenLang] = React.useState(lang[1]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleEmailChange = (event) => {
    let emailValue = event.target.value;
    validateEmail(emailValue);
    setEmail(emailValue);
  };

  const validateEmail = (value) => {
    var email = value;
    if (validator.isEmail(email)) {
      setError(false);
    } else {
      setError(true);
      setErrorMessage("Nie poprawny adres email!");
    }
  };
  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleAddPasswordChange = (event) => {
    setaddPassword(event.target.checked);
  };

  React.useEffect(() => {
    setPassword("");
    setErrorMessage(null);
    setError(false);
    setaddPassword(false);
  }, [open]);
  const classes = useStyles();
  return (
    <div>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          Dodaj Nowego Klienta
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            id="name"
            label="Nazwa Klienta"
            fullWidth={true}
            className={classes.marginBottom}
            onChange={handleNameChange}
          />
          <TextField
            id="email"
            label="Adres Email"
            type="email"
            fullWidth={true}
            className={classes.marginBottom}
            onChange={handleEmailChange}
          />
          <TextField
            id="phone"
            label="Numer Telefonu"
            type="Number"
            fullWidth={true}
            className={classes.marginBottom}
            onChange={handlePhoneChange}
          />
          <FormControlLabel
            value="addPassoword"
            control={<Checkbox color="primary" />}
            label="Czy chcesz nadać hasło?"
            labelPlacement="right"
            onChange={handleAddPasswordChange}
          />
          {addPassword && (
            <TextField
              id="clientPassword"
              label="Hasło"
              type="password"
              fullWidth={true}
              className={classes.marginBottom}
              onChange={handlePasswordChange}
            />
          )}
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            autoFocus
            onClick={() => {
              notEmpty(
                name,
                email,
                phone,
                password,
                onClose,
                updateData,
                setErrorMessage,
                setError,
                addPassword
              );
            }}
          >
            Zapisz
          </Button>
        </DialogActions>
        {error && <Alert severity="error">{errorMessage}</Alert>}
      </Dialog>
    </div>
  );
}
