import React from "react";
import { Axios } from ".././middleware/axiosInstance";
import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import { RenderCellExpand } from "./renderCellExpand";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];

function getLogs(hook) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/errorMsg/get/all`,
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ logs: res.data.data });
    } else {
      console.log(res.data.msg);
    }
  });
}
function deleteLog(hook, data) {
  Axios({
    method: "DELETE",
    withCredentials: true,
    url: `${API_HOST}/errorMsg/delete`,
    params: { _id: data },
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ databaseState: true });
      getLogs(hook);
    } else {
      hook.setState({ databaseState: false });
    }
  });
}
const logColumns = [
  {
    field: "createdAt",
    headerName: "Data",
    width: 170,
    type: "string",
    sortable: true,
    valueFormatter: (params) => {
      return moment(params.value).format("DD/MM/YYYY HH:mm");
    },
  },
  {
    field: "name",
    headerName: "Nazwa",
    width: 170,
    type: "string",
    sortable: true,
  },
  {
    field: "message",
    headerName: "Wiadomość",
    width: 170,
    type: "string",
    sortable: true,
    renderCell: RenderCellExpand,
  },
  {
    field: "error",
    headerName: "Error",
    width: 570,
    type: "string",
    sortable: true,
    renderCell: RenderCellExpand,
  },
  {
    field: "stack",
    headerName: "Stack",
    width: 570,
    type: "string",
    sortable: false,
    renderCell: RenderCellExpand,
  },
];

export default class InvitationsGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: [],
      rowSelected: [],
      invitationsClick: { start: "placeholder", end: "placeholder" },
      sortModel: [
        {
          field: "createdAt",
          sort: "desc",
        },
      ],
    };
  }
  componentDidMount() {
    getLogs(this);
  }

  render() {
    return (
      <div>
        <h2>Błędy</h2>
        <DataGrid
          rowHeight={80}
          rowKey="id"
          rows={this.state.logs}
          sortingOrder={["desc", "asc"]}
          disableSelectionOnClick
          //onSortModelChange={(model) => this.setState({ sortModel: model })}
          sortModel={this.state.sortModel}
          columns={logColumns}
          onRowSelection={this.handleRowSelection}
          pageSize={10}
          autoHeight={true}
          checkboxSelection
          onSelectionModelChange={(newSelection) => {
            console.log(newSelection.selectionModel);
            this.setState({
              rowSelected: newSelection.selectionModel,
            });
          }}
          getRowId={(row) => row._id}
        />
        <br />
        <Button
          variant="outlined"
          onClick={() => {
            deleteLog(this, this.state.rowSelected);
          }}
          color="primary"
        >
          Usuń
        </Button>
      </div>
    );
  }
}
