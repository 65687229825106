import { Button, IconButton, Snackbar } from "@material-ui/core";
import { Add, Delete, Remove } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import React, { Component } from "react";
import SortableTree, {
  getFlatDataFromTree,
  getTreeFromFlatData,
  changeNodeAtPath,
  addNodeUnderParent,
  removeNodeAtPath,
  getDescendantCount,
} from "react-sortable-tree";
// In your own app, you would need to use import styles once in the app
import "react-sortable-tree/style.css";
import CustomRenderer from "./category_editor_utils/node-renderer-custom";

export default class App extends Component {
  constructor(props) {
    super(props);
    console.log(props.flatCategories);
    this.state = {
      treeData: getTreeFromFlatData({
        flatData: props.flatCategories.map((node) => ({
          ...node,
          title: node.label,
        })),
        getKey: (node) => node.id, // resolve a node's key
        getParentKey: (node) => node.parentCategoryId, // resolve a node's parent's key
        rootKey: 0, // The value of the parent key when there is no parent (i.e., at root level)
      }),
      errorMsg: null,
    };
  }

  render() {
    const getNodeKey = ({ treeIndex }) => treeIndex;
    const flatData = (tree) =>
      getFlatDataFromTree({
        treeData: tree,
        getNodeKey: ({ node }) => node.id, // This ensures your "id" properties are exported in the path
        ignoreCollapsed: false, // Makes sure you traverse every node in the tree, not just the visible ones
      }).map(({ node, path }) => ({
        id: node.id,
        name: node.label,

        // The last entry in the path is this node's key
        // The second to last entry (accessed here) is the parent node's key
        parentCategoryId: path.length > 1 ? path[path.length - 2] : 0,
      }));

    return (
      <div style={{ minWidth: 350 }}>
        <div style={{ height: 550 }}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<Add />}
            onClick={() =>
              this.setState((state) => ({
                treeData: [
                  {
                    new: true,
                    parentCategoryId: 0,
                    label: `Nowa kategoria`,
                  },
                ].concat(state.treeData),
              }))
            }
          >
            Dodaj kategorię główną
          </Button>
          <SortableTree
            treeData={this.state.treeData}
            onChange={(treeData) => {
              this.setState({ treeData });
              this.props.onUpdate(flatData(treeData));
            }}
            isVirtualized={false}
            generateNodeProps={({ node, path }) => ({
              title: (
                <input
                  style={{ fontSize: "1.0rem", width: 150 }}
                  value={node.label}
                  onChange={(event) => {
                    const label = event.target.value;
                    let newTreeData = {
                      treeData: changeNodeAtPath({
                        treeData: this.state.treeData,
                        path,
                        getNodeKey,
                        newNode: { ...node, label },
                      }),
                    };
                    this.setState({ treeData: newTreeData.treeData });
                    this.props.onUpdate(flatData(newTreeData.treeData));
                  }}
                />
              ),
              buttons: [
                <IconButton
                  aria-label="add"
                  onClick={() => {
                    console.log(path);
                    let newTreeData = {
                      treeData: addNodeUnderParent({
                        treeData: this.state.treeData,
                        parentKey: path[path.length - 1],
                        expandParent: true,
                        getNodeKey,
                        newNode: {
                          parentCategoryId: path,

                          label: `Nowa kategoria`,
                        },
                        addAsFirstChild: true,
                      }).treeData,
                    };
                    this.setState(newTreeData);
                    this.props.onUpdate(flatData(newTreeData.treeData));
                  }}
                >
                  <Add />
                </IconButton>,
                <IconButton
                  aria-label="remove"
                  onClick={() => {
                    let count = getDescendantCount({
                      ignoreCollapsed: false,
                      node: node,
                    });
                    if (!count) {
                      let newTreeData = {
                        treeData: removeNodeAtPath({
                          treeData: this.state.treeData,
                          path,
                          getNodeKey,
                        }),
                      };
                      this.setState(newTreeData);
                      this.props.onUpdate(flatData(newTreeData.treeData));
                    } else {
                      this.setState({
                        errorMsg:
                          "Ta kategoria posiada podkategorie. Nie można jej usunąć.",
                      });
                    }
                  }}
                >
                  <Delete />
                </IconButton>,
              ],
            })}
            //nodeContentRenderer={CustomRenderer}
          />
        </div>
        <Snackbar
          open={Boolean(this.state.errorMsg)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={6000}
          onClose={() => {
            this.setState({ errorMsg: false });
          }}
        >
          <Alert
            onClose={() => {
              this.setState({ errorMsg: false });
            }}
            severity="error"
            variant="filled"
          >
            {this.state.errorMsg}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}
