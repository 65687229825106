import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import { withStyles } from "@material-ui/core/styles";
import { white } from "@material-ui/core/colors";
import InfoIcon from "@material-ui/icons/Info";
import { Radio, Typography } from "@material-ui/core";
import { Axios } from ".././middleware/axiosInstance";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];
function getAllProductImages(setImages, id) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/image/get/byProduct`,
    params: { id: id },
  })
    .then((res) => {
      if (!res.data.err) {
        console.log(res.data);
        setImages(res.data.files);
      }
    })
    .catch((err) => {
      console.log(err);
      setImages([]);
    });
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  title: {
    color: "#ffffff",
  },
  titleBar: {
    background: "rgba(0,0,0,0.5)",
  },
}));

const WhiteRadio = withStyles({
  root: {
    color: "#ffffff",
    "&$checked": {
      color: "#ffffff",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default function ImagePicker(props) {
  const classes = useStyles();
  const [imageUrls, setImages] = React.useState([]);
  const [selectedImage, setSelectedImage] = React.useState();
  React.useEffect(() => {
    if (props.product.id) {
      getAllProductImages(setImages, props.product.id);
    }
  }, [props.product.id]);
  React.useEffect(() => {
    getAllProductImages(setImages, props.product.id);
  }, [props.uploadedImages]);
  React.useEffect(() => {
    setSelectedImage(props.selectedImage);
  }, [props.selectedImage]);
  const handleChange = (event) => {
    props.onSelectedChange(event.target.value);
  };
  return (
    <div className={classes.root}>
      {imageUrls.length === 0 && (
        <Typography variant="body1">Brak zdjęć produktu</Typography>
      )}
      {imageUrls.length !== 0 && (
        <ImageList className={classes.imageList} cols={2.5}>
          {imageUrls.length > 0 &&
            imageUrls?.map((filename) => (
              <ImageListItem key={filename}>
                <img
                  src={`images/products/${props.product.id}/${filename}`}
                  alt={filename}
                  style={{ width: "100%" }}
                />
                <ImageListItemBar
                  title={
                    selectedImage === filename
                      ? "Zdjęcie główne"
                      : "Zdjęcie pomocnicze"
                  }
                  classes={{
                    root: classes.titleBar,
                    title: classes.title,
                  }}
                  actionIcon={
                    <WhiteRadio
                      checked={selectedImage === filename}
                      onChange={handleChange}
                      value={filename}
                      name="radio-button-product-image"
                      inputProps={{ "aria-label": `${filename}` }}
                    />
                  }
                />
              </ImageListItem>
            ))}
        </ImageList>
      )}
    </div>
  );
}
