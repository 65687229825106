import React from "react";
import { Axios } from ".././middleware/axiosInstance";
import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { withStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import moment, { relativeTimeRounding } from "moment";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];

function getInvitations(hook) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/invitation/get/all/window`,
  }).then((res) => {
    if (!res.data.err) {
      console.log("-----");
      console.log(res.data.data);
      hook.setState({ invitations: res.data.data });
    } else {
      console.log(res.data.msg);
    }
  });
}
function updateInvitation(hook, data) {
  Axios({
    method: "PUT",
    withCredentials: true,
    url: `${API_HOST}/invitation/update`,
    params: data,
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ databaseState: true });
      getInvitations(hook);
    } else {
      hook.setState({ databaseState: false });
      console.log(res.data.msg);
    }
  });
}
function deleteInvitations(hook, data) {
  Axios({
    method: "DELETE",
    withCredentials: true,
    url: `${API_HOST}/invitation/delete`,
    params: { ids: data },
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ databaseState: true });
      getInvitations(hook);
    } else {
      hook.setState({ databaseState: false });
      console.log(res.data.msg);
    }
  });
}

const StyledChip = withStyles((ChipTheme) => ({
  root: {
    padding: 5,
    width: 180,
    float: "left",
  },
}))(Chip);

const StyledPopover = withStyles((ChipTheme) => ({
  root: {
    pointerEvents: "none",
  },
  paper: {
    padding: ChipTheme.spacing(1),
  },
}))(Popover);

const StyledDataGrid = withStyles({
  root: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-viewport": {
      maxHeight: "none !important",
    },
  },
})(DataGrid);

const invitationColumns = (hook) => {
  return [
    {
      field: "client",
      headerName: "Klient",
      width: 170,
      type: "string",
      sortable: true,
      renderCell: (params) => {
        return <Typography>{params.row.client.name}</Typography>;
      },
    },
    {
      field: "windows",
      headerName: "Okna",
      width: 370,
      sortable: true,
      type: "string",
      renderCell: (params) => {
        let windowsDivs = [];
        params.row.windows?.forEach((windowData) => {
          windowsDivs.push(
            <div style={{ padding: 5, position: "relative", float: "left" }}>
              <StyledChip
                aria-owns={
                  hook.state.openPopover ? "mouse-over-popover" : undefined
                }
                aria-haspopup="true"
                onMouseEnter={(event) => {
                  hook.handlePopoverOpen(event, windowData);
                }}
                onMouseLeave={hook.handlePopoverClose}
                variant="outlined"
                size="small"
                label={`${moment(windowData.start).format(
                  "DD-MM HH:mm"
                )} - ${moment(windowData.end).format("HH:mm")}`}
                color="primary"
              />
            </div>
          );
        });
        return <div style={{ width: "100%" }}>{windowsDivs}</div>;
      },
    },
    {
      field: "expireAt",
      headerName: "Wygasa dnia",
      width: 170,
      type: "date",
      sortable: true,
      renderCell: (params) => {
        return (
          <Typography>
            {moment(params.row.expireAt)
              .add(14, "days")
              .format("DD-MM-YY HH:mm")}
          </Typography>
        );
      },
    },
  ];
};
export default class InvitationsGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invitations: [],
      rowsSelected: [],
      invitationsClick: { start: "placeholder", end: "placeholder" },
      anchorEl: null,
      openPopover: false,
      popoverWindow: { resourceId: 1 },
      updateCount: 0,
    };
  }
  componentDidMount() {
    getInvitations(this);
  }
  componentDidUpdate() {
    if (this.state.updateCount !== this.props.updateCount) {
      this.setState({
        updateCount: this.props.updateCount,
      });
      getInvitations(this);
    }
  }
  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    schedulerData.prev();
    schedulerData.next();
    this.setState({
      viewModel: schedulerData,
    });
    getInvitations(this);
  };

  eventClicked = (schedulerData, event) => {
    this.setState({
      invitationsClick: event,
    });
    this.setState({ editWindow: true });
  };
  handleRowSelection = (rows) => {
    this.setState({ rowsSelected: rows });
  };
  handlePopoverOpen = (event, window) => {
    this.setState({ popoverWindow: window });
    this.setState({ anchorEl: event.currentTarget });
    this.setState({ openPopover: true });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
    this.setState({ openPopover: false });
  };

  render() {
    return (
      <div>
        <Box height={"700px"}>
          <StyledDataGrid
            rowKey="id"
            rows={this.state.invitations}
            columns={invitationColumns(this)}
            onSelectionModelChange={(newSelectionModel) => {
              this.handleRowSelection(newSelectionModel.selectionModel);
            }}
            disableSelectionOnClick
            //pageSize={10}
            autoHeight={false}
            checkboxSelection
            onRowSelected={(e) => this.setState({ rowSelected: e.data })}
          />
        </Box>
        <br />
        <Button
          variant="outlined"
          onClick={() => {
            deleteInvitations(this, this.state.rowsSelected);
          }}
          color="primary"
        >
          Usuń
        </Button>
        <StyledPopover
          id="mouse-over-popover"
          open={this.state.openPopover}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>
            {moment(this.state.popoverWindow.start).format(
              "dddd DD-MM-YYYY HH:mm"
            )}{" "}
            - {moment(this.state.popoverWindow.end).format("HH:mm")}
          </Typography>
          <Typography>Typ: {this.state.popoverWindow.type}</Typography>
          <Typography>
            Lokalizacja:{" "}
            {this.props.resources[this.state.popoverWindow.resourceId].name}
          </Typography>
        </StyledPopover>
      </div>
    );
  }
}
