import React from "react";
import SignIn from "./components/SignIn";
import App from "./App";
import ReactDOM from "react-dom";
import isAuthenticated from "./components/middleware/isAuthenticated";

export default function AppHandler() {
  console.log("init");
  isAuthenticated((res) => {
    console.log("preauth");
    if (res) {
      ReactDOM.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>,
        document.getElementById("root")
      );
    } else {
      ReactDOM.render(
        <React.StrictMode>
          <SignIn />
        </React.StrictMode>,
        document.getElementById("root")
      );
    }
  });
  return null;
}
