import React from "react";
import ReactDOM from "react-dom";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import DocumentsAdd from "./documentAddModal";
import DocumentEdit from "./documentEditModal";
import MyDocument from "./documentToPDf";
import moment from "moment";
import { Axios } from ".././middleware/axiosInstance";
import { saveAs } from "file-saver";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import VisibilityIcon from "@material-ui/icons/Visibility";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];

function getMyData(hook, param) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/users/get`,
    params: { _id: param },
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ username: res.data.data[0] });
    } else {
      console.log(res.data.msg);
    }
  });
}
function checkUser(hook) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/user`,
  }).then((res) => {
    getMyData(hook, res.data);
  });
}
function getDocuments(hook) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/document/get/all`,
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ documents: res.data.data.documents });
    } else {
      console.log(res.data.msg);
    }
  });
}
function getPlaced(hook) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/ramp/get/all`,
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ placed: res.data.data });
    } else {
      console.log(res.data.msg);
    }
  });
}
function getCars(hook) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/car/get/all`,
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ cars: res.data.data });
    } else {
      console.log(res.data.msg);
    }
  });
}

function getEmployees(hook) {
  let param = ["Kierowca"];
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/users/get/all`,
    params: { userType: param },
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ employee: res.data.data });
    } else {
      console.log(res.data.msg);
    }
  });
}
function deleteDocuments(hook, data) {
  Axios({
    method: "DELETE",
    withCredentials: true,
    url: `${API_HOST}/document/delete`,
    params: { ids: data },
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ databaseState: true });
      getDocuments(hook);
    } else {
      hook.setState({ databaseState: false });
      console.log(res.data.msg);
    }
  });
}
function openEditDocumentWindow(hook, documents) {
  hook.setState({ editDocumentWindow: true, documentsEvent: documents });
}

const generatePDFDocument = async (documents, save) => {
  const blob = await pdf(
    <MyDocument documents={documents}> </MyDocument>
  ).toBlob();

  if (save) {
    saveAs(
      blob,
      documents.number.count +
        " / " +
        documents.number.year +
        " " +
        moment(documents.deliveryDate).format("DD.MM.YYYY") +
        ".pdf"
    );
  } else {
    const fileObjectURL = URL.createObjectURL(blob);
    let newWindow = window.open(fileObjectURL);
    setTimeout(function () {
      console.log(window.navigator.plugins);
    }, 10);
  }
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: 20,
  },
  marginRight: {
    marginRight: 20,
  },
}));

function documentsColumn(hook) {
  return [
    {
      field: "number",
      headerName: "Numer ",
      width: 100,
      type: "string",
      sortable: true,
      valueFormatter: (params) => {
        let numberDocument =
          params.row?.number?.count + " / " + params.row?.number?.year;
        return numberDocument;
      },
    },
    {
      field: "deliveryDate",
      headerName: "Data Dostawy",
      width: 150,
      type: "date",
      sortable: true,
      valueFormatter: (params) => {
        return moment(params.value).format("DD/MM/YYYY ");
      },
    },
    {
      field: "driver",
      headerName: "Kierowca ",
      width: 170,
      type: "string",
      sortable: true,
      valueFormatter: (params) => {
        let name =
          params.row?.driver?.firstName + " " + params.row?.driver?.surname;
        return name;
      },
    },
    {
      field: "employee",
      headerName: "Wystawca ",
      width: 170,
      type: "string",
      sortable: true,
      valueFormatter: (params) => {
        let name =
          params.row.employee?.firstName + " " + params.row.employee?.surname;
        return name;
      },
    },

    {
      field: "_id",
      headerName: " ",
      width: 350,
      renderCell: (params) => {
        return (
          <div>
            <Button
              color="primary"
              startIcon={<EditIcon />}
              style={{ marginLeft: 10, fontSize: 10 }}
              onClick={() => {
                openEditDocumentWindow(hook, params.row);
              }}
            ></Button>

            <Button
              color="primary"
              startIcon={<SaveIcon />}
              style={{ marginLeft: 10, fontSize: 10 }}
              onClick={() => {
                const save = true;
                generatePDFDocument(params.row, save);
              }}
            ></Button>

            <Button
              color="primary"
              startIcon={<VisibilityIcon />}
              style={{ marginLeft: 10, fontSize: 10 }}
              onClick={() => {
                const save = false;
                generatePDFDocument(params.row, save);
              }}
            ></Button>
          </div>
        );
      },
    },
  ];
}

export default class documentsGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      cars: [{}],
      rowSelected: [],
      documentsEvent: {},
      clickedRowIds: [],

      employee: [],
      username: {},
      placed: [],
    };
  }
  componentDidMount() {
    getDocuments(this);
    getCars(this);
    getEmployees(this);
    checkUser(this);
    getPlaced(this);
  }

  render() {
    const { viewModel, documentsEvent } = this.state;

    return (
      <div>
        <h2>Dokumenty Transportowe</h2>
        <DataGrid
          // rowKey="id"
          rows={this.state.documents}
          columns={documentsColumn(this)}
          onRowSelection={this.handleRowSelection}
          disableMultipleSelection={false}
          pageSize={10}
          autoHeight={true}
          checkboxSelection
          getRowId={(row) => row._id}
          onSelectionModelChange={(newSelection) => {
            this.setState({ rowSelected: newSelection.selectionModel });
          }}
          disableSelectionOnClick
        />
        <DocumentsAdd
          documents={documentsEvent}
          open={this.state.addDocumentWindow}
          onClose={this.closeAddDialog}
          updateData={this.updateData}
          cars={this.state.cars}
          employee={this.state.employee}
          loadUser={this.state.username}
          placed={this.state.placed}
        />
        <DocumentEdit
          documents={documentsEvent}
          open={this.state.editDocumentWindow}
          onClose={this.closeEditorDialog}
          updateData={this.updateData}
          cars={this.state.cars}
          employee={this.state.employee}
          loadUser={this.state.username}
          placed={this.state.placed}
        />
        <br />

        <Button
          variant="outlined"
          color="primary"
          style={{ marginRight: 10, float: "left" }}
          onClick={() => {
            this.setState({ addDocumentWindow: true });
          }}
        >
          Dodaj
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            deleteDocuments(this, this.state.rowSelected);
          }}
          color="primary"
        >
          Usuń
        </Button>
      </div>
    );
  }
  closeAddDialog = () => {
    this.setState({ addDocumentWindow: false });
  };
  closeEditorDialog = () => {
    this.setState({ editDocumentWindow: false });
  };
  closeCarDialog = () => {
    this.setState({ carsGridWindow: false });
  };
  updateData = () => {
    getDocuments(this);
  };
}
