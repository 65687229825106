import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import Typography from "@material-ui/core/Typography";
import MailIcon from "@material-ui/icons/Mail";
import DeleteIcon from "@material-ui/icons/Delete";
import Label from "@material-ui/icons/Label";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import InfoIcon from "@material-ui/icons/Info";
import ForumIcon from "@material-ui/icons/Forum";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import CategoryEditor from "./CategoryEditor";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Axios } from ".././middleware/axiosInstance";
import {
  useSeparateToggleClick,
  useLabelToggleClick,
  useIconToggleClick,
} from "useseparatetoggleclick";
import { Add, ArrowDropUp, LabelOff, Settings } from "@material-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Modal,
  Snackbar,
  SnackbarContent,
  TextField,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];
function saveCategories(
  newCategories,
  openModal,
  setSuccessMsg,
  setErrorMsg,
  onUpdate
) {
  Axios({
    method: "POST",
    withCredentials: true,
    url: `${API_HOST}/category/set`,
    data: { categories: newCategories },
  })
    .then((res) => {
      setSuccessMsg(res.data.msg);
      openModal(false);
      onUpdate();
    })
    .catch((err) => {
      setErrorMsg(err.msg);
    });
}
const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    "&:hover > $content": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${
        theme.palette.mode === "light"
          ? "rgb(255 255 255 / 70%)"
          : "rgb(255 255 255 / 10%)"
      })`,
      color: "var(--tree-view-color)",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
      {
        backgroundColor: "transparent",
      },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    ...other
  } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          {!props.pickMode && (
            <LabelIcon color="inherit" className={classes.labelIcon} />
          )}
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: {
    height: "auto",
    flexGrow: 1,
    maxWidth: 400,
  },
});

function filterTree(tree, nameFilter, cb) {
  let filtered = tree.children.filter((cat) => {
    let parentName = cat.label.toUpperCase().includes(nameFilter.toUpperCase());
    let childrenName = false;
    if (cat.children?.length > 0) {
      filterTree(cat, nameFilter, (filtered) => {
        if (filtered.length > 0) {
          childrenName = true;
        }
      });
    }
    return parentName || childrenName;
  });
  cb(filtered);
}

function generateItems(categories, depth, pickMode, cb) {
  let allNumOfProducts = 0;
  let items = [];
  if (categories.children.length > 0) {
    categories.children.forEach((child) => {
      if (child.children?.length > 0) {
        generateItems(
          { children: child.children },
          depth + 8,
          pickMode,
          (childItems) => {
            allNumOfProducts = child.numOfProducts;
            childItems.forEach((c) => {
              allNumOfProducts = allNumOfProducts + c.props.labelInfo;
            });
            items.push(
              <StyledTreeItem
                key={child.id}
                nodeId={child.id}
                labelText={`${child.label}`}
                labelInfo={allNumOfProducts}
                labelIcon={Label}
                pickMode={pickMode}
                style={{ marginLeft: depth }}
              >
                {childItems}
              </StyledTreeItem>
            );
          }
        );
      } else {
        items.push(
          <StyledTreeItem
            key={child.id}
            nodeId={child.id}
            labelText={`${child.label}`}
            labelInfo={child.numOfProducts}
            labelIcon={Label}
            pickMode={pickMode}
            style={{ marginLeft: depth }}
          />
        );
      }
    });
    cb(items);
  }
}

function findPathTo(data, id) {
  if (data.length > 0 && id !== 0 && id) {
    let dataMap = new Map(data.map((item) => [item.id, item]));
    let node = dataMap.get(id);
    let resArr = [];
    while (node.id !== 0) {
      resArr.push(node.id);
      if (node.parentCategoryId !== 0) {
        node = dataMap.get(node.parentCategoryId);
      } else {
        node = { id: 0 };
      }
    }
    resArr.push(0);
    return resArr.reverse();
  } else return [0];
}

export default function GmailTreeView(props) {
  const classes = useStyles();
  const [items, setItems] = React.useState([]);
  const [nameFilter, setNameFilter] = React.useState("");
  const [openEdit, setOpenEdit] = React.useState(false);
  const [successMsg, setSuccessMsg] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState(null);
  const [selected, setSelected] = React.useState([]);
  const [defaultSelected, setDefaultSelected] = React.useState([]);
  const [defaultExpanded, setDefaultExpanded] = React.useState([]);
  React.useEffect(() => {
    if (props.data.categoriesTree.length > 0) {
      if (props.searchBar) {
        filterTree(
          { children: props.data.categoriesTree },
          nameFilter,
          (filtered) => {
            generateItems(
              { children: filtered },
              8,
              props.pickMode,
              (items) => {
                console.log(items);
                setItems(items);
              }
            );
          }
        );
      } else {
        generateItems(
          { children: props.data.categoriesTree },
          8,
          props.pickMode,
          (items) => {
            console.log(items);
            setItems(items);
          }
        );
      }
    }
  }, [props, nameFilter]);
  React.useEffect(() => {
    setDefaultSelected(props.selected);
    setDefaultExpanded(["0"]);
  }, [props]);
  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
    props.onSelect(nodeIds);
  };
  const [iconCollapses, setIconCollapses] = React.useState(true);
  const [iconExpands, setIconExpands] = React.useState(true);
  const [updatedCategories, setUpdatedCategories] = React.useState(true);
  const handleIconExpands = (event) => {
    setIconExpands(event.target.checked);
  };
  const handleIconCollapses = (event) => {
    setIconCollapses(event.target.checked);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  const handleCategoriesUpdate = (categories) => {
    setUpdatedCategories(categories);
  };
  const handleSave = () => {
    saveCategories(
      updatedCategories,
      setOpenEdit,
      setSuccessMsg,
      setErrorMsg,
      props.onEdit
    );
  };
  const shouldToggleClick = React.useCallback(
    (iconClicked, isExpanding) => {
      let iconToggles = isExpanding ? iconExpands : iconCollapses;
      if ((iconToggles && iconClicked) || (!iconToggles && !iconClicked)) {
        return true;
      }
      return false;
    },
    [iconCollapses, iconExpands]
  );
  let { expanded, IconWrapper, onNodeToggle } = useSeparateToggleClick(
    shouldToggleClick,
    props.pickMode
      ? findPathTo(props.data.flatCategories, props.selected)
      : ["0"]
  );
  //expanded = [0];
  // if (props.pickMode) {
  //   expanded = findPathTo(props.data.flatCategories, props.selected);
  // }

  return (
    <div>
      {props.searchBar && (
        <TextField
          label="Wyszukaj kategorię"
          onChange={(event) => {
            setNameFilter(event.target.value);
          }}
          variant="outlined"
          style={{ width: "100%" }}
          size="small"
        />
      )}

      <TreeView
        style={{ width: props.width }}
        className={classes.root}
        onNodeToggle={onNodeToggle}
        expanded={expanded}
        selected={props.selected}
        //defaultExpanded={["0"]}
        defaultCollapseIcon={
          <IconWrapper>
            <ArrowDropDownIcon />
          </IconWrapper>
        }
        defaultExpandIcon={
          <IconWrapper>
            <ArrowRightIcon />
          </IconWrapper>
        }
        defaultEndIcon={<div style={{ width: 24 }} />}
        onNodeSelect={handleSelect}
      >
        {!props.pickMode && (
          <StyledTreeItem
            nodeId="0"
            labelText="Wszystkie kategorie"
            labelIcon={Label}
          >
            <div style={{ maxHeight: props.maxHeight, overflowY: "auto" }}>
              {items}
            </div>
          </StyledTreeItem>
        )}
        {props.pickMode && (
          // <StyledTreeItem
          //   nodeId="0"
          //   onIconClick={(event) => {}}
          //   onLabelClick={(event) => {
          //     event.preventDefault();
          //   }}
          //   labelText={
          //     <Typography
          //       variant="body1"
          //       style={{ color: "rgba(0, 0, 0, 0.87)" }}
          //     >
          //       Wybierz kategorię...
          //     </Typography>
          //   }
          //   labelIcon={Box}
          //   // expandIcon={ArrowDropDownIcon}
          //   // collapseIcon={ArrowDropUp}
          // >

          // </StyledTreeItem>
          <div
            style={{
              maxHeight: props.maxHeight,
              overflowY: "auto",
              //backgroundColor: "#fbfbfb",
              border: "1px solid rgba(224, 224, 224, 0.25)",
              borderRadius: 3,
            }}
          >
            {items}
          </div>
        )}

        {props.editMode && (
          <div>
            <StyledTreeItem
              labelText="Produkty nieprzydzielone"
              labelIcon={LabelOff}
              onLabelClick={() => {
                props.onSelect(null);
              }}
            ></StyledTreeItem>
            <StyledTreeItem
              labelText="Edytuj kategorie"
              labelIcon={Settings}
              onLabelClick={() => {
                setOpenEdit(true);
              }}
            ></StyledTreeItem>
          </div>
        )}
      </TreeView>
      <Dialog
        onClose={handleCloseEdit}
        aria-labelledby="customized-dialog-title"
        open={openEdit}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleCloseEdit}>
          Edycja kategorii
        </DialogTitle>
        <DialogContent dividers>
          <CategoryEditor
            flatCategories={props.data.flatCategories}
            onUpdate={(newCategories) => {
              console.log(newCategories);
              handleCategoriesUpdate(newCategories);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSave} color="primary">
            Zapisz zmiany
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={Boolean(successMsg)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        onClose={() => {
          setSuccessMsg(false);
        }}
      >
        <Alert
          onClose={() => {
            setSuccessMsg(false);
          }}
          severity="success"
          variant="filled"
        >
          {successMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={Boolean(errorMsg)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        onClose={() => {
          setErrorMsg(false);
        }}
      >
        <Alert
          onClose={() => {
            setErrorMsg(false);
          }}
          severity="error"
          variant="filled"
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
