import React from "react";
import clsx from "clsx";
import EditIcon from "@material-ui/icons/Edit";
import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { withStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { Axios } from ".././middleware/axiosInstance";
import CarsAdd from "./carsAddModal";
import CarsEdit from "./carsEditModal";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];

function getCars(hook) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/car/get/all`,
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ cars: res.data.data });
    } else {
      console.log(res.data.msg);
    }
  });
}
function deleteCars(hook, data) {
  Axios({
    method: "DELETE",
    withCredentials: true,
    url: `${API_HOST}/car/delete`,
    params: { ids: data },
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ databaseState: true });
      getCars(hook);
    } else {
      hook.setState({ databaseState: false });
      console.log(res.data.msg);
    }
  });
}
function openEditCarWindow(hook, cars) {
  hook.setState({ editCarWindow: true, carsEvent: cars });
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: 20,
  },
  marginRight: {
    marginRight: 20,
  },
}));

function carsColumn(hook) {
  return [
    {
      field: "carBrand",
      headerName: "Marka ",
      width: 175,
      type: "string",
      sortable: true,
    },
    {
      field: "registrationNumber",
      headerName: "Numer Rejestracyjny",
      width: 175,
      type: "string",
      sortable: true,
    },
    {
      field: "kilometers",
      headerName: "Przebieg",
      width: 170,
      type: "String",
      sortable: true,
    },
    {
      field: "fuel",
      headerName: "Paliwo",
      width: 170,
      type: "Number",
      sortable: true,
    },
    {
      field: "dmc",
      headerName: "Ładowność(kg)",
      width: 170,
      type: "String",
      sortable: true,
    },
    {
      field: "loadPallets",
      headerName: "Ilość Palet",
      width: 250,
      type: "String",
      sortable: true,
      valueFormatter: (params) => {
        let LoadPallets1 =
          "Euro:   " +
          params.row?.loadPallets?.euro +
          " Przemysłowe:  " +
          params.row?.loadPallets?.neuro;
        return LoadPallets1;
      },
    },

    {
      field: "_id",
      headerName: " ",
      width: 180,
      renderCell: (params) => {
        return (
          <div>
            <Button
              color="primary"
              startIcon={<EditIcon />}
              style={{ marginLeft: 10, fontSize: 10 }}
              onClick={() => {
                openEditCarWindow(hook, params.row);
              }}
            ></Button>
          </div>
        );
      },
    },
  ];
}

export default class carsGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cars: [
        {
          _id: "60b76f5f38215064b87d1a82",
          carBrand: "DAF",
          registrationNumber: "WSI33WFA",
          kilometers: "3331123321",
          fuel: "300",
          dmc: "12000",
        },
      ],
      rowSelected: [],
      carsEvent: {},
      clickedRowIds: [],
    };
  }
  componentDidMount() {
    getCars(this);
  }

  render() {
    const { viewModel, carsEvent } = this.state;
    return (
      <div>
        <h2>Pojazdy</h2>
        <DataGrid
          // rowKey="id"
          rows={this.state.cars}
          columns={carsColumn(this)}
          onRowSelection={this.handleRowSelection}
          disableMultipleSelection={false}
          pageSize={10}
          disableSelectionOnClick
          autoHeight={true}
          checkboxSelection
          getRowId={(row) => row._id}
          onSelectionModelChange={(newSelection) => {
            this.setState({ rowSelected: newSelection.selectionModel });
          }}
        />
        <br />
        <CarsAdd
          cars={carsEvent}
          open={this.state.addCarWindow}
          onClose={this.closeAddDialog}
          updateData={this.updateData}
        />
        <CarsEdit
          cars={carsEvent}
          open={this.state.editCarWindow}
          onClose={this.closeEditorDialog}
          updateData={this.updateData}
        />

        <Button
          variant="outlined"
          color="primary"
          style={{ marginRight: 10, float: "left" }}
          onClick={() => {
            this.setState({ addCarWindow: true });
          }}
        >
          Dodaj
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            deleteCars(this, this.state.rowSelected);
          }}
          color="primary"
        >
          Usuń
        </Button>
      </div>
    );
  }
  closeAddDialog = () => {
    this.setState({ addCarWindow: false });
  };
  closeEditorDialog = () => {
    this.setState({ editCarWindow: false });
  };

  updateData = () => {
    getCars(this);
  };
}
