import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import AppBar from "@material-ui/core/AppBar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { countries } from "./locales.json";
import validator from "validator";
import { Axios } from "./middleware/axiosInstance";
import * as EmailValidator from "email-validator";
import { useParams, useHistory } from "react-router-dom";
import Error404 from "./Error404";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../config.json")["API_HOST"][env];

function checkToken(token, setClientId, setExpiredToken, setWrongToken) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/token/get`,
    params: token,
  }).then((res) => {
    console.log(res.data);
    if (!res.data.err) {
      if (res.data.data.isActive) {
        setClientId(res.data.data.clientId);
      } else {
        setExpiredToken(true);
      }
    } else {
      setWrongToken(true);

      console.log(res.data.msg);
    }
  });
}

function saveData(id, password, history) {
  let client = {};
  client._id = id;
  client.password = password;
  console.log(client);

  Axios({
    method: "PUT",
    withCredentials: true,
    url: `${API_HOST}/client/changepassword`,
    params: client,
  }).then((res) => {
    if (!res.data.err) {
      console.log(res.data.data);
      let path = `/client`;
      history.push(path);
    } else {
      console.log(res.data.msg);
    }
  });
}
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}
const autocompleteStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      // Default transform is "translate(14px, 20px) scale(1)""
      // This lines up the label with the initial cursor position in the input
      // after changing its padding-left.
      transform: "translate(14px, 20px) scale(1);",
    },
  },
  inputRoot: {
    color: "black",
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 6,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
  },
  clearIndicator: {
    color: "black",
  },
  popupIndicator: {
    color: "black",
  },
}));
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ChangePassword() {
  const classes = useStyles();
  const autocompClass = autocompleteStyles();
  const [token] = React.useState(useParams());

  const [passwordCorrect, setPasswordCorrect] = React.useState(false);
  const [clientId, setClientId] = React.useState();
  const [password, setPassword] = React.useState();
  const [repeatpasswordCorrect, setRepeatPasswordCorrect] =
    React.useState(false);
  const [lang] = React.useState(countries);
  const [errorMessage, setErrorMessage] = React.useState();
  const [choosenLang, setChoosenLang] = React.useState(lang[1]);
  const [expiredToken, setExpiredToken] = React.useState(false);
  const [wrongToken, setWrongToken] = React.useState(false);
  const history = useHistory();
  const handleLangChange = (event, value) => {
    setChoosenLang(value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    validate(event.target.value);
  };

  const validate = (value) => {
    if (value.length == " ") {
      setErrorMessage(" ");
      setPasswordCorrect(true);
    } else if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setErrorMessage("");
      setPasswordCorrect(true);
    } else {
      setErrorMessage(choosenLang.translation.err_password);
      setPasswordCorrect(false);
    }
  };

  const handleRepeatPasswordChange = (event) => {
    let passwordRepeat = event.target.value;
    if (password === passwordRepeat) {
      setErrorMessage("");
      setRepeatPasswordCorrect(true);
    } else {
      setErrorMessage(choosenLang.translation.repeated_password_error);
      setRepeatPasswordCorrect(false);
    }
  };
  React.useEffect(() => {
    checkToken(token, setClientId, setExpiredToken, setWrongToken);
  }, [token]);

  return (
    <div>
      {expiredToken && <h1>Expired token</h1>}
      {wrongToken && <h1>Wrong token</h1>}
      {!(expiredToken || wrongToken) && (
        <div>
          <Toolbar>
            {/* <Typography variant="h6" className={classes.width100FL}>
      {choosenLang.translation.advice} - Bialpak
    </Typography> */}
            <Autocomplete
              id="country-select-demo"
              style={{
                width: 300,
                color: "black",
                position: "absolute",
                right: 20,
              }}
              options={lang}
              classes={autocompClass}
              onChange={handleLangChange}
              autoHighlight
              size="small"
              value={choosenLang}
              getOptionLabel={(option) => option.label}
              renderOption={(option) => (
                <React.Fragment>
                  <span>{countryToFlag(option.code)}&nbsp;</span>
                  {option.label}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${choosenLang.translation.choose_language}`}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </Toolbar>

          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                {choosenLang.translation.recover_password}
              </Typography>
              <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      name="password"
                      label={choosenLang.translation.password}
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      onChange={handlePasswordChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      name="repeatpassword"
                      label={choosenLang.translation.repeated_password}
                      type="password"
                      id="repeatedPassword"
                      onChange={handleRepeatPasswordChange}
                    />
                  </Grid>
                  <span
                    style={{
                      margin: 10,
                      fontWeight: "bold",
                      color: "red",
                    }}
                  >
                    {errorMessage}
                  </span>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  href="/client"
                  onClick={() => {
                    saveData(clientId, password, history);
                  }}
                  disabled={!passwordCorrect || !repeatpasswordCorrect}
                >
                  {choosenLang.translation.sign_up}
                </Button>
              </form>
            </div>
            <Box mt={5}>
              <Copyright />
            </Box>
          </Container>
        </div>
      )}
    </div>
  );
}
