import React, { Component } from "react";
import { DropzoneDialog } from "material-ui-dropzone";
import Button from "@material-ui/core/Button";
import { Axios } from ".././middleware/axiosInstance";
import { CloudUpload } from "@material-ui/icons";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];
function uploadImages(hook, id, files) {
  const data = new FormData();
  if (files) {
    data.append("productId", id);
    files.forEach((file) => {
      data.append("photos", files[0]);
    });
    //data.append("productId", id);
  }
  Axios({
    method: "POST",
    withCredentials: true,
    url: `${API_HOST}/image/upload`,
    data: data,
    //body: { product_id: id },
  }).then((res) => {
    if (!res.data.err) {
      let urls = [];
      res.data.files.forEach((file) => {
        urls.push(file.filename);
      });
      hook.props.onUpload(urls);
    } else {
      console.log(res.data.msg);
    }
  });

  // Axios.post(`${API_HOST}/image/upload`, data, {
  //   withCredentials: true,
  // }).then((res) => {
  //   if (!res.data.err) {
  //     console.log(res.data);
  //   } else {
  //     console.log(res.data.msg);
  //   }
  // });
}
export default class ImageDropzoneDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      files: [],
    };
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  handleSave(files) {
    uploadImages(this, this.props.productId, files);
    this.setState({
      files: files,
      open: false,
    });
  }

  handleOpen() {
    this.setState({
      open: true,
    });
  }

  render() {
    return (
      <div>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<CloudUpload />}
          onClick={this.handleOpen.bind(this)}
        >
          Dodaj zdjęcia
        </Button>
        <DropzoneDialog
          onSave={this.handleSave.bind(this)}
          open={this.state.open}
          dropzoneText="Przesuń pliki tutaj bądź kliknij i wybierz"
          acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
          showPreviews={true}
          showPreviewsInDropzone={false}
          previewText="Wybrane pliki"
          maxFileSize={5000000}
          onClose={this.handleClose.bind(this)}
          cancelButtonText={"Anuluj"}
          submitButtonText={"Zapisz"}
        />
      </div>
    );
  }
}
