import React from "react";
import clsx from "clsx";
import Alert from "@material-ui/lab/Alert";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as EmailValidator from "email-validator";
import Checkbox from "@material-ui/core/Checkbox";
import { countries } from "../locales.json";
import moment from "moment";
import validator from "validator";

import Axios from "axios";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];

function saveData(
  carBrand,
  registrationNumber,
  kilometers,
  fuel,
  dmc,
  loadPalletsEuro,
  loadPalletsNoEuro,
  close,
  updateData
) {
  let car = {};
  car.carBrand = carBrand;
  car.registrationNumber = registrationNumber;
  car.kilometers = kilometers;
  car.fuel = fuel;
  car.dmc = dmc;
  car.euro = loadPalletsEuro;
  car.neuro = loadPalletsNoEuro;

  Axios({
    method: "POST",
    withCredentials: true,
    url: `${API_HOST}/car/add`,
    data: car,
  })
    .then((res) => {
      updateData();
      close();
    })
    .catch((error) => {
      console.log(error.response.data.msg);
    });
}
function notEmpty(
  carBrand,
  registrationNumber,
  kilometers,
  fuel,
  dmc,
  loadPalletsEuro,
  loadPalletsNoEuro,
  close,
  updateData,
  setErrorMessage,
  setError
) {
  if (
    !carBrand ||
    !registrationNumber ||
    !kilometers ||
    !fuel ||
    !dmc ||
    !loadPalletsEuro ||
    !loadPalletsNoEuro
  ) {
    setError(true);
    setErrorMessage("Wszystkie pola muszą być wypełnione!");
  } else {
    setError(false);
    setErrorMessage(null);
    saveData(
      carBrand,
      registrationNumber,
      kilometers,
      fuel,
      dmc,
      loadPalletsEuro,
      loadPalletsNoEuro,
      close,
      updateData
    );
  }
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: 20,
  },
  marginRight: {
    marginRight: 20,
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({
  car,
  open,
  onClose = (f) => f,
  updateData = (f) => f,
}) {
  const [state, setState] = React.useState({ checkedB: true });
  const [carBrand, setCarBrand] = React.useState();
  const [registrationNumber, setRegistrationNumber] = React.useState();
  const [kilometers, setKilometers] = React.useState();
  const [fuel, setFuel] = React.useState();
  const [dmc, setDMC] = React.useState();
  const [loadPalletsEuro, setLoadPalletsEuro] = React.useState();
  const [loadPalletsNoEuro, setLoadPalletsNoEuro] = React.useState();
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleCarBrandChange = (event) => {
    setCarBrand(event.target.value);
  };
  const handleRegistrationNumberChange = (event) => {
    setRegistrationNumber(event.target.value);
  };
  const handleKilometersChange = (event) => {
    let kilo = event.target.value;
    if (kilo <= 0) {
      setError(true);
      setErrorMessage(
        "Liczba kilometrów nie może być mniejsza, bądź równa 0 !"
      );
    } else {
      setError(false);
      setErrorMessage(null);
      setKilometers(kilo);
    }
  };
  const handleFuelChange = (event) => {
    let diesel = event.target.value;
    if (diesel <= 0) {
      setError(true);
      setErrorMessage("Ilość paliwa nie może być mniejsza, bądź równa 0 !");
    } else {
      setError(false);
      setErrorMessage(null);
      setFuel(diesel);
    }
  };
  const handleDmcChange = (event) => {
    let capacity = event.target.value;
    if (capacity <= 0) {
      setError(true);
      setErrorMessage("Ładowność nie może być mniejsza, bądź równa 0 !");
    } else {
      setError(false);
      setErrorMessage(null);
      setDMC(capacity);
    }
  };
  const handleLoadEuroPalletsChange = (event) => {
    let pallet = event.target.value;
    if (pallet <= 0) {
      setError(true);
      setErrorMessage("Liczba palet nie może być mniejsza, bądź równa 0 !");
    } else {
      setError(false);
      setErrorMessage(null);
      setLoadPalletsEuro(pallet);
    }
  };
  const handleLoadNoEuroPalletsChange = (event) => {
    let pallet = event.target.value;
    if (pallet <= 0) {
      setError(true);
      setErrorMessage("Liczba palet nie może być mniejsza, bądź równa 0 !");
    } else {
      setError(false);
      setErrorMessage(null);
      setLoadPalletsNoEuro(pallet);
    }
  };

  React.useEffect(() => {
    setCarBrand(null);
    setRegistrationNumber(null);
    setKilometers(null);
    setFuel(null);
    setDMC(null);
    setLoadPalletsNoEuro(null);
    setLoadPalletsEuro(null);
    setError(false);
    setErrorMessage("");
  }, [open]);
  const classes = useStyles();
  return (
    <div>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          Dodaj Nowy Pojazd
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            required
            id="carBrand"
            label="Marka Pojazdu:"
            className={classes.marginBottom}
            fullWidth={true}
            onChange={handleCarBrandChange}
          />
          <TextField
            required
            id="registrationNumber"
            label="Numer Rejestracyjny Pojazdu:"
            className={classes.marginBottom}
            onChange={handleRegistrationNumberChange}
            fullWidth={true}
          />
          <TextField
            required
            id="kilometers"
            label="Aktualny Przebieg: "
            type="number"
            fullWidth={true}
            className={classes.marginBottom}
            onChange={handleKilometersChange}
          />
          <TextField
            required
            id="fuel"
            label="Ilość Paliwa: "
            type="number"
            fullWidth={true}
            className={classes.marginBottom}
            onChange={handleFuelChange}
          />
          <TextField
            required
            fullWidth
            label="Dopuszczalna całkowita ładowność:"
            type="Number"
            id="dmc"
            fullWidth={true}
            className={classes.marginBottom}
            onChange={handleDmcChange}
          />
          <TextField
            required
            name="loadPalletsEuro"
            label="Ilość palet euro:"
            type="number"
            id="loadPallets"
            fullWidth={true}
            className={classes.marginBottom}
            onChange={handleLoadEuroPalletsChange}
          />
          <TextField
            required
            name="loadPalletsNoEuro"
            label="Ilość palet przemysłowych:"
            type="number"
            id="loadPalletsNoEuro"
            fullWidth={true}
            className={classes.marginBottom}
            onChange={handleLoadNoEuroPalletsChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            autoFocus
            color="primary"
            onClick={() => {
              notEmpty(
                carBrand,
                registrationNumber,
                kilometers,
                fuel,
                dmc,
                loadPalletsEuro,
                loadPalletsNoEuro,
                onClose,
                updateData,
                setErrorMessage,
                setError
              );
            }}
          >
            Zapisz
          </Button>
        </DialogActions>
        {error && <Alert severity="error">{errorMessage}</Alert>}
      </Dialog>
    </div>
  );
}
