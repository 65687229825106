import React, { useState } from "react";
import ReactDOM from "react-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Toolbar from "@material-ui/core/Toolbar";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Axios } from "./middleware/axiosInstance";
import App from "../App";
import * as EmailValidator from "email-validator";
import Alert from "@material-ui/lab/Alert";
import isAuthenticated from "./middleware/isAuthenticated";
import { countries } from "./locales.json";
import { Paper } from "@material-ui/core";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../config.json")["API_HOST"][env];

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://bialpak.pl/">
        BPdev
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
function sendEmail(email, setErrorMessage, setSuccessMessage) {
  let client = {};
  client.email = email;
  console.log(client);

  Axios({
    method: "POST",
    withCredentials: true,
    url: `${API_HOST}/client/forgottenPassword`,
    data: client,
  }).then((res) => {
    if (!res.data.err) {
      setSuccessMessage(true);
      setErrorMessage(false);
    } else {
      setSuccessMessage(false);
      setErrorMessage(true);
      console.log(res.data);
    }
  });
}
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}
const autocompleteStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      // Default transform is "translate(14px, 20px) scale(1)""
      // This lines up the label with the initial cursor position in the input
      // after changing its padding-left.
      transform: "translate(14px, 20px) scale(1);",
    },
  },
  inputRoot: {
    color: "black",
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 6,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
  },
  clearIndicator: {
    color: "black",
  },
  popupIndicator: {
    color: "black",
  },
}));

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ForgottenPassword() {
  const classes = useStyles();
  const autocompClass = autocompleteStyles();
  const [email, setEmail] = React.useState();
  const [emailCorrect, setEmailCorrect] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState(false);

  const [lang] = React.useState(countries);
  const [choosenLang, setChoosenLang] = React.useState(lang[1]);
  const handleLangChange = (event, value) => {
    setChoosenLang(value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    let email = event.target.value;
    if (email == "") {
      setErrorMessage("");
      setEmailCorrect(false);
    } else if (EmailValidator.validate(email)) {
      setErrorMessage("");
      setEmailCorrect(true);
    } else {
      setEmailCorrect(false);
      setErrorMessage(choosenLang.translation.err_email);
    }
  };

  return (
    <div>
      <Toolbar>
        <Autocomplete
          id="country-select-demo"
          style={{
            width: 300,
            color: "black",
            position: "absolute",
            right: 20,
          }}
          options={lang}
          classes={autocompClass}
          onChange={handleLangChange}
          autoHighlight
          size="small"
          value={choosenLang}
          getOptionLabel={(option) => option.label}
          renderOption={(option) => (
            <React.Fragment>
              <span>{countryToFlag(option.code)}&nbsp;</span>
              {option.label}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`${choosenLang.translation.choose_language}`}
              variant="outlined"
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
      </Toolbar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={`${choosenLang.translation.email_address}`}
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleEmailChange}
            />

            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                sendEmail(email, setErrorMessage, setSuccessMessage);
              }}
              disabled={!emailCorrect}
            >
              {choosenLang.translation.recover_password}
            </Button>
          </form>
          {successMessage && (
            <Alert severity="success">
              ${choosenLang.translation.succes_forgotten}
            </Alert>
          )}
          {errorMessage && (
            <Alert severity="warning">
              ${choosenLang.translation.error_forgotten}
            </Alert>
          )}
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
}
