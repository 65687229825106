import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Axios } from ".././middleware/axiosInstance";
import Alert from "@material-ui/lab/Alert";
import validator from "validator";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];

function saveData(id, name, email, phone, close, updateData) {
  let client = {};
  client._id = id;
  client.name = name;
  client.email = email;
  client.phone = phone;
  // client.password = password;
  console.log(client);
  Axios({
    method: "PUT",
    withCredentials: true,
    url: `${API_HOST}/client/update`,
    params: client,
  }).then((res) => {
    console.log(res.data);
    updateData();
    close();
  });
}
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
function notEmpty(
  id,
  name,
  email,
  phone,
  close,
  updateData,
  setErrorMessage,
  setError
) {
  if (!name || !email || !phone) {
    setError(true);
    setErrorMessage("Wszystkie pola muszą być wypełnione!");
  } else {
    setError(false);
    setErrorMessage(null);
    saveData(id, name, email, phone, close, updateData);
  }
}

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: 20,
  },
  marginRight: {
    marginRight: 20,
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({
  client,
  open,
  onClose = (f) => f,
  updateData = (f) => f,
}) {
  console.log(client);

  const [name, setClientName] = React.useState();
  const [email, setClientEmail] = React.useState();
  const [phone, setClientPhone] = React.useState();
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const handleNameChange = (event) => {
    setClientName(event.target.value);
  };
  const handleEmailChange = (event) => {
    let emailValue = event.target.value;
    validateEmail(emailValue);
    setClientEmail(emailValue);
  };
  const validateEmail = (value) => {
    var email = value;
    if (validator.isEmail(email)) {
      setError(false);
    } else {
      setError(true);
      setErrorMessage("Nie poprawny adres email!");
    }
  };
  const handlePhoneChange = (event) => {
    setClientPhone(event.target.value);
  };
  React.useEffect(() => {
    setClientName(client.name);
    setClientEmail(client.email);
    setClientPhone(client.phone);
    setErrorMessage(null);
    setError(false);
  }, [client]);
  const classes = useStyles();
  return (
    <div>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          Edytuj Klienta:
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            id="name"
            label="Nazwa"
            fullWidth={true}
            className={classes.marginBottom}
            onChange={handleNameChange}
            defaultValue={name}
          />
          <TextField
            id="email"
            label="Adres Email"
            type="text"
            fullWidth={true}
            className={classes.marginBottom}
            defaultValue={email}
            onChange={handleEmailChange}
          />

          <TextField
            id="phone"
            label="Numer Telefonu"
            type="number"
            fullWidth={true}
            defaultValue={phone}
            onChange={handlePhoneChange}
            className={classes.marginBottom}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            autoFocus
            onClick={() => {
              notEmpty(
                client._id,
                name,
                email,
                phone,
                onClose,
                updateData,
                setErrorMessage,
                setError
              );
            }}
          >
            Zapisz
          </Button>
        </DialogActions>
        {error && <Alert severity="error">{errorMessage}</Alert>}
      </Dialog>
    </div>
  );
}
