import React from "react";
import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import CheckIcon from "@material-ui/icons/Check";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ClientsAdd from "./ClientsAddModal";
import ClientsEdit from "./ClientsEditModal";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import {
  makeStyles,
  withStyles,
  createMuiTheme,
} from "@material-ui/core/styles";
import { Axios } from ".././middleware/axiosInstance";
import Chip from "@material-ui/core/Chip";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];

function getClients(hook) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/client/get/all`,
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ clients: res.data.data });
    } else {
      console.log(res.data.msg);
    }
  });
}
function deleteClient(hook, data) {
  Axios({
    method: "DELETE",
    withCredentials: true,
    url: `${API_HOST}/client/delete`,
    params: { ids: data },
  }).then((res) => {
    if (!res.data.err) {
      // alert('Wysłano')
      hook.setState({ databaseState: true });
      getClients(hook);
    } else {
      // alert('Nie Wysłano');
      hook.setState({ databaseState: false });
      console.log(res.data.msg);
    }
  });
}

function openEditClientWindow(hook, client) {
  hook.setState({ editClient: true, clientsEvent: client });
}
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  outlinedPrimary: {},
});

const StyledChip = withStyles((ChipTheme) => ({
  root: {
    padding: 5,
    width: 140,
    float: "left",
  },
}))(Chip);

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: 20,
  },
  marginRight: {
    marginRight: 20,
  },
}));

function clientColumns(hook) {
  return [
    // { field: 'id', headerName: 'ID', width: 70, type: 'number', sortable: true },
    {
      field: "name",
      headerName: "Nazwa",
      width: 100,
      type: "string",
      sortable: true,
    },
    {
      field: "email",
      headerName: "Adres Email",
      width: 200,
      type: "string",
      sortable: true,
    },
    {
      field: "phone",
      headerName: "Numer Telefonu",
      width: 150,
      type: "number",
      sortable: true,
    },
    {
      field: "isVerified",
      headerName: "Potwierdzone Konto",
      width: 200,
      sortable: true,
      renderCell: (params) => {
        let icon;

        if (params.row.isVerified === true) {
          icon = (
            <StyledChip
              variant="outlined"
              size="small"
              icon={<DoneAllIcon />}
              label="Potwierdzono"
              color="primary"
              value="Potwierdzono"
            />
          );
        } else if (params.row.token != null) {
          icon = (
            <StyledChip
              variant="outlined"
              size="small"
              icon={<CheckIcon />}
              label="Zaproszono"
              color="primary"
              value="Zaproszono"
            />
          );
        } else {
          icon = (
            <StyledChip
              variant="outlined"
              size="small"
              icon={<CloseIcon />}
              label="Nie zaproszono"
              color="primary"
            />
          );
        }
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {icon}
          </div>
        );
      },
    },
    {
      field: "id",
      headerName: " ",
      width: 100,
      renderCell: (params) => {
        return (
          <Button
            color="primary"
            startIcon={<EditIcon />}
            style={{ marginLeft: 10, fontSize: 10 }}
            onClick={() => {
              openEditClientWindow(hook, params.row);
            }}
          ></Button>
        );
      },
    },
  ];
}

export default class ClientsGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [
        {
          id: 0,
          name: "asda",
          email: "ss@wp.pl",
          phone: 7289123111,
          isVerified: true,
          password: "true",
        },
      ],
      rowSelected: [
        {
          id: 0,
          name: "asda",
          email: "ss@wp.pl",
          phone: 7289123111,
          isVerified: true,
          password: "true",
        },
      ],
      clientsEvent: {},
      senderClient: false,
      clickedRowIds: [],
    };
  }
  componentDidMount() {
    getClients(this);
  }

  render() {
    const { viewModel, clientsEvent } = this.state;

    return (
      <div>
        <h2>Dostawcy</h2>
        <DataGrid
          autoPageSize
          pagination
          rowKey="id"
          rows={this.state.clients}
          columns={clientColumns(this)}
          onRowSelection={this.handleRowSelection}
          disableMultipleSelection={false}
          disableSelectionOnClick
          pageSize={10}
          autoHeight={true}
          checkboxSelection
          // onRowSelected={(e) => this.setState({rowSelected: e.data})}
          onSelectionModelChange={(newSelection) => {
            this.setState({ rowSelected: newSelection.selectionModel });
          }}
        />
        <ClientsAdd
          client={clientsEvent}
          open={this.state.addClientWindow}
          onClose={this.closeAddDialog}
          updateData={this.updateData}
        />
        <ClientsEdit
          client={clientsEvent}
          open={this.state.editClient}
          onClose={this.closeEditorDialog}
          updateData={this.updateData}
        />
        <br />
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            this.setState({ addClientWindow: true });
          }}
        >
          {" "}
          Dodaj{" "}
        </Button>{" "}
        <Button
          variant="outlined"
          onClick={() => {
            deleteClient(this, this.state.rowSelected);
          }}
          color="primary"
        >
          Usuń
        </Button>
        {/* <Button variant="contained" color="primary" onClick={()=>{this.setState({editClient: true})}}> Edytuj </Button>  */}
      </div>
    );
  }
  closeAddDialog = () => {
    this.setState({ addClientWindow: false });
  };
  closeEditorDialog = () => {
    this.setState({ editClient: false });
  };
  updateData = () => {
    getClients(this);
  };
}
