import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Switch,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  Check,
  ChildCare,
  Close,
  DoneAll,
  Info,
  InfoOutlined,
  Send,
  SyncAlt,
  Warning,
} from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/styles";
import React from "react";
import { Axios } from ".././middleware/axiosInstance";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];
function getCecha(hook) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/customer/get/all/cecha`,
  }).then((res) => {
    if (!res.data.err) {
      let cechaSort = res.data.data.sort((a, b) => (a.name > b.name ? 1 : -1));
      hook.setState({ cecha: cechaSort });
    } else {
      console.log(res.data.msg);
    }
  });
}
function getOperators(hook) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/users/get/all`,
    params: { userType: "Handlowiec" },
  }).then((res) => {
    if (!res.data.err) {
      let operatorsSort = res.data.data.employee.sort((a, b) =>
        a.surname > b.surname ? 1 : -1
      );
      if (!hook.state.edit) {
        hook.setState({ customerOperator: res.data.data.current });
        hook.setState({ customerOperatorId: res.data.data.current._id });
      }
      hook.setState({ operators: operatorsSort });
    } else {
      console.log(res.data.msg);
    }
  });
}

function getParents(hook) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/customer/get/all`
  }).then((res) => {
    if (!res.data.err) {
      let parentsSort = res.data.data.sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
      let currentParent = parentsSort.filter((parent)=>parent.id === hook.state.customerId)
      if (!hook.state.edit) {
        hook.setState({ customerParent: currentParent });
        hook.setState({ customerParentId: currentParent.id });
      }
      hook.setState({ parents: parentsSort });
    } else {
      console.log(res.data.msg);
    }
  });
}
function getExternalClients(hook, id) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/customer/get/external/byCecha`,
    params: { id: id },
  }).then((res) => {
    if (!res.data.err) {
      let externalCustomers = res.data.data.sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
      let filtered = externalCustomers;
      if (!hook.props.edit) {
        filtered = externalCustomers.filter((cust) => {
          if (
            hook.props.data.customersIds.flat(1).includes(cust.clientIdSubiekt)
          ) {
            return false;
          } else return cust;
        });
      }
      hook.setState({ externalCustomers: filtered });
    } else {
      console.log(res.data.msg);
    }
  });
}
function getSuggestedProducts(hook, id) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/customer/get/all/suggestedProducts`,
    params: { id: id },
  }).then((res) => {
    if (!res.data.err) {
      let productsSort = res.data.data.sort((a, b) =>
        a.tw_Symbol > b.tw_Symbol ? 1 : -1
      );
      hook.setState({ customerSuggestedProducts: productsSort });
      let customerSuggestedProductsChoosen = [];
      productsSort.forEach((prod) => {
        if (prod.onPlatform) {
          customerSuggestedProductsChoosen.push(prod.id);
        }
      });
      hook.setState({
        customerSuggestedProductsChoosen: customerSuggestedProductsChoosen,
      });
    } else {
      console.log(res.data.msg);
    }
  });
}
function resendInviteToCustomer(hook, customerId) {
  Axios({
    method: "POST",
    withCredentials: true,
    url: `${API_HOST}/customer/resendInvite`,
    data: { id: customerId },
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ successMsg: res.data.msg });
      hook.setState({ snackbarOpen: true });
    } else {
      console.log(res.data.msg);
    }
  });
}
const styles = (theme) => ({
  header: {
    padding: 10,
    borderRadius: 0,
  },
  root: {
    width: "100%",
    //maxWidth: "36ch",
    backgroundColor: theme.palette.background.default,
    maxHeight: 350,
    overflowY: "scroll",
    overflowX: "hidden",
    boxShadow: theme.shadows[1],
    marginBottom: 20,
  },
  item: {
    borderRadius: 5,
  },
  inline: {
    display: "inline",
  },
  green: {
    backgroundColor: "#9ccc65",
    color: "white",
  },
  blue: {
    backgroundColor: "#29b6f6",
    color: "white",
  },
  purple: {
    backgroundColor: "#ab47bc",
    color: "white",
  },
  whiteText: {
    color: "white",
  },
  rootPaper: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.25),
    margin: 0,
    boxShadow: "none",
  },
  chip: {
    margin: theme.spacing(0.25),
  },
  marginAuto: {
    margin: "auto",
  },
});

function checkEmail(email) {
  if (/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
    return false;
  } else {
    return true;
  }
}

class CustomerDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cecha: [],
      customerId: 0,
      customerName: "",
      customerClientIdSubiekt: 0,
      customerCechaIdSubiekt: 0,
      customerParent: {},
      customerParentId: 0,
      successMsg: "",
      snackbarOpen: false,
      customerEmail: "",
      customerManagerEmail: "",
      customerGroupId: 0,
      customerGroupLabel: "Rolnictwo",
      customerWarehouse: 2,
      customerFreeDelivery: false,
      customerSuggestedProducts: [],
      customerOperator: {},
      customerOperatorId: 0,
      parents: [],
      operators: [],
      openDrawer: false,
      editExternal: false,
      pickedCecha: 0,
      externalCustomers: [],
      customerSuggestedProductsChoosen: [],
      externalCechaChoosen: {},
      categoryLabel: "",
      uploadedImages: [],
      chipDataGroup: [
        { key: 0, label: "Rolnictwo", color: "green" },
        { key: 1, label: "Przemysł", color: "blue" },
        { key: 2, label: "Detal", color: "purple" },
      ],
      chipDataWarehouse: [
        { key: 2, label: "Siedlce", color: "green" },
        { key: 1, label: "Biała Podlaska", color: "blue" },
      ],
    };
  }
  componentDidMount() {
    getCecha(this);
    getOperators(this);
    getParents(this);
  }
  static getDerivedStateFromProps(props, state) {
    if (props.data.customer && props.data.customer?.id !== state.customerId) {
      
      let parentClient = state.parents.filter((parent)=>parent.id===props.data.customer.parentClientId)
      console.log("EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE", parentClient)
      return {
        customerId: props.data.customer.id,
        customerName: props.data.customer.name,
        customerClientIdSubiekt: props.data.customer.clientIdSubiekt,
        customerCechaIdSubiekt: props.data.customer.cechaIdSubiekt,
        //customerSymbol: props.data.customer.symbol,
        customerEmail: props.data.customer.email,
        customerManagerEmail: props.data.customer.managerEmail,
        customerGroupId: props.data.customer.groupId,
        customerWarehouse: props.data.customer.magazynId,
        customerOperatorId: props.data.customer.operatorId,
        customerOperator: props.data.customer.operator,
        customerParentId: props.data.customer.parentClientId,
        customerParent: parentClient[0],
        customerFreeDelivery: props.data.customer.freeDelivery,
      };
    }
    return null;
  }
  handleSelectedGroupChange(key, label) {
    this.setState({ customerGroupId: key });
    this.setState({ customerGroupLabel: label });
  }
  handleSelectedWarehouseChange(key) {
    this.setState({ customerWarehouse: key });
  }
  handleToggle(value) {
    const currentIndex =
      this.state.customerSuggestedProductsChoosen.indexOf(value);
    const newChecked = [...this.state.customerSuggestedProductsChoosen];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({ customerSuggestedProductsChoosen: newChecked });
  }
  handleAllCheckbox(hook, event) {
    if (event.target.checked) {
      const newChecked = this.state.customerSuggestedProducts
        .filter((prod) => {
          return prod.onPlatform === true;
        })
        .map((prod) => {
          return prod.id;
        });
      console.log(newChecked);
      this.setState({ customerSuggestedProductsChoosen: newChecked });
    } else {
      this.setState({ customerSuggestedProductsChoosen: [] });
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <Drawer
        anchor={"right"}
        open={this.props.open}
        onClose={() => {
          this.props.onClose();
        }}
      >
        <Box m={2} style={{ maxWidth: 600 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography style={{ float: "left" }} variant="h5">
                {!this.props.edit && "Dodawanie klienta"}
                {this.props.edit && "Edycja klienta"}
              </Typography>
              <IconButton
                onClick={() => {
                  this.props.onClose();
                }}
                style={{ float: "right" }}
              >
                <Close />
              </IconButton>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="body1">
                Subiekt
                {this.props.edit && (
                  <IconButton
                    onClick={() => {
                      this.setState({ editExternal: true });
                    }}
                  >
                    <SyncAlt />
                  </IconButton>
                )}
              </Typography>
              {this.props.edit && (
                <Typography variant="body1">
                  Ponów zaproszenie
                  <IconButton
                    onClick={() => {
                      resendInviteToCustomer(this, this.state.customerId);
                    }}
                  >
                    <Send />
                  </IconButton>
                </Typography>
              )}
            </Grid>
            {(!this.props.edit || this.state.editExternal) && (
              <Grid item xs={12} md={12} lg={6}>
                <Autocomplete
                  required
                  id="combo-box-demo"
                  options={this.state.cecha}
                  getOptionLabel={(option) => `${option.name}`}
                  onChange={(event, newValue) => {
                    if (newValue.id) {
                      this.setState({
                        externalCechaChoosen: newValue,
                      });
                      getExternalClients(this, newValue.id);
                    }
                  }}
                  //value={this.state.externalCechaChoosen}
                  //defaultValue={this.state.externalCechaChoosen?.name}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Cecha" variant="outlined" />
                  )}
                />
              </Grid>
            )}
            {(!this.props.edit || this.state.editExternal) && (
              <Grid item xs={12} md={12} lg={6}>
                <Autocomplete
                  required
                  id="combo-box-demo"
                  options={this.state.externalCustomers}
                  getOptionLabel={(option) =>
                    `[${option.symbol}] ${option.name}`
                  }
                  noOptionsText="Wyszukiwani klienci są już dodani lub podano błędne dane"
                  onChange={(event, customer) => {
                    if (customer) {
                      this.setState({
                        customerName: customer.name,
                        customerClientIdSubiekt: customer.clientIdSubiekt,
                        customerCechaIdSubiekt: customer.cechaIdSubiekt,
                        //customerSymbol: `[${customer.symbol}]`,
                        customerEmail: customer.email,
                        customerManagerEmail: customer.managerEmail,
                        externalCustomersChoosen: customer,
                      });
                      getSuggestedProducts(this, customer.clientIdSubiekt);
                    }
                  }}
                  style={{ width: "100%" }}
                  //value={this.state.externalCustomersChoosen}
                  //defaultValue={this.state.externalCustomersChoosen?.name}
                  renderInput={(params) => (
                    <TextField {...params} label="Klient" variant="outlined" />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="body1">Informacje</Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                required
                id="outlined-required-name"
                style={{ width: "100%" }}
                value={this.state.customerName || ""}
                onChange={(event) => {
                  this.setState({
                    customerName: event.target.value,
                  });
                }}
                label="Nazwa"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                id="outlined-multiline-flexible"
                label="E-mail"
                type="email"
                error={
                  this.state.customerEmail === "" ||
                  checkEmail(this.state.customerEmail)
                }
                required
                style={{ width: "100%" }}
                value={this.state.customerEmail || ""}
                onChange={(event) => {
                  this.setState({
                    customerEmail: event.target.value,
                  });
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                id="outlined-multiline-flexible"
                label="E-mail do potwierdzeń (niewymagane)"
                type="email"
                style={{ width: "100%" }}
                value={this.state.customerManagerEmail || ""}
                onChange={(event) => {
                  this.setState({
                    customerManagerEmail: event.target.value,
                  });
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              
              <Autocomplete
                id="combo-box-demo"
                options={this.state.operators}
                required
                getOptionLabel={(option) =>
                  `${option.firstName} ${option.surname}`
                }
                onChange={(event, newValue) => {
                  this.setState({
                    customerOperatorId: newValue._id,
                    customerOperator: newValue,
                  });
                }}
                value={this.state.customerOperator}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Operator" variant="outlined" />
                )}
              />
            </Grid>
            
            <Grid item xs={12} md={12} lg={6}>
            <Autocomplete
                id="combo-box-demo"
                options={this.state.parents}
                required
                getOptionLabel={(option) =>
                  `${option.name}`
                }
                onChange={(event, newValue) => {
                  this.setState({
                    customerParentId: newValue.id,
                    customerParent: newValue,
                  });
                }}
                value={this.state.customerParent}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Kontrahent nadrzędny" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Typography variant="body1" style={{ marginBottom: 10 }}>
                Grupa:
              </Typography>
              <Paper component="ul" className={classes.rootPaper}>
                {this.state.chipDataGroup.map((data) => {
                  let icon;
                  if (this.state.customerGroupId === data.key) {
                    icon = <Check />;
                  }
                  return (
                    <li key={data.key}>
                      <Chip
                        variant={
                          this.state.customerGroupId === data.key
                            ? undefined
                            : "outlined"
                        }
                        //size="small"
                        icon={icon}
                        label={data.label}
                        color={"primary"}
                        className={classes.chip}
                        style={
                          this.state.customerGroupId === data.key
                            ? { color: "white" }
                            : undefined
                        }
                        onClick={() => {
                          this.handleSelectedGroupChange(data.key, data.label);
                        }}
                      />
                    </li>
                  );
                })}
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Typography variant="body1" style={{ marginBottom: 10 }}>
                Magazyn zamówień:
              </Typography>
              <Paper component="ul" className={classes.rootPaper}>
                {this.state.chipDataWarehouse.map((data) => {
                  let icon;
                  if (this.state.customerWarehouse === data.key) {
                    icon = <Check />;
                  }
                  return (
                    <li key={data.key}>
                      <Chip
                        variant={
                          this.state.customerWarehouse === data.key
                            ? undefined
                            : "outlined"
                        }
                        //size="small"
                        icon={icon}
                        label={data.label}
                        color={"primary"}
                        className={classes.chip}
                        style={
                          this.state.customerWarehouse === data.key
                            ? { color: "white" }
                            : undefined
                        }
                        onClick={() => {
                          this.handleSelectedWarehouseChange(data.key);
                        }}
                      />
                    </li>
                  );
                })}
              </Paper>
              <Typography variant="body1" style={{ marginTop: 10 }}>
                Dostawa:
              </Typography>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.customerFreeDelivery}
                      color="primary"
                      onChange={(ev, free) => {
                        this.setState({
                          customerFreeDelivery: free,
                        });
                      }}
                      name="customerFreeDelivery"
                    />
                  }
                  label="Darmowa"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              {this.state.customerSuggestedProducts.length > 0 &&
                !this.props.edit && (
                  <div>
                    <Typography variant="body1" style={{ marginBottom: 10 }}>
                      Sugerowany przydział produktów{" "}
                      <Tooltip
                        style={{ fontSize: 18 }}
                        title="Na podstawie dotychczas złożonych zamówień"
                        arrow
                      >
                        <InfoOutlined />
                      </Tooltip>{" "}
                      :{" "}
                    </Typography>
                    <List>
                      <ListItem key={0} role={undefined} dense>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={
                              this.state.customerSuggestedProducts.filter(
                                (prod) => {
                                  return prod.onPlatform === true;
                                }
                              ).length ===
                              this.state.customerSuggestedProductsChoosen.length
                            }
                            indeterminate={
                              this.state.customerSuggestedProducts.filter(
                                (prod) => {
                                  return prod.onPlatform === true;
                                }
                              ).length <=
                              this.state.customerSuggestedProductsChoosen.length
                            }
                            onChange={(event) => {
                              this.handleAllCheckbox(this, event);
                            }}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": 0 }}
                          />
                        </ListItemIcon>
                        <ListItemText id={0} primary="Nazwa" />
                        <ListItemSecondaryAction>
                          <Chip
                            label={`Wybrane: ${
                              this.state.customerSuggestedProductsChoosen.filter(
                                function (prod) {
                                  return prod.onPlatform !== true;
                                }
                              ).length
                            }
                             / ${this.state.customerSuggestedProducts.length}`}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>

                    <List className={classes.root}>
                      {this.state.customerSuggestedProducts.map((prod) => {
                        const labelId = `checkbox-list-label-${prod.id}`;
                        return (
                          <ListItem
                            key={prod.id}
                            role={undefined}
                            dense
                            button
                            onClick={() => {
                              this.handleToggle(prod.id);
                            }}
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={this.state.customerSuggestedProductsChoosen.includes(
                                  prod.id
                                )}
                                disabled={!prod.onPlatform}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={prod.name} />
                            <ListItemSecondaryAction>
                              {!prod.onPlatform && (
                                <Tooltip title="Brak w Platformie">
                                  <Warning />
                                </Tooltip>
                              )}
                              {prod.onPlatform && !prod.isOrdered && (
                                <Tooltip title="Dostępny w Platformie">
                                  <DoneAll />
                                </Tooltip>
                              )}
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      })}
                    </List>
                  </div>
                )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Button
              style={{ float: "right" }}
              variant="outlined"
              color="primary"
              onClick={() => {
                let customer = {
                  id: this.state.customerId,
                  name: this.state.customerName,
                  clientIdSubiekt: this.state.customerClientIdSubiekt,
                  cechaIdSubiekt: this.state.customerCechaIdSubiekt,
                  operatorId: this.state.customerOperatorId,
                  parentClientId: this.state.customerParentId,
                  email: this.state.customerEmail,
                  managerEmail: this.state.customerManagerEmail,
                  warehouseId: this.state.customerWarehouse,
                  freeDelivery: this.state.customerFreeDelivery,
                  groupId: this.state.customerGroupId,
                  products: this.state.customerSuggestedProductsChoosen,
                };
                this.props.onSave(customer, this.props.edit);
              }}
            >
              Zapisz
            </Button>
          </Grid>
        </Box>
        <Snackbar
          open={this.state.snackbarOpen}
          autoHideDuration={4000}
          onClose={() => {
            this.setState({ snackbarOpen: false });
          }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => {
              this.setState({ snackbarOpen: false });
            }}
            variant="filled"
            severity="success"
            sx={{ width: "100%" }}
          >
            {this.state.successMsg}
          </Alert>
        </Snackbar>
      </Drawer>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CustomerDrawer);
