import React, { useState } from "react";
import ReactDOM from "react-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Toolbar from "@material-ui/core/Toolbar";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Axios } from "./middleware/axiosInstance";
import ClientApp from "./ClientApp";
import Alert from "@material-ui/lab/Alert";
import isAuthenticated from "./middleware/isAuthenticated";
import { countries } from "./locales.json";
import { Paper } from "@material-ui/core";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../config.json")["API_HOST"][env];

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://bialpak.pl/">
        BPdev
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage:
      "url(https://images.unsplash.com/photo-1565891741441-64926e441838?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1502&q=80)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}
const autocompleteStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      // Default transform is "translate(14px, 20px) scale(1)""
      // This lines up the label with the initial cursor position in the input
      // after changing its padding-left.
      transform: "translate(14px, 20px) scale(1);",
    },
  },
  inputRoot: {
    color: "black",
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 6,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
  },
  clearIndicator: {
    color: "black",
  },
  popupIndicator: {
    color: "black",
  },
}));

export default function SignInSide() {
  const classes = useStyles();
  const autocompClass = autocompleteStyles();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [wrongPasswordAlert, setWrongPasswordAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [lang] = React.useState(countries);
  const [choosenLang, setChoosenLang] = React.useState(lang[1]);

  const handleLangChange = (event, value) => {
    setChoosenLang(value);
  };

  const login = (event) => {
    event.stopPropagation();
    event.preventDefault();
    Axios({
      method: "POST",
      data: {
        email: email,
        password: password,
      },
      withCredentials: true,
      url: `${API_HOST}/login/client`,
    }).then((res) => {
      console.log(res);
      if (res.status === 200) {
        if (res.data.error) {
          setWrongPasswordAlert(true);
          setAlertMessage(res.data.error);
          setTimeout(() => {
            setWrongPasswordAlert(false);
          }, 8000);
        } else {
          ReactDOM.render(
            <React.StrictMode>
              <ClientApp />
            </React.StrictMode>,
            document.getElementById("root")
          );
        }
      }
    });
  };

  return (
    <div>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Toolbar>
            {/* <Typography variant="h6" className={classes.width100FL}>
      {choosenLang.translation.advice} - Bialpak
    </Typography> */}
            <Autocomplete
              id="country-select-demo"
              style={{
                width: 300,
                color: "black",
                position: "absolute",
                right: 20,
              }}
              options={lang}
              classes={autocompClass}
              onChange={handleLangChange}
              autoHighlight
              size="small"
              value={choosenLang}
              getOptionLabel={(option) => option.label}
              renderOption={(option) => (
                <React.Fragment>
                  <span>{countryToFlag(option.code)}&nbsp;</span>
                  {option.label}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${choosenLang.translation.choose_language}`}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </Toolbar>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {`${choosenLang.translation.login}`}
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label={`${choosenLang.translation.email_address}`}
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={`${choosenLang.translation.password}`}
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label={`${choosenLang.translation.remember_me}`}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(event) => {
                  login(event);
                }}
              >
                {`${choosenLang.translation.log_in}`}
              </Button>
              {wrongPasswordAlert && (
                <Alert style={{ marginBottom: "10px" }} severity="error">
                  {alertMessage}
                </Alert>
              )}
              <Grid container>
                <Grid item xs>
                  <Link href="/forgottenPassowordClient" variant="body2">
                    {`${choosenLang.translation.forgotten_password}`}
                  </Link>
                </Grid>
                {/* <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid> */}
              </Grid>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
