import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";
import { Axios } from "../middleware/axiosInstance";
import { Add, Check, Face, Person, PlusOne } from "@material-ui/icons";

import {
  Badge,
  Button,
  Chip,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];
const useStyles = makeStyles((theme) => ({
  header: {
    padding: 10,
    borderRadius: 0,
  },
  root: {
    width: "100%",
    //maxWidth: "36ch",
    backgroundColor: theme.palette.background.default,
    maxHeight: 900,
    overflowY: "scroll",
    overflowX: "hidden",
    boxShadow: theme.shadows[1],
  },
  item: {
    borderRadius: 5,
  },
  inline: {
    display: "inline",
  },
  green: {
    backgroundColor: "#9ccc65",
    color: "white",
  },
  factorGreen: {
    backgroundColor: "#CDDC39",
    color: "white",
  },
  factorRed: {
    backgroundColor: "#FFB74D",
    color: "white",
  },
  blue: {
    backgroundColor: "#29b6f6",
    color: "white",
  },
  purple: {
    backgroundColor: "#ab47bc",
    color: "white",
  },
  whiteText: {
    color: "white",
  },
  rootPaper: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.25),
    margin: 0,
    boxShadow: "none",
  },
  chip: {
    margin: theme.spacing(0.25),
  },
  marginAuto: {
    margin: "auto",
  },
}));

function customersFilter(
  customerNameFilter,
  selectedGroup,
  chipDataGroup,
  selectedWarehouse,
  chipDataWarehouse,
  onlyOwn,
  userId
) {
  return function (element) {
    let groupMap = new Map(
      chipDataGroup.map((group) => {
        return [group.label, group];
      })
    );
    let warehouseMap = new Map(
      chipDataWarehouse.map((warehouse) => {
        return [warehouse.label, warehouse];
      })
    );
    let name = element.name
      .toUpperCase()
      .includes(customerNameFilter.toUpperCase());
    let group = true;
    if (selectedGroup.length > 0) {
      let groupSelected = groupMap.get(element.group);
      group = selectedGroup.includes(groupSelected.key);
    }
    let warehouse = true;
    if (selectedWarehouse.length > 0) {
      warehouse = selectedWarehouse.includes(element.magazynId);
    }
    let own = true;
    if (element.operatorId !== userId && onlyOwn) {
      own = false;
    }
    if (name && group && warehouse && own) {
      return element;
    }
  };
}

export default function AlignItemsList(props) {
  const classes = useStyles();
  const [chipDataGroup, setChipDataGroup] = React.useState([
    { key: 0, label: "Rolnictwo", color: "green" },
    { key: 1, label: "Przemysł", color: "blue" },
    { key: 2, label: "Detal", color: "purple" },
  ]);
  const [chipDataWarehouse, setChipDataWarehouse] = React.useState([
    { key: 2, label: "Siedlce", color: "green" },
    { key: 1, label: "Biała Podlaska", color: "blue" },
  ]);
  const [onlyOwnFilter, setOnlyOwnFilter] = React.useState(true);
  const [selectedGroup, setSelectedGroup] = React.useState([]);
  const [userId, setUserId] = React.useState(0);
  const [customerNameFilter, setCustomerNameFilter] = React.useState("");
  const [selectedWarehouse, setSelectedWarehouse] = React.useState([]);
  const handleCustomerNameFilter = (event) => {
    setCustomerNameFilter(event.target.value);
  };
  const handleSelectedGroupChange = (key) => {
    if (selectedGroup.includes(key)) {
      setSelectedGroup((oldKeys) => {
        let newKeys = oldKeys.filter((i, _) => i !== key);
        return newKeys;
      });
    } else {
      setSelectedGroup((oldKeys) => [...oldKeys, key]);
    }
  };
  const handleSelectedWarehouseChange = (key) => {
    if (selectedWarehouse.includes(key)) {
      setSelectedWarehouse((oldKeys) => {
        let newKeys = oldKeys.filter((i, _) => i !== key);
        return newKeys;
      });
    } else {
      setSelectedWarehouse((oldKeys) => [...oldKeys, key]);
    }
  };
  React.useEffect(() => {
    Axios({
      method: "GET",
      withCredentials: true,
      url: `${API_HOST}/user`,
    }).then((res) => {
      setUserId(res.data.user._id);
      console.log(res.data.user._id);
    });
  }, []);
  return (
    <div>
      <Paper className={classes.header}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              value={customerNameFilter}
              onChange={handleCustomerNameFilter}
              variant="outlined"
              size="small"
              style={{ width: "100%" }}
              label="Wyszukaj klienta"
            ></TextField>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Paper component="ul" className={classes.rootPaper}>
              {chipDataGroup.map((data) => {
                let icon;
                if (selectedGroup.includes(data.key)) {
                  icon = <Check />;
                }
                return (
                  <li key={data.key}>
                    <Chip
                      variant={
                        selectedGroup.includes(data.key)
                          ? undefined
                          : "outlined"
                      }
                      size="small"
                      icon={icon}
                      label={data.label}
                      color={"primary"}
                      className={classes.chip}
                      style={
                        selectedGroup.includes(data.key)
                          ? { color: "white" }
                          : undefined
                      }
                      onClick={() => {
                        handleSelectedGroupChange(data.key);
                      }}
                    />
                  </li>
                );
              })}
            </Paper>
          </Grid>
          <Divider style={{ width: "90%", marginLeft: "5%" }} component="div" />
          <Grid item xs={12} md={12} lg={12}>
            <Paper component="ul" className={classes.rootPaper}>
              {chipDataWarehouse.map((data) => {
                let icon;
                if (selectedWarehouse.includes(data.key)) {
                  icon = <Check />;
                }
                return (
                  <li key={data.key}>
                    <Chip
                      variant={
                        selectedWarehouse.includes(data.key)
                          ? undefined
                          : "outlined"
                      }
                      size="small"
                      icon={icon}
                      label={data.label}
                      color={"primary"}
                      className={classes.chip}
                      style={
                        selectedWarehouse.includes(data.key)
                          ? { color: "white" }
                          : undefined
                      }
                      onClick={() => {
                        handleSelectedWarehouseChange(data.key);
                      }}
                    />
                  </li>
                );
              })}
            </Paper>
          </Grid>
          <Divider style={{ width: "90%", marginLeft: "5%" }} component="div" />
          <Grid item xs={12} md={12} lg={12}>
            <Paper component="ul" className={classes.rootPaper}>
              <li>
                <Chip
                  variant={onlyOwnFilter ? undefined : "outlined"}
                  size="small"
                  icon={onlyOwnFilter ? <Check /> : undefined}
                  label={"Tylko swoi"}
                  color={"primary"}
                  className={classes.chip}
                  style={onlyOwnFilter ? { color: "white" } : undefined}
                  onClick={() => {
                    setOnlyOwnFilter((prevVal) => !prevVal);
                  }}
                />
              </li>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
      <List className={classes.root}>
        {props.customers
          .filter(
            customersFilter(
              customerNameFilter,
              selectedGroup,
              chipDataGroup,
              selectedWarehouse,
              chipDataWarehouse,
              onlyOwnFilter,
              userId
            )
          )
          .map((customer) => {
            return (
              <div key={customer.id}>
                <ListItem
                  alignItems="flex-start"
                  button
                  className={classes.item}
                  onClick={() => {
                    if (customer.id !== null) {
                      props.onCustomerChoose(customer);
                    }
                  }}
                >
                  <ListItemAvatar className={classes.marginAuto}>
                    <Person />
                  </ListItemAvatar>
                  <ListItemText
                    primary={customer.name.substring(0, 35)}
                    secondary={
                      <React.Fragment>
                        {/* <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary"
                          style={{
                            width: "100%",
                            float: "left",
                            marginBottom: 10,
                          }}
                        >
                          {customer.email}
                        </Typography> */}
                        <Chip
                          className={classes.green}
                          label={customer.group}
                          size="small"
                          style={{ marginRight: 5, marginTop: 5 }}
                        />
                        <Chip
                          className={classes.whiteText}
                          color={"primary"}
                          label={customer.magazyn}
                          size="small"
                          style={{ marginRight: 5, marginTop: 5 }}
                        />
                        <Chip
                          className={
                            customer.priceFactor >= 0
                              ? classes.factorGreen
                              : classes.factorRed
                          }
                          //color={"primary"}
                          style={{ marginTop: 5 }}
                          label={
                            customer.priceFactor >= 0
                              ? `+${customer.priceFactor}%`
                              : `${customer.priceFactor}%`
                          }
                          size="small"
                        />
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </div>
            );
          })}
      </List>
    </div>
  );
}
