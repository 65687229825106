/* eslint-disable no-loop-func */
import React from "react";
import ReactDOM from "react-dom";
import Scheduler, {
  SchedulerData,
  ViewTypes,
  CellUnits,
  DATE_FORMAT,
} from "react-big-scheduler";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import "./SchedulerStyle.css";
import WindowEditor from "./WindowEditModal";
import EmailSender from "./EmailSendModal";
import moment from "moment-business-days";
import withDragDropContext from "./withDnDContext";
import { Axios } from "./middleware/axiosInstance";
import Button from "@material-ui/core/Button";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import PersonIcon from "@material-ui/icons/Person";
import Checkbox from "@material-ui/core/Checkbox";
import InvitationsAddModal from "./invitation/InvitationsAddModal";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import CustomerDrawer from "./sales_customers/CustomerDrawer";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { Category } from "@material-ui/icons";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../config.json")["API_HOST"][env];

function getWindows(hook) {
  let startDate = hook.state.viewModel.startDate;
  let endDate = hook.state.viewModel.endDate;
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/ramp/get/all`,
  }).then((res) => {
    hook.resources = res.data.data;
    hook.setState({ resourcesReturned: true });
    let newSchedulerData = hook.state.viewModel;
    newSchedulerData.setResources(res.data.data);
    hook.setState({
      viewModel: newSchedulerData,
    });
  });
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/window/get/all`,
    params: { start: startDate, end: endDate },
  }).then((res) => {
    hook.resources = res.data.data;
    hook.setState({ windowsReturned: true });
    hook.setState({ maxWindowId: res.data.maxValue });
    let newSchedulerData = hook.state.viewModel;
    let sorted = res.data.data;
    sorted.sort(function (a, b) {
      return new Date(a.start) - new Date(b.start);
    });
    newSchedulerData.setEvents(sorted);
    hook.setState({
      viewModel: newSchedulerData,
    });
  });
}
function updateWindows(hook, data) {
  Axios({
    method: "PUT",
    withCredentials: true,
    url: `${API_HOST}/window/update`,
    params: data,
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ databaseState: true });
      hook.props.refreshResource();
      hook.props.refreshInvitations();
    } else {
      hook.setState({ databaseState: false });
      console.log(res.data.msg);
    }
  });
}
function addWindow(hook, data) {
  Axios({
    method: "POST",
    withCredentials: true,
    url: `${API_HOST}/window/add`,
    data: data,
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ databaseState: true });
      hook.setState({ maxWindowId: res.data.maxValue });
      hook.props.refreshResource();
    } else {
      hook.setState({ databaseState: false });
      console.log(res.data.msg);
    }
  });
}
function addGeneratedWindows(hook, data) {
  Axios({
    method: "POST",
    withCredentials: true,
    url: `${API_HOST}/window/add/generated`,
    data: { windows: data },
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ databaseState: true });
      getWindows(hook);
      hook.setState({ maxWindowId: res.data.maxValue });
      hook.setState({ generateLoading: false });
      hook.props.refreshResource();
    } else {
      hook.setState({ databaseState: false });
      console.log(res.data.msg);
    }
  });
}
function deleteWindow(hook, data) {
  Axios({
    method: "DELETE",
    withCredentials: true,
    url: `${API_HOST}/window/delete`,
    params: data,
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ databaseState: true });
      hook.props.refreshResource();
      hook.props.refreshInvitations();
    } else {
      hook.setState({ databaseState: false });
      console.log(res.data.msg);
    }
  });
}
function rightCustomHeader(hook) {
  return (
    <div>
      {!hook.state.invitationMode && (
        <Button
          variant="outlined"
          style={{ height: 33, marginRight: 10 }}
          color="primary"
          onClick={() => {
            hook.setInvitationMode(true);
          }}
        >
          Tryb zaproszeń
        </Button>
      )}
      {hook.state.invitationMode && (
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          <Button
            onClick={() => {
              hook.inviteCreate();
            }}
          >
            Utwórz zaproszenie
          </Button>
          <Button
            variant="outlined"
            style={{ height: 33, marginRight: 10 }}
            color="primary"
            onClick={() => {
              hook.setInvitationMode(false);
            }}
          >
            Anuluj
          </Button>
        </ButtonGroup>
      )}
      <Button
        variant="outlined"
        color="primary"
        style={{ height: 33, marginRight: 10 }}
        disabled={hook.state.generateLoading}
        onClick={() => {
          hook.generateEvents(hook);
        }}
      >
        Generuj okna
      </Button>
      {hook.state.generateLoading && (
        <div style={{ margin: 10, float: "left" }}>
          <CircularProgress
            size={24}
            style={{
              buttonProgress: {
                color: "#ff6634",
                position: "absolute",
              },
            }}
          />
        </div>
      )}

      <IconButton
        onClick={() => {
          getWindows(hook);
        }}
        aria-label="refresh"
      >
        <RefreshIcon />
      </IconButton>
    </div>
  );
}
const stringToColour = function (str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = "#";
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};

let dotStyle = {
  float: "left",
  position: "absolute",
  left: "6px",
  width: "12px",
  height: "12px",
  top: "50%",
  "-webkit-transform": "translate(0, -50%)",
  transform: "translate(0, -50%)",
};

function getPercentage(hook) {
  let start = moment(hook.props.start, "YYYY-MM-DD").toDate();
  let end = moment(hook.props.end, "YYYY-MM-DD").toDate();
  start = moment(start).add("hour", hook.state.dayStartFrom);
  end = moment(end).add("hour", hook.state.dayStopTo);
  let now = moment();
  if (start > now) {
    return 0;
  }
  let per_complete = ((now - start) / (end - start)) * 100;
  let final = Math.round(per_complete * 100) / 100;
  if (final > 100) {
    final = 100;
  }
  return final;
}

const getDateLabel = (schedulerData, viewType, startDate, endDate) => {
  let start = schedulerData.localeMoment(startDate);
  let end = schedulerData.localeMoment(endDate);
  let dateLabel = start.format("dddd DD.MM.YYYY");

  if (
    viewType === ViewTypes.Week ||
    (start != end &&
      (viewType === ViewTypes.Custom ||
        viewType === ViewTypes.Custom1 ||
        viewType === ViewTypes.Custom2))
  ) {
    dateLabel = `${start.format("D ")}-${end.format("D MMM YYYY")}`;
    if (start.month() !== end.month())
      dateLabel = `${start.format("D MMM ")}-${end.format(" D MMM YYYY")}`;
    if (start.year() !== end.year())
      dateLabel = `${start.format("D MMM, YYYY")}-${end.format(" D MMM YYYY")}`;
  } else if (viewType === ViewTypes.Month) {
    dateLabel = start.format("MMMM YYYY");
  } else if (viewType === ViewTypes.Quarter) {
    dateLabel = `${start.format("MMM D")}-${end.format("MMM D YYYY")}`;
  } else if (viewType === ViewTypes.Year) {
    dateLabel = start.format("YYYY");
  }

  return dateLabel;
};

class Indicator extends React.Component {
  constructor(props) {
    super(props);
    if (props.screen !== "xs") {
      this.indicator = document.createElement("div");
      this.indicator.id = "scheduler-indicator";
      this.indicatorDot = document.createElement("div");
      this.indicatorDot.id = "scheduler-indicator-dot";
      this.indicator.appendChild(this.indicatorDot);
      this.state = {
        dayStartFrom: 6,
        dayStopTo: 18,
      };
    }
  }
  componentDidUpdate() {
    if (this.props.screen !== "xs") {
      this.indicator.style.left = `${getPercentage(this)}%`;
    }
  }
  componentDidMount() {
    if (this.props.screen !== "xs") {
      const SchedulerView =
        document.getElementsByClassName("scheduler-content");
      this.indicator.style.left = `${getPercentage(this)}%`;
      SchedulerView[0].appendChild(this.indicator);
      setInterval(() => {
        this.indicator.style.left = `${getPercentage(this)}%`;
      }, 5000);
    }
  }
  render() {
    if (this.props.screen !== "xs") {
      return ReactDOM.createPortal(this.props.children, this.indicator);
    } else {
      return null;
    }
  }
}
class SchedulerWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resourcesReturned: false,
      windowsReturned: false,
      editWindow: false,
      senderWindow: false,
      invitationModal: false,
    };
    this.resources = [];
    this.events = [];

    moment.locale("pl-pl");

    let schedulerData = new SchedulerData(
      new moment().format(DATE_FORMAT),
      ViewTypes.Day,
      props.width === "xs" ? true : false,
      false,
      {
        customCellWidth: 60,
        nonAgendaDayCellHeaderFormat: "D/M|HH:mm",
        views: [
          {
            viewName: "Dzień",
            viewType: ViewTypes.Day,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: "Tydzień",
            viewType: ViewTypes.Week,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: "Miesiąc",
            viewType: ViewTypes.Month,
            showAgenda: false,
            isEventPerspective: false,
          },
        ],
      },
      {
        getCustomDateFunc: this.getCustomDate,
        isNonWorkingTimeFunc: this.isNonWorkingTime,
        getSummaryFunc: this.getSummary,
        checkConflict: true,
      }
    );
    schedulerData.config.schedulerWidth = "100%";
    schedulerData.config.dayCellWidth = 60;
    schedulerData.config.besidesWidth = props.width === "xs" ? 60 : 200;
    schedulerData.config.agendaResourceTableWidth = "100px";
    schedulerData.config.dayStartFrom = 6;
    schedulerData.config.dayStopTo = 17;
    schedulerData.config.resourceName = "Lokalizacja";
    schedulerData.setResources(this.resources);
    schedulerData.setEvents(this.events);
    schedulerData.setLocaleMoment(moment);
    schedulerData.setMinuteStep(30);
    schedulerData.config.eventItemHeight = 30;
    schedulerData.config.eventItemLineHeight = 32;
    schedulerData.config.nonWorkingTimeHeadBgColor = "rgb(255 74 74 / 14%)";
    schedulerData.config.nonWorkingTimeBodyBgColor = "rgb(255 74 74 / 14%)";
    schedulerData.config.eventItemPopoverDateFormat = "MMMM Do YYYY, h:mm:ss a";
    schedulerData.behaviors.getDateLabelFunc = getDateLabel;
    this.state = {
      viewModel: schedulerData,
      maxWindowId: 0,
      windowEvent: { start: "placeholder", end: "placeholder" },
      clientData: { name: "Ładuję.." },
      invitationMode: false,
      invitationWindows: [],
      checkedInvitationWindowsIds: {},
      showIndicator: true,
      generateLoading: false,
    };
  }

  componentDidMount() {
    getWindows(this);
  }
  render() {
    const { viewModel, windowEvent } = this.state;
    const stateHook = this;
    return (
      <div>
        <h2>Terminarz</h2>
        <Grid container>
          {this.state.resourcesReturned && (
            <Grid item xs={12} md={12} lg={12}>
              <Scheduler
                schedulerData={viewModel}
                prevClick={this.prevClick}
                nextClick={this.nextClick}
                onSelectDate={this.onSelectDate}
                onViewChange={this.onViewChange}
                eventItemClick={this.eventClicked}
                viewEventClick={this.ops1}
                viewEventText="Usuń"
                viewEvent2Text="Edytuj"
                viewEvent2Click={this.ops2}
                updateEventStart={this.updateEventStart}
                updateEventEnd={this.updateEventEnd}
                moveEvent={this.moveEvent}
                newEvent={this.newEvent}
                eventItemPopoverTemplateResolver={
                  this.eventItemPopoverTemplateResolver
                }
                eventItemTemplateResolver={this.eventItemTemplateResolver}
                toggleExpandFunc={this.toggleExpandFunc}
                conflictOccurred={this.conflictOccurred}
                rightCustomHeader={rightCustomHeader(this)}
              />
              <Indicator
                show={this.props.show}
                start={this.state.viewModel.startDate}
                end={this.state.viewModel.endDate}
                screen={this.props.width}
              />
            </Grid>
          )}
          <WindowEditor
            window={windowEvent}
            open={this.state.editWindow}
            onClose={this.closeEditorDialog}
            updateData={this.updateData}
          />
          <EmailSender
            window={windowEvent}
            open={this.state.senderWindow}
            onClose={this.closeSenderDialog}
            updateData={this.updateData}
          />
          <InvitationsAddModal
            invitationWindows={this.state.invitationWindows}
            open={this.state.invitationModal}
            onClose={this.closeInviteModal}
            updateData={this.updateData}
          />
        </Grid>
      </div>
    );
  }

  updateData = () => {
    getWindows(this);
    this.props.refreshInvitations();
  };

  closeEditorDialog = () => {
    this.setState({ editWindow: false });
  };

  closeSenderDialog = () => {
    this.setState({ senderWindow: false });
  };

  closeInviteModal = () => {
    this.setState({ invitationModal: false });
    this.props.refreshInvitations();
  };

  prevClick = (schedulerData) => {
    schedulerData.prev();
    getWindows(this);
    this.setState({
      viewModel: schedulerData,
    });
  };

  nextClick = (schedulerData) => {
    schedulerData.next();
    getWindows(this);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onViewChange = (schedulerData, view) => {
    let indicator = document.getElementById("scheduler-indicator");
    if (view.viewType === 0) {
      indicator.style.display = "block";
    } else {
      indicator.style.display = "none";
    }
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    // let step = view.viewType === ViewTypes.Custom ? 30 : 15;
    // schedulerData.setMinuteStep(step);
    schedulerData.config.customCellWidth =
      view.viewType === ViewTypes.Custom ? 60 : 60;
    console.log(schedulerData.config.customCellWidth);
    getWindows(this);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    schedulerData.prev();
    schedulerData.next();
    this.setState({
      viewModel: schedulerData,
    });
    getWindows(this);
  };

  eventClicked = (schedulerData, event) => {
    if (!this.state.invitationMode) {
      console.log("asdasd");
      event.start = moment(event.start).utc().format();
      event.end = moment(event.end).utc().format();
      this.setState({
        windowEvent: event,
      });
      this.setState({ editWindow: true });
    }
  };

  inviteSend = (schedulerData, event) => {
    this.setState({
      windowEvent: event,
    });
    this.setState({ senderWindow: true });
  };

  inviteCreate = () => {
    this.setState({ invitationModal: true });
  };

  setInvitationMode = (state) => {
    this.setState({
      invitationMode: state,
    });
  };

  handleInvitationWindow = (event, window) => {
    if (event.target.checked) {
      let checked = { ...this.state.checkedInvitationWindowsIds };
      checked[window.id] = true;
      let sorted = [...this.state.invitationWindows, window];
      sorted.sort(function (a, b) {
        return new Date(a.start) - new Date(b.start);
      });
      this.setState({
        invitationWindows: sorted,
        checkedInvitationWindowsIds: checked,
      });
    } else {
      let windows = this.state.invitationWindows;
      let newWindows = [];
      let newIds = {};
      windows.forEach((w) => {
        if (w.id !== window.id) {
          newWindows.push(w);
          newIds[w.id] = true;
        }
      });
      this.setState({
        invitationWindows: newWindows,
        checkedInvitationWindowsIds: newIds,
      });
    }
  };

  ops1 = (schedulerData, event) => {
    schedulerData.removeEventById(event.id);
    deleteWindow(this, { id: event.id });
  };

  ops2 = (schedulerData, event) => {
    alert(
      `You just executed ops2 to event: {id: ${event.id}, title: ${event.title}}`
    );
  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    let newFreshId = this.state.maxWindowId + 1;
    let newEvent = {
      id: newFreshId,
      title: "Nowe okno",
      start: start,
      end: end,
      resourceId: slotId,
      //clientId: 0,
      type: "Rozładunek",
      bgColor: "#2ecc71",
      carrier: "",
    };
    schedulerData.addEvent(newEvent);
    this.setState({
      viewModel: schedulerData,
    });
    addWindow(this, newEvent);
  };

  generateEvents = (hook) => {
    hook.setState({ generateLoading: true });
    let newFreshId = hook.state.maxWindowId + 1;
    let schedulerData = hook.state.viewModel;
    let newEvent = {
      title: "Nowe okno",
      type: "Rozładunek",
      bgColor: "#2ecc71",
    };
    let start = moment(schedulerData.startDate).utc().format();
    start = moment(start);
    let end = moment(schedulerData.endDate).utc().format();
    end = moment(end);
    let days = end.diff(start, "days");
    if (days === 0) {
      days = 1;
    }
    start = moment(start).set({ hour: 8, minute: 30 });
    end = moment(start).set({ hour: 9, minute: 30 });
    let events = [];
    let currDay = moment(schedulerData.startDate);
    for (let y = 0; y < days; y++) {
      if ([1, 2, 3, 4, 5].includes(currDay.day())) {
        schedulerData.resources.forEach((resource) => {
          if (!resource.preventGenerate) {
            for (let i = 0; i < 5; i++) {
              let event = {
                ...newEvent,
                start: start.utc().format(),
                end: end.utc().format(),
                resourceId: resource.id,
                id: newFreshId,
              };
              events.push(event);
              //schedulerData.addEvent(event);
              console.log(newFreshId);
              newFreshId++;
              start.add(1, "hour").add(30, "minute");
              end.add(1, "hour").add(30, "minute");
            }
            start = moment(start).set({ hour: 7, minute: 30 });
            end = moment(end).set({ hour: 8, minute: 30 });
          }
        });
      }
      start.add(1, "day");
      end.add(1, "day");
      currDay.add(1, "day");
    }
    addGeneratedWindows(this, events);
  };

  updateEventStart = (schedulerData, event, newStart) => {
    schedulerData.updateEventStart(event, newStart);
    this.setState({
      viewModel: schedulerData,
    });
    updateWindows(this, { id: event.id, start: newStart });
  };

  updateEventEnd = (schedulerData, event, newEnd) => {
    schedulerData.updateEventEnd(event, newEnd);
    this.setState({
      viewModel: schedulerData,
    });
    updateWindows(this, { id: event.id, end: newEnd });
  };

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    schedulerData.moveEvent(event, slotId, slotName, start, end);
    this.setState({
      viewModel: schedulerData,
    });
    updateWindows(this, {
      id: event.id,
      start: start,
      end: end,
      resourceId: slotId,
    });
  };

  getCustomDate = (schedulerData, num, date = undefined) => {
    const { viewType } = schedulerData;
    let selectDate = schedulerData.startDate;

    schedulerData.setViewType(
      schedulerData.viewType,
      schedulerData.showAgenda,
      schedulerData.isEventPerspective
    );

    if (date != undefined) selectDate = date;
    console.log("custom");
    let startDate =
        num === 0
          ? selectDate
          : schedulerData
              .localeMoment(selectDate)
              .add(2 * num, "days")
              .format(DATE_FORMAT),
      endDate = schedulerData
        .localeMoment(startDate)
        .add(1, "days")
        .format(DATE_FORMAT),
      cellUnit = CellUnits.Hour;
    if (viewType === ViewTypes.Custom) {
      schedulerData
        .localeMoment(selectDate)
        .add(2 * num, "days")
        .format(DATE_FORMAT);
      endDate = schedulerData
        .localeMoment(startDate)
        .add(1, "days")
        .format(DATE_FORMAT);
      cellUnit = CellUnits.Hour;
    } else if (viewType === ViewTypes.Custom1) {
      schedulerData
        .localeMoment(selectDate)
        .add(2 * num, "days")
        .format(DATE_FORMAT);
      endDate = schedulerData.localeMoment(startDate).format(DATE_FORMAT);
      cellUnit = CellUnits.Hour;
    }

    return {
      startDate,
      endDate,
      cellUnit,
    };
  };

  getSummary = (
    schedulerData,
    headerEvents,
    slotId,
    slotName,
    headerStart,
    headerEnd
  ) => {
    let text = headerEvents.length.toString();
    let color = "#d9d9d9";
    if (headerEvents.length > 0)
      color = headerEvents.length <= 1 ? "green" : "red";
    return { text: text, color: color, fontSize: "12px" };
  };

  conflictOccurred = (
    schedulerData,
    action,
    event,
    type,
    slotId,
    slotName,
    start,
    end
  ) => {
    alert(`Okna czasowe nakładają się`);
  };

  isNonWorkingTime = (schedulerData, time) => {
    const { localeMoment } = schedulerData;
    if (schedulerData.cellUnit === CellUnits.Hour) {
      let hour = localeMoment(time).hour();
      if (hour < 8 || hour > 15) return true;
    } else {
      let dayOfWeek = localeMoment(time).weekday();
      if (dayOfWeek === 5 || dayOfWeek === 6) return true;
    }

    return false;
  };

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData,
    });
  };
  eventItemPopoverTemplateResolver = (
    schedulerData,
    eventItem,
    title,
    start,
    end,
    statusColor
  ) => {
    let name = "Nie przydzielono";
    if (eventItem.client) {
      name = eventItem.client.name;
    }
    let clientColor = "#ddd";
    switch (eventItem.state) {
      case "Powiadomiono":
        clientColor = "#009688";
        break;
      case "Nie powiadomiono":
        clientColor = "#E91E63";
        break;
      case "Potwierdzone":
        clientColor = "#8BC34A";
        break;
      case "Zakończone":
        clientColor = "#009688";
        break;
      case "Zaproszono":
        clientColor = "#FFEB3B";
        break;
      default:
        clientColor = "#ddd";
        break;
    }
    let type = "Rozładunek";
    if (eventItem.type === "Załadunek") {
      type = "Załadunek";
    }
    let eventsProducts = [];
    if (eventItem.products) {
      eventItem.products.map((prod) => {
        eventsProducts.push(
          <Box>
            <Typography variant="body2" style={{ marginBottom: 10 }}>
              {prod.count} x {prod.name}
            </Typography>
          </Box>
        );
      });
    }
    return (
      // <React.Fragment>
      //     <h3>{title}</h3>
      //     <h5>{start.format("HH:mm")} - {end.format("HH:mm")}</h5>
      //     <img src="./icons8-ticket-96.png" />
      // </React.Fragment>
      <div style={{ width: "300px" }}>
        <Grid container spacing={1}>
          <Grid container item xs={12} spacing={13}>
            <PersonIcon style={{ marginRight: 10 }} />
            <span
              className="header2-text"
              style={{
                float: "left",
                display: "inline-flex",
                "align-items": "center",
              }}
              title={name}
            >
              Kontrahent:&nbsp;
              <span
                style={{
                  fontWeight: 600,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {name}
              </span>
            </span>
          </Grid>
          <Grid container item xs={12} spacing={13}>
            <CompareArrowsIcon style={{ marginRight: 10 }} />
            <span
              className="header2-text"
              style={{
                float: "left",
                display: "inline-flex",
                "align-items": "center",
              }}
              title={type}
            >
              Typ:&nbsp;<span style={{ fontWeight: 600 }}>{type}</span>
            </span>
          </Grid>
          {(eventItem.client || eventItem.state === "Zaproszono") && (
            <Grid container item xs={12} spacing={13}>
              <FiberManualRecordIcon
                style={{ color: clientColor, marginRight: 10 }}
              />
              <span
                className="header2-text"
                style={{
                  float: "left",
                  display: "inline-flex",
                  "align-items": "center",
                }}
                title={type}
              >
                Stan:&nbsp;
                <span style={{ fontWeight: 600 }}>{eventItem.state}</span>
              </span>
            </Grid>
          )}
          {eventItem.products?.length > 0 && (
            <Grid container item xs={12} spacing={13}>
              <Category style={{ marginRight: 10 }} />
              <span
                className="header2-text"
                style={{
                  float: "left",
                  display: "inline-flex",
                  "align-items": "center",
                }}
                title={type}
              >
                Produkty:&nbsp;
              </span>
              <span
                style={{
                  marginLeft: 40,
                  fontWeight: 600,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {eventsProducts}
              </span>
            </Grid>
          )}
          <Grid container style={{ marginTop: 10 }} item xs={12} spacing={13}>
            <span className="header1-text" style={{ marginBottom: 20 }}>
              {start.format("HH:mm")} - {end.format("HH:mm")}
            </span>
          </Grid>
          <Grid container item xs={12} spacing={13}>
            {!eventItem.client && !eventItem.isSalesWindow && (
              <Button
                variant="outlined"
                style={{ height: 33, marginRight: 10 }}
                color="primary"
                onClick={() => {
                  this.inviteSend(schedulerData, eventItem);
                }}
              >
                Zaproś
              </Button>
            )}
            {eventItem.state === "Nie powiadomiono" &&
              eventItem.client &&
              !eventItem.isSalesWindow && (
                <Button
                  variant="outlined"
                  style={{ height: 33, marginRight: 10 }}
                  color="primary"
                  onClick={() => {
                    this.inviteSend(schedulerData, eventItem);
                  }}
                >
                  Powiadom
                </Button>
              )}
            {!eventItem.isSalesWindow && (
              <Button
                variant="outlined"
                style={{ height: 33 }}
                color="secondary"
                onClick={() => {
                  this.ops1(schedulerData, eventItem);
                }}
              >
                Usuń
              </Button>
            )}

            <div className="popover-window-id">{`#${eventItem.id}`}</div>
          </Grid>
        </Grid>
      </div>
    );
  };
  eventItemTemplateResolver = (
    schedulerData,
    event,
    bgColor,
    isStart,
    isEnd,
    mustAddCssClass,
    mustBeHeight,
    agendaMaxEventWidth
  ) => {
    console.log(event.isSalesWindow);
    if (event.isSalesWindow) {
      event.client = event.customer;
      console.log(event.customer);
    }
    let borderWidth = isStart ? "4" : "4";
    let borderColor = "rgba(0,139,236,1)",
      backgroundColor = "#80C5F6";
    let clientColor = "#ddd";
    switch (event.state) {
      case "Powiadomiono":
        clientColor = "#009688";
        break;
      case "Nie powiadomiono":
        clientColor = "#E91E63";
        break;
      case "Potwierdzone":
        clientColor = "#8BC34A";
        break;
      case "Zakończone":
        clientColor = "#009688";
        break;
      case "Zaproszono":
        clientColor = "#FFEB3B";
        break;
      default:
        clientColor = "#ddd";
        break;
    }
    if (!!event.client) {
      borderColor = event.client ? "rgba(0,139,236,1)" : "#999";
      backgroundColor = event.client ? bgColor : "#cccccc";
    }
    if (!event.client && event.state !== "Zaproszono") {
      clientColor = "#ddd";
    }
    let divStyle = {
      borderLeft: borderWidth + "px solid " + borderColor,
      borderRight: borderWidth + "px solid " + borderColor,
      backgroundColor: backgroundColor,
      height: mustBeHeight,
    };
    if (!!agendaMaxEventWidth)
      divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
    let type = "R";
    if (event.type === "Załadunek") {
      type = "Z";
    }
    let client = "Wolne";
    if (event.client) {
      client = event.client.name;
    }
    return (
      <div key={event.id} className={mustAddCssClass} style={divStyle}>
        {this.state.invitationMode && !event.client && (
          <Checkbox
            onChange={(clickEvent) => {
              this.handleInvitationWindow(clickEvent, event);
            }}
            checked={this.state.checkedInvitationWindowsIds[event.id]}
            inputProps={{ "aria-label": "primary checkbox" }}
            size="small"
            style={{ padding: 5 }}
          />
        )}
        {!this.state.invitationMode && (
          <div>
            <div
              className="status-dot"
              style={{ ...dotStyle, backgroundColor: clientColor }}
            />
            <span
              style={{ marginLeft: "18px", lineHeight: `${mustBeHeight}px` }}
            >{`[${type}] ${client}`}</span>
          </div>
        )}
      </div>
    );
  };
}
export default withWidth()(withDragDropContext(SchedulerWrapper));
