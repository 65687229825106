import {
  Divider,
  Grid,
  IconButton,
  Paper,
  Slide,
  Slider,
} from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
//import SalesSpark from "./SalesSpark";
import Typography from "@material-ui/core/Typography";
import { Edit } from "@material-ui/icons";
import { Axios } from ".././middleware/axiosInstance";

const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];

function updatePricingPolicy(priceFactorValue, customerId, handleUpdate) {
  Axios({
    method: "PUT",
    withCredentials: true,
    url: `${API_HOST}/customer/set/priceFactor`,
    params: { id: customerId, priceFactor: priceFactorValue },
  }).then((res) => {
    if (!res.data.err) {
      console.log(res.data.msg);
      handleUpdate(priceFactorValue);
    } else {
      console.log(res.data.msg);
    }
  });
}
function getPricingPolicy(customerId, setPriceFactor) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/customer/get/byField/priceFactor`,
    params: { id: customerId },
  }).then((res) => {
    if (!res.data.err) {
      console.log(res.data.msg);
      setPriceFactor(res.data.priceFactor);
    } else {
      console.log(res.data.msg);
    }
  });
}
const useStyles = makeStyles({
  root: {
    minWidth: 275,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  dividerMargin: {
    marginTop: 20,
    marginBottom: 20,
  },
  slider: {
    marginLeft: 20,
    width: "80%",
  },
});
function valuetext(value) {
  return `${value}%`;
}
const marks = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: -10,
    label: "-10%",
  },
  {
    value: 10,
    label: "10%",
  },
  {
    value: -5,
    label: "-5%",
  },
  {
    value: 5,
    label: "5%",
  },
];

function CustomerProfile(props) {
  const classes = useStyles();
  let priceFactorTimeout = React.useRef(setTimeout(() => {}, 0));
  const [priceFactorValue, setPriceFactor] = React.useState(0);
  const handleSliderChange = (event, newValue) => {
    setPriceFactor(newValue);
  };
  const handleUpdate = (priceFactorValue) => {
    props.onUpdate(priceFactorValue);
  };
  React.useEffect(() => {
    getPricingPolicy(props.customer.id, setPriceFactor);
  }, [props]);
  React.useEffect(() => {
    clearTimeout(priceFactorTimeout.current);
    priceFactorTimeout.current = setTimeout(() => {
      updatePricingPolicy(priceFactorValue, props.customer.id, handleUpdate);
    }, 1500);
  }, [priceFactorValue]);
  return (
    <Card className={classes.root} elevation={2}>
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={12} lg={9}>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {props.customer.group}
            </Typography>
            <Typography variant="h5" component="h2">
              {props.customer.name}
              <IconButton>
                <Edit
                  style={{ float: "right" }}
                  color="primary"
                  onClick={() => {
                    props.onEdit();
                  }}
                />
              </IconButton>
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              {props.customer.email}
            </Typography>
            <Typography variant="body2" component="p">
              Domyślny magazyn zamówień: {props.customer.magazyn}
            </Typography>
            <Typography variant="body2" component="p">
              Opiekun: {props.customer.operator?.firstName}{" "}
              {props.customer.operator?.surname}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={3}>
            <Typography variant="h6" component="p">
              Polityka cenowa:
            </Typography>
            <Typography id="discrete-slider" variant="body2" gutterBottom>
              {priceFactorValue > 0 && "Podwyżka: "}
              {priceFactorValue === 0 && "Podwyżka / rabat: "}
              {priceFactorValue < 0 && "Rabat: "}
              {priceFactorValue}%
            </Typography>
            <Slider
              className={classes.slider}
              defaultValue={30}
              getAriaValueText={valuetext}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              value={priceFactorValue}
              onChange={handleSliderChange}
              step={1}
              marks={marks}
              min={-10}
              max={10}
            />
            <Typography variant="body2">Kwota minimalna zamówienia:</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default CustomerProfile;
