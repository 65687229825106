import React from "react";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import { Table } from "@material-ui/core";
import moment from "moment";
import {
  ArrowLeft,
  BorderRight,
  CallReceived,
  CallSharp,
} from "@material-ui/icons";
import logo from "./logo.png";
import Noto from "../../assets/noto-serif.regular.ttf";
Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
});
Font.register({
  family: "Noto",
  format: "truetype",
  src: Noto,
});

// Create styles
const styles = StyleSheet.create({
  textStyleDescription: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontFamily: "Roboto",
    textAlign: "center",
    fontSize: "10",
  },
  textStyle: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontFamily: "Roboto",
    textAlign: "center",
    fontSize: "14",
  },
  textStyle1: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontFamily: "Noto",
    textAlign: "center",
    fontSize: "14",
  },
  textStyleLeft: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontFamily: "Roboto",
    textAlign: "left",
    fontSize: "14",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    margin: 10,
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  documentInformationCol: {
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderRight: 0,
    width: "25%",
  },
  documentInformationCol2: {
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderRight: 0,
    width: "25%",
  },
  documentInformationCol3: {
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderRight: 0,
    width: "20%",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  companyNameCol: {
    width: "48%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontFamily: "Noto",
    textAlign: "center",
    marginTop: "50",
    float: "left",
    marginLeft: "-10",
  },
  documentNumber: {
    width: "100",
    borderStyle: "solid",
    borderWidth: 1,

    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontFamily: "Roboto",
    textAlign: "center",
  },
  image: {
    objectFit: "cover",
    width: "150",
    border: 0,
    float: "left",
    marginLeft: "-10",
  },
  documentInformation: {
    // marginTop: "-20",
    flexDirection: "row",
    width: "auto",
  },
  documentInformation2: {
    marginTop: "10",
    flexDirection: "row",
    width: "auto",

    borderBottom: 1,
  },
  documentInformation3: {
    flexDirection: "row",
    width: "auto",
    marginTop: "10",
    border: 1,
    textAlign: "center",
    borderStyle: "solid",
  },
});

// Create Document Component
const MyDocument = ({ documents }) => (
  <Document>
    <Page style={styles.body}>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <View style={styles.image}>
              <Image style={styles.image} src={logo} alt="images" />
            </View>
          </View>
          <View style={styles.companyNameCol}>
            <Text style={styles.textStyle1}>ZLECENIE TRANSPORTU</Text>
            <br />
            <Text style={styles.textStyle1}> WŁASNEGO</Text>
            <br />
            <Text style={styles.textStyle1}>
              Bialpak Choromański, Leszczyński sp.j.
            </Text>
          </View>
          <View style={{ width: "20%", marginTop: "50", marginLeft: "40" }}>
            <Text style={styles.textStyle}>
              Nr: {"   " + documents.number.count + "/" + documents.number.year}
            </Text>
            <br />
            <br />
            <Text style={styles.textStyle}>
              Data Dostawy:{" "}
              {" " + moment(documents.deliveryDate).format("DD.MM.YYYY ")}
            </Text>
          </View>
        </View>
        <View style={styles.documentInformation}></View>

        <View style={styles.documentInformation2}>
          <View style={styles.documentInformationCol2}>
            <Text style={styles.textStyle}>
              {documents.employee.firstName + " " + documents.employee.surname}
            </Text>
            <Text style={styles.textStyleDescription}>
              Zlecenie przygotował
            </Text>
          </View>
          <View style={styles.documentInformationCol2}>
            <Text style={styles.textStyle}>
              {documents.driver.firstName + " " + documents.driver.surname}
            </Text>
            <Text style={styles.textStyleDescription}>Kierowca </Text>
          </View>

          <View style={styles.documentInformationCol2}>
            <Text style={styles.textStyle}>
              {documents.car.carBrand +
                " (" +
                documents.car.registrationNumber +
                " )"}
            </Text>
            <Text style={styles.textStyleDescription}>Samochód</Text>
          </View>
          <View style={styles.documentInformationCol}>
            <Text style={styles.textStyle}>{documents.loadingPlace}</Text>
            <Text style={styles.textStyleDescription}>Miejsce załadunku</Text>
          </View>
        </View>
        <View style={styles.documentInformation2}>
          <View style={styles.documentInformationCol2}>
            <Text style={styles.textStyle}> </Text>
            <Text style={styles.textStyleDescription}>
              Godziny rozpoczęcia pracy
            </Text>
          </View>
          <View style={styles.documentInformationCol2}>
            <Text style={styles.textStyle}> </Text>
            <Text style={styles.textStyleDescription}>Wybór Trasy </Text>
          </View>

          <View style={styles.documentInformationCol2}>
            <Text style={styles.textStyle}> {documents.car.kilometers} </Text>
            <Text style={styles.textStyleDescription}>Licznik Kilometrów</Text>
          </View>
          <View style={styles.documentInformationCol}>
            <Text style={styles.textStyle}> </Text>
            <Text style={styles.textStyleDescription}>
              Tankowanie ON (ilość l)
            </Text>
          </View>
        </View>
        <View style={styles.documentInformation2}>
          <View style={styles.documentInformationCol3}>
            <Text style={styles.textStyle}> </Text>
            <Text style={styles.textStyleDescription}>Palety załadowane</Text>
          </View>
          <View style={styles.documentInformationCol3}>
            <Text style={styles.textStyle}> </Text>
            <Text style={styles.textStyleDescription}>Przemysłowe </Text>
          </View>

          <View style={styles.documentInformationCol3}>
            <Text style={styles.textStyle}> </Text>
            <Text style={styles.textStyleDescription}>Europodobne</Text>
          </View>
          <View style={styles.documentInformationCol3}>
            <Text style={styles.textStyle}> </Text>
            <Text style={styles.textStyleDescription}>Palety zwrócone</Text>
          </View>
          <View style={styles.documentInformationCol3}>
            <Text style={styles.textStyle}> </Text>
            <Text style={styles.textStyleDescription}>Łącznie palet</Text>
          </View>
        </View>
        <View style={styles.documentInformation3}>
          <View
            style={{
              borderRight: 1,
              width: "15%",
              textAlign: "center",
            }}
          >
            <Text style={styles.textStyle}>Data FV</Text>
          </View>
          <View
            style={{
              borderRight: 1,
              width: "20%",
              textAlign: "center",
            }}
          >
            <Text style={styles.textStyle}> NR WZ / FV</Text>
          </View>

          <View
            style={{
              borderRight: 1,
              width: "25%",
              textAlign: "center",
            }}
          >
            <Text style={styles.textStyle}> Odbiorca </Text>
          </View>
          <View
            style={{
              borderRight: 1,
              width: "10%",
              textAlign: "center",
            }}
          >
            <Text style={styles.textStyle}> </Text>
            <Text style={styles.textStyleDescription}>Masa</Text>
          </View>
          <View
            style={{
              borderRight: 1,
              width: "20%",
              textAlign: "center",
            }}
          >
            <Text style={styles.textStyle}> </Text>
            <Text style={styles.textStyleDescription}>Pobranie Gotówki</Text>
          </View>
          <View
            style={{
              width: "20%",
              textAlign: "center",
            }}
          >
            <Text style={styles.textStyle}> Uwagi </Text>
          </View>
        </View>
        <View style={styles.documentInformation2}>
          <View
            style={{
              width: "15%",
              textAlign: "center",
            }}
          >
            <Text style={styles.textStyle}>01.01.2021</Text>
          </View>
          <View
            style={{
              width: "20%",
              textAlign: "center",
            }}
          >
            <Text style={styles.textStyle}> 1 / 2021</Text>
          </View>

          <View
            style={{
              width: "25%",
              textAlign: "center",
            }}
          >
            <Text style={styles.textStyle}> Aleksander Somla </Text>
          </View>
          <View
            style={{
              width: "10%",
              textAlign: "center",
            }}
          >
            <Text style={styles.textStyle}>100</Text>
          </View>
          <View
            style={{
              width: "20%",
              textAlign: "center",
            }}
          >
            <Text style={styles.textStyle}>100 </Text>
          </View>
          <View
            style={{
              width: "20%",
              textAlign: "center",
            }}
          >
            <Text style={styles.textStyle}> brak </Text>
          </View>
        </View>
        <View style={styles.documentInformation2}></View>
        <View style={styles.documentInformation2}></View>
        <View style={styles.documentInformation2}></View>
        <View style={styles.documentInformation2}></View>
        <View style={styles.documentInformation2}>
          <View style={styles.documentInformationCol3}>
            <Text style={styles.textStyle}>Rozliczenie Gotówki:</Text>
          </View>
          <View style={styles.documentInformationCol3}>
            <Text style={styles.textStyle}> </Text>
            <Text style={styles.textStyleDescription}>Data </Text>
          </View>

          <View style={styles.documentInformationCol3}>
            <Text style={styles.textStyle}> </Text>
            <Text style={styles.textStyleDescription}>Kwota</Text>
          </View>
          <View style={styles.documentInformationCol3}>
            <Text style={styles.textStyle}> </Text>
            <Text style={styles.textStyleDescription}>Przyjał</Text>
          </View>
          <View style={styles.documentInformationCol3}>
            <Text style={styles.textStyle}> </Text>
            <Text style={styles.textStyleDescription}>Podpis</Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);
export default MyDocument;
