import { DataGrid } from "@material-ui/data-grid";
import { Axios } from ".././middleware/axiosInstance";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import CategoryPicker from "../sales_settings/CategoryPicker";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import CustomersList from "./CustomersList";
import Alert from "@material-ui/lab/Alert";
import CustomerProfile from "./CustomerProfile";
import CustomerGrid from "./CustomerGrid";
import CustomerDrawer from "./CustomerDrawer";
import { AccountBox, Person, Save } from "@material-ui/icons";
import CustomerOrdersTable from "./CustomerOrdersTable";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];

function getAllCustomers(hook, customer) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/customer/get/all`,
  }).then((res) => {
    if (!res.data.err) {
      let dataSort = res.data.data.sort((a, b) => (a.name > b.name ? 1 : -1));
      hook.setState({ customers: dataSort });
      let customersIds = res.data.data.map((prod) => {
        return [prod.clientIdSubiekt];
      });
      hook.setState({ allCustomersIds: customersIds });
      if (customer) {
        hook.setState({ customerId: customer.id });
        hook.setState({ customer: customer });
      }
    } else {
      console.log(res.data.msg);
    }
  });
}
function getCategories(hook) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/product_categories/get/all/tree`,
  }).then((res) => {
    if (!res.data.err) {
      let data = res.data.data.tree.sort((a, b) =>
        a.label > b.label ? 1 : -1
      );
      //assignObjectPaths(data);
      hook.setState({ categories: data });
      hook.setState({ flatCategories: res.data.data.flat });
    } else {
      console.log(res.data.msg);
    }
  });
}
function getChildCategories(hook, id) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/product_categories/get/tree/ids`,
    params: { id: id },
  }).then((res) => {
    if (!res.data.err) {
      if (res.data.data.length > 0) {
        getProducts(hook, [...res.data.data[0].categoryHierarchyIds, id]);
      } else {
        getProducts(hook, [id]);
      }
    } else {
      console.log(res.data.msg);
    }
  });
}
function getProducts(hook, ids) {
  hook.setState({ loading: true });
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/product/get/byCategory/categoryHierarchy/true`,
    params: { ids: ids },
  }).then((res) => {
    if (!res.data.err) {
      let dataSort = res.data.data.sort((a, b) => (a.name < b.name ? 1 : -1));
      hook.setState({ products: dataSort });
      hook.setState({ loading: false });
    } else {
      console.log(res.data.msg);
    }
  });
}
function addCustomer(hook, customer) {
  console.log(customer);
  Axios({
    method: "POST",
    withCredentials: true,
    url: `${API_HOST}/customer/add`,
    data: customer,
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ successMsg: res.data.msg });
      hook.setState({ openDrawer: false });
      getAllCustomers(hook, res.data.customer);
    } else {
      console.log(res.data.msg);
    }
  });
}

function editCustomer(hook, customer) {
  console.log(customer);
  Axios({
    method: "PUT",
    withCredentials: true,
    url: `${API_HOST}/customer/update`,
    params: customer,
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ customerId: res.data.customer.id });
      hook.setState({ customer: res.data.customer });
      hook.setState({ successMsg: res.data.msg });
      hook.setState({ openDrawer: false });
      getAllCustomers(hook);
    } else {
      console.log(res.data.msg);
    }
  });
}
function saveProducts(hook) {
  //hook.setState({ loading: true });
  let newProducts = [];
  hook.state.currentProductsModel.forEach((prod) => {
    console.log(prod)
    newProducts.push({
      isExact: prod.isExact,
      exactPrice: prod.exactPrice,
      clientId: hook.state.customerId,
      productId: prod.productId,
      isPromotion: prod.isPromotion,
      limits: prod.limits,
    });
  });
  Axios({
    method: "POST",
    withCredentials: true,
    url: `${API_HOST}/customer/products/set`,
    data: { products: newProducts, customerId: hook.state.customerId },
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ successMsg: res.data.msg });
    } else {
      console.log(res.data.msg);
    }
  });
}
function customerColumns(hook) {
  return [
    {
      id: "name",
      label: "Nazwa",
      numeric: false,
      disablePadding: true,
      width: 700,
    },
    {
      id: "magazyn",
      label: "Magazyn",
      numeric: false,
      disablePadding: false,
      width: 300,
    },
    {
      id: "edit",
      label: "Edycja",
      numeric: false,
      disablePadding: false,
      width: 300,
    },
  ];
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function customersData(customers) {
  let tab = [];
  customers.forEach((customer) => {
    if (customer.magazynId === 1) {
      customer.magazyn = "Biała Podlaska";
    } else if (customer.magazynId === 2) {
      customer.magazyn = "Siedlce";
    }
    if (customer.groupId === 0) {
      customer.group = "Rolnictwo";
    } else if (customer.groupId === 1) {
      customer.group = "Przemysł";
    } else if (customer.groupId === 2) {
      customer.group = "Detal";
    }
    tab.push(customer);
  });
  return tab;
}
const styles = (theme) => ({
  tabsRoot: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: "10px",
    border: "solid 1px #cecece",
  },
  shadow: {
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  formControl: {
    margin: theme.spacing(1),

    minWidth: 120,
  },
  inputBaseHeight: {
    height: 35,
  },
  productPadding: {
    padding: theme.spacing(0),
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  placeholder: {
    height: 1000,
    backgroundColor: "#dbdcdd",
    display: "flex",
  },
  placeholderIcon: {
    fontSize: 150,
    color: "#9d9696",
    margin: "auto auto",
  },
  productsHeader: {
    height: 56,
    marginBottom: 15,
  },
  addButton: {
    float: "right",
    color: "white",
  },
});

class SalesSettingsGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      products: [],
      flatCategories: [],
      categories: [],
      customer: {},
      customerEdit: { id: 0 },
      customerId: 0,
      successMsg: false,
      filterColumn: "name",
      filterValue: "",
      loading: false,
      allCustomersIds: [],
      selectedCategoryId: null,
      currentProductsModel: [],
      openDrawer: false,
      customerEditMode: false,
      currentProductsModelCount: 12,
      tabValue: 0,
      customerPriceFactor: 0,
    };
  }

  //const [value, setValue] = React.useState(0);

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  handleTabChangeIndex = (index) => {
    this.setState({ tabValue: index });
  };

  componentDidMount() {
    getAllCustomers(this);
    //getAllProducts(this);
    getCategories(this);
  }

  handleSaveProducts() {
    saveProducts(this);
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div style={{ minHeight: 1800 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={3}>
            <h2>Klienci</h2>
          </Grid>
          <Grid item xs={12} md={12} lg={9}>
            <Button
              variant="contained"
              color="primary"
              className={classes.addButton}
              onClick={() => {
                this.setState({ openDrawer: true });
                this.setState({ customerEditMode: false });
                this.setState({ customerEdit: { id: 0 } });
              }}
            >
              DODAJ
            </Button>
          </Grid>
          <Grid item xs={12} md={12} lg={3}>
            <CustomersList
              customers={customersData(this.state.customers)}
              onCustomerChoose={(customer) => {
                this.setState({ customer: customer });
                this.setState({ customerId: customer.id });
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={9}>
            {this.state.customerId !== 0 && (
              <div>
                <CustomerProfile
                  customer={this.state.customer}
                  productsCount={this.state.currentProductsModelCount}
                  onEdit={() => {
                    this.setState({ openDrawer: true });
                    this.setState({ customerEditMode: true });
                    let customer = this.state.customer;
                    this.setState({ customerEdit: customer });
                  }}
                  onUpdate={(priceFactor) => {
                    getAllCustomers(this);
                    this.setState({ customerPriceFactor: priceFactor });
                  }}
                />
                <Paper className={classes.productPadding}>
                  <div className={classes.tabsRoot}>
                    <AppBar position="static" color="default" elevation={1}>
                      <Tabs
                        value={this.state.tabValue}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                      >
                        <Tab label="Przydział produktów" {...a11yProps(0)} />
                        <Tab label="Złożone zamówienia" {...a11yProps(1)} />
                        <Tab label="Statystyki" {...a11yProps(2)} />
                      </Tabs>
                    </AppBar>
                    <SwipeableViews
                      axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                      index={this.state.tabValue}
                      onChangeIndex={this.handleTabChangeIndex}
                    >
                      <TabPanel
                        value={this.state.tabValue}
                        index={0}
                        dir={theme.direction}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12} lg={3}>
                            <Grid item xs={12} md={12} lg={12}>
                              {/* <Typography variant="h6">
                          Przydzielonych produktów:{" "}
                          {this.state.currentProductsModel.length}
                        </Typography> */}
                              <div className={classes.productsHeader}>
                                <Button
                                  startIcon={<Save />}
                                  variant="outlined"
                                  style={{ width: "100%", height: "100%" }}
                                  color="primary"
                                  onClick={() => {
                                    this.handleSaveProducts();
                                  }}
                                >
                                  Zapisz zmiany
                                </Button>
                              </div>
                            </Grid>
                            <CategoryPicker
                              data={{
                                categoriesTree: this.state.categories,
                                flatCategories: this.state.flatCategories,
                              }}
                              searchBar={true}
                              maxHeight={900}
                              editMode={false}
                              pickMode={true}
                              selected={0}
                              onSelect={(selected) => {
                                if (selected === "0") {
                                  //getAllProducts(this);
                                } else {
                                  this.setState({
                                    selectedCategoryId: selected,
                                  });
                                  //getChildCategories(this, selected);
                                }
                              }}
                            />
                            {/* </Paper> */}
                          </Grid>
                          <Grid item xs={12} md={12} lg={9}>
                            <div className={classes.productsHeader}>
                              <Paper
                                elevation={0}
                                component="form"
                                className={classes.root}
                              >
                                <InputBase
                                  className={classes.input}
                                  placeholder="Wyszukaj produkt"
                                  inputProps={{
                                    "aria-label": "wyszukaj produkt",
                                  }}
                                  onChange={(event) => {
                                    setTimeout(() => {
                                      this.setState({
                                        filterValue: event.target.value,
                                      });
                                    }, 200);
                                  }}
                                />
                                <Divider
                                  className={classes.divider}
                                  orientation="vertical"
                                />
                                <FormControl
                                  className={classes.formControl}
                                  variant="outlined"
                                >
                                  <InputLabel htmlFor="my-column">
                                    Kolumna
                                  </InputLabel>
                                  <Select
                                    value={this.state.filterColumn}
                                    onChange={(event) => {
                                      this.setState({
                                        filterColumn: event.target.value,
                                      });
                                    }}
                                    inputProps={{
                                      name: "kolumna",
                                      id: "my-column",
                                    }}
                                    label="Kolumna"
                                    className={classes.inputBaseHeight}
                                  >
                                    <MenuItem value={"name"}>Nazwa</MenuItem>
                                    <MenuItem value={"symbol"}>Symbol</MenuItem>
                                  </Select>
                                </FormControl>
                              </Paper>
                            </div>

                            <CustomerGrid
                              customerId={this.state.customerId}
                              customerPriceFactor={
                                this.state.customerPriceFactor
                              }
                              categoryId={this.state.selectedCategoryId}
                              loading={this.state.loading}
                              filterColumn={this.state.filterColumn}
                              filterValue={this.state.filterValue}
                              onProductModelChange={(model) => {
                                this.setState({ currentProductsModel: model });
                                this.setState({
                                  currentProductsModelCount: model.length,
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </TabPanel>
                      <TabPanel
                        value={this.state.tabValue}
                        index={1}
                        dir={theme.direction}
                      >
                        <CustomerOrdersTable
                          customerId={this.state.customerId}
                        />
                      </TabPanel>
                      <TabPanel
                        value={this.state.tabValue}
                        index={2}
                        dir={theme.direction}
                      >
                        Item Three
                      </TabPanel>
                    </SwipeableViews>
                  </div>
                </Paper>
              </div>
            )}
            {this.state.customerId === 0 && (
              <Paper className={classes.placeholder}>
                <Person className={classes.placeholderIcon}></Person>
              </Paper>
            )}
          </Grid>
        </Grid>
        <CustomerDrawer
          open={this.state.openDrawer}
          edit={this.state.customerEditMode}
          data={{
            //cecha: cecha,
            //categories: this.state.categories,
            //flatCategories: this.state.flatCategories,
            customersIds: this.state.allCustomersIds,
            customer: this.state.customerEdit,
          }}
          onClose={() => {
            this.setState({ openDrawer: false });
          }}
          onSave={(customer, edit) => {
            if (edit) {
              editCustomer(this, customer);
            } else {
              addCustomer(this, customer);
            }
          }}
        />
        <Snackbar
          open={Boolean(this.state.successMsg)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={6000}
          onClose={() => {
            this.setState({ successMsg: false });
          }}
        >
          <Alert
            onClose={() => {
              this.setState({ successMsg: false });
            }}
            severity="success"
            variant="filled"
          >
            {this.state.successMsg}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SalesSettingsGrid);
