import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import { Axios } from ".././middleware/axiosInstance";
import validator from "validator";
import Alert from "@material-ui/lab/Alert";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import EmailPicker from "../admin/EmailPicker";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];

function saveData(
  id,
  firstName,
  surname,
  phone,
  email,
  employeeTypes,
  employeeAccess,
  employeeAdditionalEmails,
  login,
  password,
  close,
  updateData,
  setErrorMessage
) {
  let employee = {};
  employee._id = id;
  employee.firstName = firstName;
  employee.surname = surname;
  employee.phone = phone;
  employee.password = password;
  employee.email = email;
  employee.typeUser = employeeTypes;
  employee.accessUser = employeeAccess;
  employee.accessUser = employeeAccess;
  employee.additionalEmails = employeeAdditionalEmails;
  console.log(employee);
  Axios({
    method: "PUT",
    withCredentials: true,
    url: `${API_HOST}/employee/update`,
    params: employee,
  }).then((res) => {
    updateData();
    close();
  });
}
function notEmpty(
  id,
  firstName,
  surname,
  phone,
  email,
  employeeTypes,
  employeeAccess,
  employeeAdditionalEmails,
  login,
  password,
  close,
  updateData,
  setErrorMessage,
  setError,
  addPassword
) {
  if (addPassword) {
    if (
      !firstName ||
      !surname ||
      !phone ||
      !email ||
      !employeeTypes ||
      !employeeAdditionalEmails ||
      !password
    ) {
      setError(true);
      setErrorMessage("Wszystkie pola muszą być wypełnione!");
    } else {
      setError(false);
      setErrorMessage(null);
      saveData(
        id,
        firstName,
        surname,
        phone,
        email,
        employeeTypes,
        employeeAccess,
        employeeAdditionalEmails,
        login,
        password,
        close,
        updateData
      );
    }
  } else {
    if (!firstName || !surname || !phone || !email || !employeeTypes) {
      setError(true);
      setErrorMessage("Wszystkie pola muszą być wypełnione!");
    } else {
      setError(false);
      setErrorMessage(null);
      saveData(
        id,
        firstName,
        surname,
        phone,
        email,
        employeeTypes,
        employeeAccess,
        employeeAdditionalEmails,
        login,
        password,
        close,
        updateData
      );
    }
  }
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: 20,
  },
  marginRight: {
    marginRight: 20,
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({
  open,
  onClose = (f) => f,
  updateData = (f) => f,
  types,
  access,
  employees,
  additionalEmails,
}) {
  const [state, setState] = React.useState({ checkedB: true });

  const [login, setLogin] = React.useState();
  const [phone, setPhone] = React.useState();
  const [firstName, setFirstName] = React.useState();
  const [surname, setSurname] = React.useState();
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [addPassword, setaddPassword] = React.useState(false);
  const [employeeTypes, setEmployeeTypes] = React.useState();
  const [employeeAccess, setEmployeeAccess] = React.useState();
  const [employeeAdditionalEmails, setEmployeeAdditionalEmails] =
    React.useState();
  const [email, setEmail] = React.useState();
  const [passwordCorrect, setPasswordCorrect] = React.useState(false);
  const [repeatpasswordCorrect, setRepeatPasswordCorrect] =
    React.useState(false);
  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    validate(event.target.value);
  };
  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };
  const handleSurnameChange = (event) => {
    setSurname(event.target.value);
  };
  const handleEmployeeTypeChange = (value) => {
    if (value) {
      setEmployeeTypes(value);
    }
  };

  const handleEmailChange = (event) => {
    let emailValue = event.target.value;
    validateEmail(emailValue);
    setEmail(emailValue);
  };
  const handleAddPasswordChange = (event) => {
    setaddPassword(event.target.checked);
  };

  const validateEmail = (value) => {
    var email = value;

    if (validator.isEmail(email)) {
      setError(false);
    } else {
      setError(true);
      setErrorMessage("Nie poprawny adres email!");
    }
  };

  const handleRepeatPasswordChange = (event) => {
    let passwordRepeat = event.target.value;
    if (password === passwordRepeat) {
      setError(false);
      setErrorMessage(null);
      setRepeatPasswordCorrect(true);
    } else {
      setError(true);
      setErrorMessage("Hasła nie są takie same!");
      setRepeatPasswordCorrect(false);
    }
  };
  const validate = (value) => {
    if (value.length == "") {
      setErrorMessage("");
      setPasswordCorrect(true);
    } else if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setError(false);
      setErrorMessage(null);
      setPasswordCorrect(true);
    } else {
      setError(true);
      setErrorMessage(
        "Hasło nie jest wystarczająco silne. Minimum 8 znaków, minimum jedna cyfra, znak specjalny, mała oraz duża litera."
      );
      setPasswordCorrect(false);
    }
  };

  React.useEffect(() => {
    setFirstName(employees.firstName);
    setSurname(employees.surname);
    setEmployeeTypes(employees.typeUser);
    setEmployeeAccess(employees.accessUser);
    setEmployeeAdditionalEmails(employees.additionalEmails);
    setLogin(employees.username);
    setPhone(employees.phone);
    setEmail(employees.email);
    setPassword("");
    setErrorMessage(null);
    setError(false);
    setaddPassword(false);
  }, [open, employees]);
  const classes = useStyles();
  return (
    <div>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          Edytuj Pracownika:
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            id="firstName"
            label="Imię:"
            className={(classes.marginBottom, classes.marginRight)}
            onChange={handleFirstNameChange}
            style={{ width: "48%" }}
            defaultValue={firstName}
          />
          <TextField
            id="surname"
            label="Nazwisko:"
            className={classes.marginBottom}
            onChange={handleSurnameChange}
            style={{ width: "48%" }}
            defaultValue={surname}
          />
          <TextField
            required
            fullWidth
            name="email"
            label="Email"
            type="text"
            id="email"
            defaultValue={email}
            className={classes.marginBottom}
            onChange={handleEmailChange}
          />
          <Autocomplete
            onChange={(event, value) => handleEmployeeTypeChange(value)}
            options={types}
            defaultValue={employeeTypes}
            getOptionLabel={(option) => option}
            className={classes.marginBottom}
            fullWidth={true}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Stanowisko: "
                placeholder={types}
                value={types}
              />
            )}
          />
          <Autocomplete
            multiple
            id="access-tags"
            options={access}
            onChange={(event, value) => setEmployeeAccess(value)}
            disableCloseOnSelect
            defaultValue={employeeAccess}
            getOptionLabel={(option) => option}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </React.Fragment>
            )}
            style={{ width: "100%", marginBottom: 10 }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Dostęp"
                placeholder="Dostęp"
              />
            )}
          />
          <Autocomplete
            multiple
            id="additionalEmails-tags"
            options={additionalEmails}
            onChange={(event, value) => setEmployeeAdditionalEmails(value)}
            disableCloseOnSelect
            defaultValue={employeeAdditionalEmails}
            getOptionLabel={(option) => option}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </React.Fragment>
            )}
            style={{ width: "100%", marginBottom: 10 }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Emaile dodatkowe"
                placeholder="Emaile dodatkowe"
              />
            )}
          />
          <TextField
            id="loginEmployee"
            label="Login: "
            type="text"
            fullWidth={true}
            className={classes.marginBottom}
            onChange={handlePasswordChange}
            disabled
            defaultValue={login}
          />
          <TextField
            id="phone"
            label="Numer Telefonu: "
            type="phone"
            fullWidth={true}
            className={classes.marginBottom}
            onChange={handlePhoneChange}
            defaultValue={phone}
          />
          <FormControlLabel
            value="addPassoword"
            control={<Checkbox color="primary" />}
            label="Czy chcesz zmienić hasło?"
            labelPlacement="right"
            onChange={handleAddPasswordChange}
          />
          {addPassword && (
            <TextField
              id="clientPassword"
              label="Hasło"
              type="password"
              fullWidth={true}
              className={classes.marginBottom}
              onChange={handlePasswordChange}
            />
          )}
          {addPassword && (
            <TextField
              required
              fullWidth
              name="repeatpassword"
              label="Powtórz Hasło:"
              type="password"
              id="repeatedPassword"
              className={classes.marginBottom}
              onChange={handleRepeatPasswordChange}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            autoFocus
            className={(classes.marginBottom, classes.marginRight)}
            onClick={() => {
              notEmpty(
                employees._id,
                firstName,
                surname,
                phone,
                email,
                employeeTypes,
                employeeAccess,
                employeeAdditionalEmails,
                login,
                password,
                onClose,
                updateData,
                setErrorMessage,
                setError,
                addPassword
              );
            }}
            color="primary"
          >
            Zapisz
          </Button>
        </DialogActions>
        {error && <Alert severity="error">{errorMessage}</Alert>}
      </Dialog>
    </div>
  );
}
