import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    Divider,
    Drawer,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography,
    useTheme,
} from "@material-ui/core";
import { DataGrid } from '@mui/x-data-grid';

const columns = [
    {
        field: 'count',
        headerName: 'Ilość',
        type: 'number',
        width: 85,
        editable: true,
    },
    {
        field: 'unit',
        headerName: 'Jedn.',
        width: 100,
        editable: true,
    },
    {
        field: 'baseUnit',
        headerName: 'Jedn. bazowa',
        width: 100,
        editable: true,
    },
    {
        field: 'default',
        headerName: 'Główna',
        type: 'boolean',
        width: 100,
        editable: true,
    },
    {
        field: 'blocked',
        headerName: 'Zablokowana',
        type: 'boolean',
        width: 100,
        editable: true,
    },

];
const getNextId = (measurements) => {
    let highestId = 0;
    measurements.forEach((measure) => {
        if (measure.id > highestId) {
            highestId = measure.id
        }
    })
    return highestId + 1
}


export default function ProductMeasurements({ units, measurements, setMeasurements }) {
    const theme = useTheme()
    const [innerMeasures, setInnerMeasures] = useState([{ id: 0, count: 1, unit: 'jedn.', baseUnit: 'jedn. baz' }])
    const [editRowsModel, setEditRowsModel] = useState({});

    const handleEditRowsModelChange = ((newModel) => {
        let changed = false
        if (newModel) {
            let id = Object.keys(newModel)[0]
            console.log(id)


            if (id) {
                let key = Object.keys(newModel[id])[0]
                console.log(key)
                console.log()
                let newMeasures = innerMeasures.map((m) => {
                    if (m.id === Number(id)) {
                        changed = true
                        console.log(newModel[id][key].value)
                        m[key] = newModel[id][key].value
                        return m
                    }
                    else return m
                })
                if (changed) {
                    setInnerMeasures(newMeasures)
                    setMeasurements(newMeasures)
                }
            }
        }
        setEditRowsModel(newModel);
    });

    const handleAddMeasurement = () => {
        let measure = { id: getNextId(innerMeasures), count: 1, unit: 'jedn.', baseUnit: 'jedn. baz' }
        let rows = [...innerMeasures, measure]
        let deepCopyRows = JSON.stringify(rows)
        setMeasurements(JSON.parse(deepCopyRows))
        setInnerMeasures(JSON.parse(deepCopyRows));
    };

    const handleRemoveMeasurement = () => {
        setInnerMeasures((prevRows) => {
            const rowToDeleteIndex = prevRows.length - 1;
            const rows = [
                ...innerMeasures.slice(0, rowToDeleteIndex),
                ...innerMeasures.slice(rowToDeleteIndex + 1),
            ];
            setMeasurements(rows)
            return rows
        });
    };

    useEffect(() => {
        if (measurements) {
            setInnerMeasures(measurements)
        }

        return () => {
            setInnerMeasures([])
        }
    }, [measurements, setInnerMeasures])


    return (
        <div style={{ display: 'flex', height: '100%' }}>

            <div style={{ flexGrow: 1 }}>
                <Typography style={{ marginBottom: 10 }}>Jednostki miary: </Typography>
                {/* {JSON.stringify(innerMeasures)} */}
                <Button variant='outlined' onClick={() => {
                    handleAddMeasurement()
                }}>Dodaj</Button>
                <Button variant='outlined' onClick={() => {
                    handleRemoveMeasurement()
                }}>Usuń</Button>
                <DataGrid
                    style={{ color: theme.palette.type === "dark" ? "white" : "black" }}
                    autoHeight
                    hideFooter={true}
                    density='compact'
                    rows={innerMeasures}
                    columns={columns}
                    pageSize={innerMeasures.length}
                    rowsPerPageOptions={innerMeasures.length}
                    checkboxSelection={false}
                    disableSelectionOnClick
                    //editMode="row"
                    editRowsModel={editRowsModel}
                    onEditRowsModelChange={handleEditRowsModelChange}
                //processRowUpdate={processRowUpdate}
                />
                </div>
        </div>
    )
}
