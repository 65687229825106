import React from "react";
import { Axios } from ".././middleware/axiosInstance";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import { RenderCellExpand } from "./renderCellExpand";
import moment from "moment";
import {
  AccessTime,
  Cancel,
  PersonAdd,
  PersonAddDisabled,
  Place,
  Undo,
} from "@material-ui/icons";
import { Button, Tooltip } from "@material-ui/core";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];

function getChangeLogs(setChangelogs) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/changelog/get/all`,
  }).then((res) => {
    if (!res.data.err) {
      setChangelogs(res.data.data);
    } else {
      console.log(res.data.msg);
    }
  });
}
function deleteLog(setChangelogs, data) {
  Axios({
    method: "DELETE",
    withCredentials: true,
    url: `${API_HOST}/changelog/delete`,
    params: { _id: data },
  }).then((res) => {
    if (!res.data.err) {
      getChangeLogs(setChangelogs);
    } else {
      console.log(res.data.msg);
    }
  });
}
function undoChange(setChangelogs, data) {
  Axios({
    method: "POST",
    withCredentials: true,
    url: `${API_HOST}/changelog/undo`,
    data: { data: data },
  }).then((res) => {
    if (!res.data.err) {
      getChangeLogs(setChangelogs);
    } else {
      console.log(res.data.msg);
    }
  });
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  marginBottom: {
    marginBottom: 20,
  },
  marginRight: {
    marginRight: 20,
  },
  marginLeft: {
    marginLeft: 70,
  },
  inviteContainer: {
    lineHeight: "24px",
    height: 120,
    padding: 4,
    borderLeft: "4px solid rgb(0, 139, 236)",
    borderRight: "4px solid rgb(0, 139, 236)",
    width: "100%",
    backgroundColor: "rgb(128, 197, 246)",
  },
  inviteDate: {
    fontSize: 14,
    color: "#252525",
    float: "left",
    padding: 2,
    width: "100%",
  },
  inviteTime: {
    fontSize: 20,
    color: "#252525",
    float: "left",
    paddingLeft: 2,
    width: "100%",
  },
  inviteRamp: {
    fontSize: 14,
    color: "#252525",
    float: "left",
    paddingLeft: 2,
  },
  inviteIndentTop: {
    position: "absolute",
    transform: "translate(0px, -50%)",
    borderRadius: 10,
    zIndex: 2,
    width: 10,
    height: 10,
    backgroundColor: "white",
    left: 60,
    top: 0,
  },
  inviteIndentBottom: {
    position: "absolute",
    transform: "translate(0px, 50%)",
    borderRadius: 10,
    zIndex: 2,
    width: 10,
    height: 10,
    backgroundColor: "white",
    left: 60,
    bottom: 0,
  },
  leftContainer: {
    position: "absolute",
    height: "100%",
    width: 66,
    top: 0,
    left: 0,
    float: "left",
    backgroundColor: "#95d3ff",
    zIndex: 1,
  },
  icon: {
    color: "#252525",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "45%",
  },
  width100FL: {
    width: "100%",
    float: "left",
  },
  title: {
    width: "100%",
    paddingLeft: "11%",
  },
  appbar: {
    backgroundColor: "#ff6634",
    paddingLeft: "11%",
    paddingRight: "11%",
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  colorWhite: {
    color: "white",
  },
  confirmButton: {
    marginLeft: "12%",
    backgroundColor: "#ff6634",
  },
  changeColor: {
    backgroundColor: "#ff373766",
  },
}));

function logColumns(classes, setChangelogs) {
  return [
    {
      field: "createdAt",
      headerName: "Data zmiany",
      width: 150,
      type: "string",
      sortable: true,
      valueFormatter: (params) => {
        return moment(params.value).format("DD/MM/YYYY HH:mm");
      },
      renderCell: (params) => {
        params.value = moment(params.value).format("DD/MM/YYYY HH:mm");
        return RenderCellExpand(params);
      },
    },
    {
      field: "prevWindow.id",
      headerName: "Id okna",
      width: 50,
      type: "string",
      sortable: true,
      renderCell: (params) => {
        let window = params.row.prevWindow;
        if (!window) {
          window = params.row.nextWindow;
        }
        return `#${window.id}`;
      },
    },
    {
      field: "prevWindow.start",
      headerName: "Data okna",
      width: 150,
      type: "string",
      sortable: true,
      renderCell: (params) => {
        let window = params.row.prevWindow;
        if (!window) {
          window = params.row.nextWindow;
        }
        params.value = moment(window.start).format("DD/MM/YYYY");
        return RenderCellExpand(params);
      },
    },
    {
      field: "action",
      headerName: "Zmiana",
      width: 100,
      type: "string",
      sortable: true,
      renderCell: (params) => {
        const action = params.row.action;
        if (action === "hours_change") {
          return (
            <Tooltip title="Zmiana godzin">
              <AccessTime></AccessTime>
            </Tooltip>
          );
        } else if (action === "client_add") {
          return (
            <Tooltip title="Dodanie klienta">
              <PersonAdd></PersonAdd>
            </Tooltip>
          );
        } else if (action === "client_change") {
          return (
            <Tooltip title="Zmiana klienta">
              <PersonAddDisabled></PersonAddDisabled>
            </Tooltip>
          );
        } else if (action === "resource_change") {
          return (
            <Tooltip title="Zmiana zasobu">
              <Place></Place>
            </Tooltip>
          );
        } else if (action === "remove") {
          return (
            <Tooltip title="Usunięcie">
              <Cancel></Cancel>
            </Tooltip>
          );
        } else if (action === "undo") {
          return (
            <Tooltip title="Przywrócenie">
              <Undo></Undo>
            </Tooltip>
          );
        }
      },
    },
    {
      field: "user",
      headerName: "Użytkownik",
      width: 130,
      type: "string",
      sortable: true,
      renderCell: RenderCellExpand,
    },
    {
      field: "prevWindow",
      headerName: "Przed",
      width: 250,
      type: "string",
      sortable: true,
      renderCell: (params) => {
        const window = params.row.prevWindow;
        let client, start, end, date;
        if (window) {
          const action = params.row.action;
          start = moment(window.start).format("HH:mm");
          end = moment(window.end).format("HH:mm");
          date = moment(window.start).format("DD-MM-YYYY");
          if (!window.clientId) {
            client = "Brak";
          } else if (action === "undo") {
          } else {
            client = params.row.prevClient?.name;
          }
        }
        return (
          <div>
            {window && (
              <div className={classes.inviteContainer}>
                <span className={classes.inviteDate}>{date}</span>
                <span
                  className={classes.inviteTime}
                >{`${start} - ${end}`}</span>
                <span
                  className={classes.inviteRamp}
                >{`Lokalizacja: ${params.row.prevResource?.name}`}</span>
                <span
                  className={classes.inviteRamp}
                >{`Dostawca: ${client}`}</span>
              </div>
            )}
            {!window && <div>{"---"}</div>}
          </div>
        );
      },
    },
    {
      field: "nextWindow",
      headerName: "Po",
      width: 250,
      type: "string",
      sortable: true,
      renderCell: (params) => {
        const changes = params.row.changes;
        const action = params.row.action;
        let start, end, date, resource, client;
        if (action !== "remove") {
          const window = params.row.nextWindow;
          const prevWindow = params.row.prevWindow;
          start = moment(window.start).format("HH:mm");
          end = moment(window.end).format("HH:mm");
          date = moment(window.start).format("DD-MM-YYYY");
          if (!changes.resourceId) {
            resource = params.row.prevResource?.name;
          } else if (changes.resourceId === prevWindow?.resourceId) {
            resource = params.row.prevResource?.name;
          } else {
            resource = params.row.nextResource?.name;
          }
          if (!window.clientId) {
            client = "Brak";
          } else if (action === "client_change") {
            client = params.row.nextClient.name;
          } else if (action === "client_add") {
            client = params.row.nextClient.name;
          } else if (action === "undo") {
            client = params.row.nextClient.name;
          } else {
            client = params.row.prevClient.name;
          }
        }
        return (
          <div>
            {action !== "remove" && (
              <div className={classes.inviteContainer}>
                <span className={classes.inviteDate}>{date}</span>
                <span className={classes.inviteTime}>
                  {changes.start && action === "hours_change" && (
                    <span className={classes.changeColor}>{start}</span>
                  )}
                  {changes.start && action !== "hours_change" && (
                    <span>{start}</span>
                  )}
                  {!changes.start && <span>{start}</span>}
                  {` - `}
                  {changes.end && action === "hours_change" && (
                    <span className={classes.changeColor}>{end}</span>
                  )}
                  {changes.end && action !== "hours_change" && (
                    <span>{end}</span>
                  )}
                  {!changes.end && <span>{end}</span>}
                </span>
                <span className={classes.inviteRamp}>
                  {`Lokalizacja: `}
                  {(changes.resourceId ||
                    params.row.nextWindow.resourceId !==
                      params.row.prevWindow.resourceId) && (
                    <span className={classes.changeColor}>{resource}</span>
                  )}
                  {!changes.resourceId && <span>{resource}</span>}
                </span>
                <span className={classes.inviteRamp}>
                  {`Dostawca: `}
                  {changes.clientId && (
                    <span className={classes.changeColor}>{client}</span>
                  )}
                  {!changes.clientId && <span>{client}</span>}
                </span>
              </div>
            )}
            {action === "remove" && <div>{"---"}</div>}
          </div>
        );
      },
    },
    {
      field: "id",
      headerName: " ",
      width: 100,
      renderCell: (params) => {
        return (
          <Button
            color="primary"
            startIcon={<Undo />}
            style={{ marginLeft: 10, fontSize: 10 }}
            onClick={() => {
              undoChange(setChangelogs, params.row);
            }}
          ></Button>
        );
      },
    },
  ];
}

export default function InvitationsGrid() {
  const [changelogs, setChangelogs] = React.useState([]);
  const [rowSelected, setRowSelected] = React.useState([]);
  const [sortModel] = React.useState([
    {
      field: "createdAt",
      sort: "desc",
    },
  ]);
  const classes = useStyles();
  React.useEffect(() => {
    getChangeLogs(setChangelogs);
  }, []);

  return (
    <div>
      <h2>Rejestr zmian</h2>
      <DataGrid
        rowHeight={120}
        rowKey="_id"
        rows={changelogs}
        sortingOrder={["desc", "asc"]}
        sortModel={sortModel}
        disableSelectionOnClick
        columns={logColumns(classes, setChangelogs)}
        pageSize={10}
        autoHeight={true}
        checkboxSelection
        onSelectionModelChange={(newSelection) => {
          setRowSelected(newSelection.selectionModel);
        }}
        getRowId={(row) => row._id}
      />
      <br />
      <Button
        variant="outlined"
        onClick={() => {
          deleteLog(setChangelogs, rowSelected);
        }}
        color="primary"
      >
        {" "}
        Usuń{" "}
      </Button>{" "}
    </div>
  );
}
