import React from "react";
import { Axios } from ".././middleware/axiosInstance";
import { DataGrid } from "@material-ui/data-grid";
import { withStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import moment, { relativeTimeRounding } from "moment";
import {
  AccessTime,
  AddBox,
  Cancel,
  FiberNew,
  PersonAdd,
  PersonAddDisabled,
  Place,
  Send,
  Undo,
} from "@material-ui/icons";
import { Button, Tooltip } from "@material-ui/core";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];

function getNotifications(hook) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/notification/get/all/sent`,
  }).then((res) => {
    if (!res.data.err) {
      console.log("-----");
      console.log(res.data.data);
      hook.setState({ notifications: res.data.data });
    } else {
      console.log(res.data.msg);
    }
  });
}
function deleteNotifications(hook, data) {
  Axios({
    method: "DELETE",
    withCredentials: true,
    url: `${API_HOST}/notification/delete`,
    params: { ids: data },
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ databaseState: true });
      getNotifications(hook);
    } else {
      hook.setState({ databaseState: false });
      console.log(res.data.msg);
    }
  });
}

const StyledChip = withStyles((ChipTheme) => ({
  root: {
    padding: 5,
    width: 180,
    float: "left",
  },
}))(Chip);

const StyledPopover = withStyles((ChipTheme) => ({
  root: {
    pointerEvents: "none",
  },
  paper: {
    padding: ChipTheme.spacing(1),
  },
}))(Popover);

const StyledDataGrid = withStyles({
  root: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-viewport": {
      maxHeight: "none !important",
    },
  },
})(DataGrid);

const notificationColumns = (hook) => {
  return [
    {
      field: "client",
      headerName: "Klient",
      width: 170,
      type: "string",
      sortable: true,
      renderCell: (params) => {
        return <Typography>{params.row.client.name}</Typography>;
      },
    },
    {
      field: "type",
      headerName: "Typ",
      width: 90,
      type: "string",
      sortable: true,
      renderCell: (params) => {
        const type = params.row.type;
        if (type === "windowHoursChanged") {
          return (
            <Tooltip title="Zmiana godzin">
              <AccessTime></AccessTime>
            </Tooltip>
          );
        } else if (type === "windowNew") {
          return (
            <Tooltip title="Dodanie okna">
              <FiberNew></FiberNew>
            </Tooltip>
          );
        } else if (type === "resourceChanged") {
          return (
            <Tooltip title="Zmiana lokalizacji">
              <Place></Place>
            </Tooltip>
          );
        } else if (type === "windowRemoved") {
          return (
            <Tooltip title="Usunięcie okna">
              <Cancel></Cancel>
            </Tooltip>
          );
        }
      },
    },
    {
      field: "window",
      headerName: "Dotyczy okna",
      width: 220,
      sortable: true,
      type: "string",
      renderCell: (params) => {
        let windowData = params.row.window;
        return (
          <div style={{ width: "100%" }}>
            <div style={{ padding: 5, position: "relative", float: "left" }}>
              <StyledChip
                aria-owns={
                  hook.state.openPopover ? "mouse-over-popover" : undefined
                }
                aria-haspopup="true"
                onMouseEnter={(event) => {
                  hook.handlePopoverOpen(event, windowData);
                }}
                onMouseLeave={hook.handlePopoverClose}
                variant="outlined"
                size="small"
                label={`${moment(windowData.start).format(
                  "DD-MM HH:mm"
                )} - ${moment(windowData.end).format("HH:mm")}`}
                color="primary"
              />
            </div>
          </div>
        );
      },
    },
  ];
};
export default class NotificationsGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      rowsSelected: [],
      notificationsClick: { start: "placeholder", end: "placeholder" },
      anchorEl: null,
      openPopover: false,
      popoverWindow: { resourceId: 1 },
      updateCount: 0,
    };
  }
  componentDidMount() {
    getNotifications(this);
  }
  componentDidUpdate() {
    if (this.state.updateCount !== this.props.updateCount) {
      this.setState({
        updateCount: this.props.updateCount,
      });
      getNotifications(this);
    }
  }
  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    schedulerData.prev();
    schedulerData.next();
    this.setState({
      viewModel: schedulerData,
    });
    getNotifications(this);
  };

  eventClicked = (schedulerData, event) => {
    this.setState({
      notificationsClick: event,
    });
    this.setState({ editWindow: true });
  };
  handleRowSelection = (rows) => {
    this.setState({ rowsSelected: rows });
  };
  handlePopoverOpen = (event, window) => {
    this.setState({ popoverWindow: window });
    this.setState({ anchorEl: event.currentTarget });
    this.setState({ openPopover: true });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
    this.setState({ openPopover: false });
  };

  render() {
    return (
      <div>
        <Box height={"700px"}>
          <StyledDataGrid
            rowKey="_id"
            rows={this.state.notifications}
            columns={notificationColumns(this)}
            onSelectionModelChange={(newSelectionModel) => {
              this.handleRowSelection(newSelectionModel.selectionModel);
            }}
            //pageSize={10}
            autoHeight={false}
            checkboxSelection
            onRowSelected={(e) => this.setState({ rowSelected: e.data })}
            getRowId={(row) => row._id}
          />
        </Box>
        <br />
        <Button
          variant="outlined"
          onClick={() => {
            deleteNotifications(this, this.state.rowsSelected);
          }}
          color="primary"
        >
          Usuń
        </Button>
        <StyledPopover
          id="mouse-over-popover"
          open={this.state.openPopover}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>
            {moment(this.state.popoverWindow.start).format(
              "dddd DD-MM-YYYY HH:mm"
            )}{" "}
            - {moment(this.state.popoverWindow.end).format("HH:mm")}
          </Typography>
          <Typography>Typ: {this.state.popoverWindow.type}</Typography>
          <Typography>
            Lokalizacja:{" "}
            {this.props.resources[this.state.popoverWindow.resourceId].name}
          </Typography>
          <div className="popover-window-id">{`#${this.state.popoverWindow.id}`}</div>
        </StyledPopover>
      </div>
    );
  }
}
