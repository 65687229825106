import React, { useEffect, useState } from "react";
import moment from "moment";
import { Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { PieChart } from "react-minimal-pie-chart";
import { Axios } from ".././middleware/axiosInstance";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];

function getNextWindow(
  setNext,
  setStart,
  setEnd,
  setType,
  setClient,
  resourceId
) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/window/get/next`,
    params: { resourceId: resourceId, type: "Rozładunek" },
  }).then((res) => {
    if (!res.data.err && res.data.data[0] && setNext) {
      setNext(true);
      setStart(res.data.data[0].start);
      setEnd(res.data.data[0].end);
      setType(res.data.data[0].type);
      setClient(res.data.data[0].client);
    } else if (setNext) {
      setNext(false);
    }
  });
}
const useStyles = makeStyles((theme) => ({
  circle: {
    border: "0.5em solid black",
    borderRadius: "100%",
    height: "2em",
    width: "2em",
    textAlign: "center",
  },
  number: {
    marginTop: "0.10em",
    fontSize: "1.5em",
    fontWeight: "bold",
    fontFamily: "sans-serif",
    color: "black",
  },
  fill: {
    fill: "#ffffff",
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    width: "100%",
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#ff6634",
  },
}))(LinearProgress);

function getPercentage(
  startDate,
  endDate,
  setNext,
  setStart,
  setEnd,
  setType,
  setClient,
  resource
) {
  let start = moment(startDate);
  let end = moment(endDate);
  let now = moment();
  let per_complete = ((now - start) / (end - start)) * 100;
  let final = Math.round(per_complete * 100) / 100;
  if (final >= 100) {
    getNextWindow(setNext, setStart, setEnd, setType, setClient, resource);
  }
  return final;
}
export default function NextWindows(props) {
  const classes = useStyles();
  const [isNext, setNext] = useState(true);
  const [start, setStart] = useState(moment().set({ minute: 0, second: 0 }));
  const [end, setEnd] = useState(
    moment().set({ minute: 0, second: 0 }).add(1, "hours")
  );
  const [type, setType] = useState("");
  const [client, setClient] = useState({});
  useEffect(() => {
    getNextWindow(setNext, setStart, setEnd, setType, setClient, 1);
  }, []);

  useEffect(() => {
    getNextWindow(
      setNext,
      setStart,
      setEnd,
      setType,
      setClient,
      props.resource.id
    );
  }, [props.resource.updateCount, props.resource.id]);
  return (
    <div>
      <div style={{ height: 135 }}>
        <Typography variant="h5" style={{ float: "left", color: "#ff6634" }}>
          Kolejna awizacja:
        </Typography>
        <div
          style={{
            width: "100%",
            height: 65,
            float: "left",
            marginTop: 10,
          }}
        >
          <div className={classes.root}>
            <div
              style={{ float: "left", width: "100%", marginBottom: "20px" }}
            ></div>
            <div style={{ float: "left", width: "100%", marginBottom: "10px" }}>
              <Typography style={{ float: "left" }} variant="h6">
                Kontrahent:
                <Box component="span" fontWeight={600}>
                  {" "}
                  {(isNext && client?.name) || "---"}
                </Box>
              </Typography>
              <Typography style={{ float: "right" }} variant="h6">
                Typ:
                <Box component="span" fontWeight={600}>
                  {" "}
                  {(isNext && type) || "---"}
                </Box>
              </Typography>
            </div>
            <Typography variant="h7">
              {(isNext && (
                <Box component="span" fontWeight={600}>
                  {moment(start).format("HH:mm")}
                </Box>
              )) ||
                " "}
              {}
            </Typography>
            <Typography variant="h7" style={{ float: "right" }}>
              {(isNext && (
                <Box component="span" fontWeight={600}>
                  {moment(end).format("HH:mm")}
                </Box>
              )) ||
                " "}
              {}
            </Typography>
            {isNext && (
              <BorderLinearProgress variant="determinate" value={-1} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
