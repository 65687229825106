import * as React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Axios } from "./middleware/axiosInstance";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import { TransitionGroup } from "react-transition-group";
import {
  ListItemSecondaryAction,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../config.json")["API_HOST"][env];
function getAllProducts(setProducts) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/product/get/all`,
  }).then((res) => {
    if (!res.data.err) {
      setProducts(res.data.data);
    } else {
      console.log(res.data.msg);
    }
  });
}

function renderItem({ item, handleRemoveProduct, handleChangeProductCount }) {
  return (
    <ListItem>
      <ListItemText
        primary={item.name}
        secondary={item.symbol}
        style={{ width: "90%" }}
      />
      <TextField
        id="outlined-basic"
        label="Ilość"
        variant="outlined"
        type="number"
        value={item.count}
        onChange={(event) => handleChangeProductCount(item, event.target.value)}
      />
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="delete"
          title="Usuń"
          onClick={() => handleRemoveProduct(item)}
        >
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default function TransitionGroupExample(props) {
  const [pickedProducts, setPickedProducts] = React.useState([]);
  const [products, setProducts] = React.useState([]);

  React.useEffect(() => {
    getAllProducts(setProducts);
    if (props.pickedProducts?.length > 0) {
      setPickedProducts(props.pickedProducts);
    }
  }, []);

  React.useEffect(() => {
    props.onUpdate(pickedProducts);
  }, [pickedProducts]);

  const handleAddProduct = (value) => {
    if (value) {
      setPickedProducts(function (prev) {
        let array = [value, ...prev];
        array = [...new Set(array)];
        return array;
      });
    }
  };

  const handleRemoveProduct = (item) => {
    setPickedProducts((prev) => [...prev.filter((i) => i.name !== item.name)]);
  };

  const handleChangeProductCount = (item, value) => {
    console.log(item);
    setPickedProducts((prev) => [
      ...prev.map(function (i) {
        if (i === item) {
          i.count = value;
        }
        return i;
      }),
    ]);
  };

  const addProductSelect = (
    <Autocomplete
      id="combo-box-products"
      options={products}
      getOptionLabel={(option) => `${option.symbol} ${option.name}`}
      onChange={(event, newValue) => {
        handleAddProduct(newValue);
      }}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField {...params} label="Produkty" variant="outlined" />
      )}
    />
  );

  return (
    <div>
      {addProductSelect}
      <Box sx={{ mt: 1 }}>
        <List>
          <TransitionGroup>
            {pickedProducts?.map((item) => (
              <Collapse key={item.name}>
                {renderItem({
                  item,
                  handleRemoveProduct,
                  handleChangeProductCount,
                })}
              </Collapse>
            ))}
          </TransitionGroup>
        </List>
      </Box>
    </div>
  );
}
