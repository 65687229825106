import {
  Box,
  Button,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { Axios } from ".././middleware/axiosInstance";
import CategoryPicker from "./CategoryPicker";
import ImageDropzoneDialog from "./Dropzone";
import ImagePicker from "./ImagePicker";
import ProductMeasurements from "./ProductMeasurements";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];
function getExternalProducts(hook, id) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/product/get/external/byCecha`,
    params: { id: id },
  }).then((res) => {
    if (!res.data.err) {
      let externalProducts = res.data.data.sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
      let filtered = externalProducts;
      if (!hook.props.edit) {
        filtered = externalProducts.filter((prod) => {
          if (
            hook.props.data.productsIds.flat(1).includes(prod.towarIdSubiekt)
          ) {
            return false;
          } else return prod;
        });
      }
      hook.setState({ externalProducts: filtered });
    } else {
      console.log(res.data.msg);
    }
  });
}
function getExternalProductsFromProps(hook, id, towarId) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/product/get/external/byCecha`,
    params: { id: id },
  }).then((res) => {
    if (!res.data.err) {
      let externalProducts = res.data.data;
      let extProdsMap = new Map(
        externalProducts.map((prod) => {
          return [prod.towarIdSubiekt, prod];
        })
      );
      hook.setState({ externalProducts: externalProducts });
      hook.setState({ externalProductsChoosen: extProdsMap.get(towarId) });
    } else {
      console.log(res.data.msg);
    }
  });
}

class ProductDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productId: null,
      productName: "",
      productTowarIdSubiekt: 0,
      productCechaIdSubiekt: 0,
      productDescription: "",
      productImgUrl: "",
      productCategoryId: 0,
      productSymbol: "",
      productUnit: "",
      productUnitCount: 0,
      productBaseUnit: "",
      productIgnoreAvailability: false,
      productUnitConvert: false,
      productUnitConvertFactor: 1,
      productUnitConvertBase: "",
      productUnitConvertUnit: "",
      productUnitConvertUnitCount: 0,
      productMeasurements: [{ id: 1, count: 1, unit: 'szt.', baseUnit: 'szt.' }],
      openDrawer: false,
      pickedCecha: 0,
      externalProducts: [],
      externalProductsChoosen: { symbol: "", name: "" },
      externalCechaChoosen: {},
      categories: new Map(
        props.data.flatCategories.map((item) => [item.id, item])
      ),
      categoryLabel: "",
      uploadedImages: [],
    };
  }
  static getDerivedStateFromProps(props, state) {
    if (props.data.product.id !== state.productId) {
      let cechaMap = new Map(
        props.data.cecha.map((cecha) => {
          return [cecha.id, cecha];
        })
      );
      return {
        productId: props.data.product.id,
        productName: props.data.product.name,
        productTowarIdSubiekt: props.data.product.towarIdSubiekt,
        productCechaIdSubiekt: props.data.product.cechaIdSubiekt,
        productDescription: props.data.product.description,
        productImgUrl: props.data.product.imgUrl,
        productCategoryId: props.data.product.categoryId,
        productSymbol: props.data.product.symbol,
        productUnit: props.data.product.unit,
        productUnitCount: props.data.product.unitCount,
        productBaseUnit: props.data.product.baseUnit,
        productUnitConvert: props.data.product.unitConvert,
        productUnitConvertFactor: props.data.product.unitConvertFactor,
        productUnitConvertBase: props.data.product.unitConvertBase,
        productUnitConvertUnit: props.data.product.unitConvertUnit,
        productUnitConvertUnitCount: props.data.product.unitConvertUnitCount,
        productMeasurements: props.data.product.measurements,
        productIgnoreAvailability: props.data.product.ignoreAvailability,
        externalCechaChoosen: cechaMap.get(props.data.product.cechaIdSubiekt),
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data.product.id !== this.state.productId) {
      getExternalProductsFromProps(
        this,
        this.state.productCechaIdSubiekt,
        this.state.productTowarIdSubiekt
      );
    }
  }
  getPackage = () => {
    if (this.state.productUnitConvertFactor > 1) {
      return `${Number(this.state.productUnitCount * this.state.productUnitConvertUnitCount)} ${this.state.productBaseUnit} / ${this.state.productUnitConvertUnit}`
    }
    else {
      return `${Number(this.state.productUnitCount / this.state.productUnitConvertUnitCount)} ${this.state.productUnitConvertUnit} / ${this.state.productBaseUnit}`
    }
  }

  render() {
    return (
      <Drawer
        anchor={"right"}
        open={this.props.open}
        onClose={() => {
          this.props.onClose();
        }}
      >
        <Box m={2} style={{ maxWidth: 600 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography style={{ float: "left" }} variant="h5">
                {!this.props.edit && "Dodawanie produktu"}
                {this.props.edit && "Edycja produktu"}
              </Typography>
              <IconButton
                onClick={() => {
                  this.props.onClose();
                }}
                style={{ float: "right" }}
              >
                <Close />
              </IconButton>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="body1">Subiekt:</Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Autocomplete
                id="combo-box-demo"
                options={this.props.data.cecha}
                getOptionLabel={(option) => `${option.name}`}
                onChange={(event, newValue) => {
                  this.setState({
                    externalCechaChoosen: newValue,
                  });
                  getExternalProducts(this, newValue.id);
                }}
                value={this.state.externalCechaChoosen}
                defaultValue={this.state.productCechaIdSubiekt}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Cecha" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Autocomplete
                id="combo-box-demo"
                options={this.state.externalProducts}
                getOptionLabel={(option) => `[${option.symbol}] ${option.name}`}
                noOptionsText="Wyszukiwane produkty są już dodane lub podano błędne dane"
                onChange={(event, product) => {
                  console.log(product);
                  if (product) {
                    this.setState({
                      productName: product.name,
                      productTowarIdSubiekt: product.towarIdSubiekt,
                      productCechaIdSubiekt: product.cechaIdSubiekt,
                      productUnit: product.unit,
                      productBaseUnit: product.baseUnit,
                      productUnitCount: product.unitCount,
                      productSymbol: `[${product.symbol}]`,
                      externalProductsChoosen: product,
                    });
                  }
                }}
                style={{ width: "100%" }}
                value={this.state.externalProductsChoosen}
                //defaultValue={this.state.externalProductsChoosen?.name}
                renderInput={(params) => (
                  <TextField {...params} label="Produkt" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="body1">Informacje:</Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={2}>
              <TextField
                required
                id="outlined-required-symbol"
                style={{ width: "100%" }}
                value={this.state.productSymbol || ""}
                onChange={(event) => {
                  this.setState({
                    productSymbol: event.target.value,
                  });
                }}
                label="Symbol"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={12} lg={10}>
              <TextField
                required
                id="outlined-required-name"
                style={{ width: "100%" }}
                value={this.state.productName || ""}
                onChange={(event) => {
                  this.setState({
                    productName: event.target.value,
                  });
                }}
                label="Nazwa"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                id="outlined-multiline-flexible"
                label="Opis"
                required
                multiline
                style={{ width: "100%" }}
                rows={3}
                value={this.state.productDescription}
                onChange={(event) => {
                  this.setState({
                    productDescription: event.target.value,
                  });
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Typography variant="body1" style={{ marginBottom: 10 }}>
                Kategoria:
              </Typography>
              <CategoryPicker
                data={{
                  categoriesTree: this.props.data.categories,
                  flatCategories: this.props.data.flatCategories,
                }}
                maxHeight={300}
                width={"100%"}
                editMode={false}
                pickMode={true}
                selected={this.state.productCategoryId}
                onSelect={(selected) => {
                  this.setState({
                    productCategoryId: selected,
                    categoryLabel: this.state.categories.get(selected)?.label,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Typography variant="body1" style={{ marginBottom: 10 }}>
                Zaawansowane:{" "}
              </Typography>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.productIgnoreAvailability}
                      color="primary"
                      onChange={(ev, ignore) => {
                        this.setState({
                          productIgnoreAvailability: ignore,
                        });
                      }}
                      name="ignoreAvailabity"
                    />
                  }
                  label="Ignoruj dostępność"
                />
              </FormGroup>
              <Typography
                variant="body1"
                style={{
                  marginBottom: 10,
                  marginTop: 10,
                  float: "left",
                  width: "100%",
                }}
                gutterBottom
              >
                Jednostka podstawowa:{" "}
              </Typography>
              <TextField
                id="outlined-flexible"
                label="Ilość"
                required
                style={{ width: "30%" }}
                rows={3}
                value={Number(this.state.productUnitCount) || ""}
                onChange={(event) => {
                  this.setState({
                    productUnitCount: event.target.value,
                  });
                  this.setState({
                    productUnitConvertFactor: Number(this.state.productUnitConvertUnitCount / Number(event.target.value)),
                  });
                }}
                type="number"
                variant="outlined"
                size="small"
              />
              <TextField
                id="outlined-flexible"
                label="Jednost."
                required
                style={{ width: "30%" }}
                rows={3}
                value={this.state.productUnit || ""}
                onChange={(event) => {
                  this.setState({
                    productUnit: event.target.value,
                  });
                }}
                variant="outlined"
                size="small"
              />
              <TextField
                id="outlined-flexible"
                label="Jedn. bazowa"
                required
                style={{ width: "40%" }}
                rows={3}
                value={this.state.productBaseUnit || ""}
                onChange={(event) => {
                  this.setState({
                    productBaseUnit: event.target.value,
                  });
                }}
                variant="outlined"
                size="small"
              />
              {/* <FormGroup row style={{marginTop: 10, marginBottom: 10}}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.productUnitConvert}
                      color="primary"
                      onChange={(ev, convert) => {
                        this.setState({
                          productUnitConvert: convert,
                        });
                      }}
                      name="unitConvert"
                    />
                  }
                  label="Konwertuj jednostkę podst."
                />
              </FormGroup>
              {this.state.productUnitConvert && 
              <>
              <TextField
                id="outlined-flexible"
                label="Ilość"
                required
                style={{ width: "30%",marginBottom: 10 }}
                rows={3}
                value={Number(this.state.productUnitConvertUnitCount) || ""}
                onChange={(event) => {
                  this.setState({
                    productUnitConvertUnitCount: Number(event.target.value),
                  });
                  this.setState({
                    productUnitConvertFactor: Number(Number(event.target.value) / this.state.productUnitCount),
                  });
                }}
                type="number"
                variant="outlined"
                size="small"
              />
              <TextField
                id="outlined-flexible"
                label="Jednost."
                required
                style={{ width: "30%" }}
                rows={3}
                value={this.state.productUnitConvertBase || ""}
                onChange={(event) => {
                  this.setState({
                    productUnitConvertBase: event.target.value,
                  });
                }}
                variant="outlined"
                size="small"
              />
              <TextField
                id="outlined-flexible"
                label="Jedn. bazowa"
                required
                style={{ width: "40%" }}
                rows={3}
                value={this.state.productUnitConvertUnit || ""}
                onChange={(event) => {
                  this.setState({
                    productUnitConvertUnit: event.target.value,
                  });
                }}
                variant="outlined"
                size="small"
              />
              <TextField
                id="outlined-flexible"
                label="Mnożnik"
                required
                disabled
                style={{ width: "30%" }}
                rows={3}
                value={Number(this.state.productUnitConvertUnitCount / this.state.productUnitCount) || ""}
                type="number"
                variant="outlined"
                size="small"
              />
              <Typography
                style={{ color: "white" }}
                size="small"
              >Pakowanie: {this.getPackage()}
              </Typography>
              <Typography
                style={{ color: "white" }}
                size="small"
              >test:{` ${this.state.productUnitConvertUnitCount} ${this.state.productUnitConvertFactor}`}
              </Typography>
              
              </>}
               */}

            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <ProductMeasurements
                units={
                  {
                    unit: this.state.productUnit,
                    baseUnit: this.state.productBaseUnit,
                    count: this.state.productUnitCount
                  }
                }
                measurements={this.state.productMeasurements}
                setMeasurements={(measurements)=>{
                  this.setState({ productMeasurements: measurements })
                  //alert(JSON.stringify(measurements))
                }} />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              {this.props.edit && (
                <div>
                  <Typography variant="body1" style={{ marginBottom: 10 }}>
                    Zdjęcia
                  </Typography>
                  <ImagePicker
                    product={{ id: this.state.productId }}
                    selectedImage={this.state.productImgUrl}
                    onSelectedChange={(name) => {
                      this.setState({ productImgUrl: name });
                    }}
                    uploadedImages={this.state.uploadedImages}
                  />

                  <ImageDropzoneDialog
                    productId={this.state.productId}
                    onUpload={(imgs) => {
                      this.setState({ uploadedImages: imgs });
                    }}
                  />
                </div>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Button
              style={{ float: "right" }}
              variant="outlined"
              color="primary"
              onClick={() => {
                let product = {
                  id: this.state.productId,
                  name: this.state.productName,
                  towarIdSubiekt: this.state.productTowarIdSubiekt,
                  cechaIdSubiekt: this.state.productCechaIdSubiekt,
                  description: this.state.productDescription,
                  imgUrl: this.state.productImgUrl,
                  categoryId: this.state.productCategoryId,
                  unit: this.state.productUnit,
                  unitCount: this.state.productUnitCount,
                  baseUnit: this.state.productBaseUnit,
                  symbol: this.state.productSymbol,
                  ignoreAvailability: this.state.productIgnoreAvailability,
                  measurements: this.state.productMeasurements,
                };
                this.props.onSave(product, this.props.edit);
              }}
            >
              Zapisz
            </Button>
          </Grid>
        </Box>
      </Drawer>
    );
  }
}

export default ProductDrawer;
