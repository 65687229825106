import React from "react";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { Axios } from "./middleware/axiosInstance";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../config.json")["API_HOST"][env];

function getRampData(rampId, setRampName) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/ramp/get`,
    params: { id: rampId },
  }).then((res) => {
    console.log(res.data);
    if (res.data.data[0]) {
      setRampName(res.data.data[0].name);
    }
  });
}
function getClientData(clientId, setClientData) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/client/get`,
    params: { _id: clientId },
  }).then((res) => {
    console.log(res.data);
    if (res.data.data[0]) {
      setClientData(res.data.data[0]);
    }
  });
}
function getClients(setClientsFull, setClients) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/client/get/all`,
  }).then((res) => {
    console.log(res.data);
    if (res.data.data[0]) {
      setClients(res.data.data);
      setClientsFull(
        res.data.data.map((c) => {
          return c.name;
        })
      );
    }
  });
}

function saveData(
  id,
  title,
  clientId,
  start,
  end,
  carrier,
  type,
  registrationNumber,
  phone,
  numberOfPallets,
  description,
  close,
  updateData
) {
  let window = {};
  window.id = id;
  window.clientId = clientId;
  window.start = start;
  window.end = end;
  window.title = title;
  window.carrier = carrier;
  window.type = type;
  window.registrationNumber = registrationNumber;
  window.phone = phone;
  window.numberOfPallets = numberOfPallets;
  window.description = description;
  console.log(window);
  Axios({
    method: "PUT",
    withCredentials: true,
    url: `${API_HOST}/window/update`,
    params: window,
  }).then((res) => {
    console.log(res.data);
    updateData();
    close();
  });
}

const types = [{ name: "Rozładunek" }, { name: "Załadunek" }];

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: 20,
  },
  marginRight: {
    marginRight: 20,
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({
  window,
  open,
  clientId,
  onClose = (f) => f,
  updateData = (f) => f,
}) {
  const [startDate, setStartDate] = React.useState("1");
  const [windowTitle, setWindowTitle] = React.useState("1");
  const [endDate, setEndDate] = React.useState("1");
  const [rampName, setRampName] = React.useState("Ładowanie...");
  const [client, setClientData] = React.useState("Nie przydzielono");
  const [registrationNumber, setRegistrationNumber] = React.useState("");
  const [numberOfPallets, setnumberOfPallets] = React.useState();
  const [description, setDescription] = React.useState(" ");
  const [phone, setPhone] = React.useState(0);
  const [clients, setClients] = React.useState([]);
  const [clientsFullData, setClientsFullData] = React.useState({});
  const [carrierName, setCarrierName] = React.useState("PEKAES*");
  const [type, setType] = React.useState("Rozładunek");
  const handleTitleChange = (event) => {
    setWindowTitle(event.target.value);
  };
  const handleStartChange = (event) => {
    setStartDate(event.target.value);
  };
  const handleEndChange = (event) => {
    setEndDate(event.target.value);
  };
  const handleClientChange = (value) => {
    if (value) {
      setClientData(value);
    }
  };
  const handleTypeChange = (value) => {
    if (value) {
      setType(value);
    }
  };
  const handleCarrierChange = (event) => {
    setCarrierName(event.target.value);
  };
  const handlenumberOfPalletsChange = (event) => {
    setnumberOfPallets(event.target.value);
  };
  const handleRegistrationNumberChange = (event) => {
    setRegistrationNumber(event.target.value);
  };
  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  React.useEffect(() => {
    console.log(window);
    setStartDate(
      moment.utc(window.start.slice(0, 16)).local().format("YYYY-MM-DDTHH:mm")
    );
    setEndDate(
      moment.utc(window.end.slice(0, 16)).local().format("YYYY-MM-DDTHH:mm")
    );
    setWindowTitle(window.title);
    setRegistrationNumber(window.registrationNumber);
    setPhone(window.phone);
    setnumberOfPallets(window.numberOfPallets);
    setDescription(window.description);
    getRampData(window.resourceId, setRampName);
    getClientData(window.clientId, setClientData);
    getClients(setClientsFullData, setClients);
  }, [window]);
  const classes = useStyles();
  return (
    <div>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          Okno: {window.title}
        </DialogTitle>
        <DialogContent dividers>
          <Autocomplete
            onChange={(event, value) => handleTypeChange(value)}
            options={types}
            defaultValue={types[0]}
            getOptionLabel={(option) => option.name}
            className={classes.marginBottom}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label="Typ" placeholder={window.type} />
            )}
          />
          <TextField
            id="standard"
            label="Przewoźnik"
            fullWidth={true}
            className={classes.marginBottom}
            onChange={handleCarrierChange}
            defaultValue={window.carrier}
          />

          <TextField
            id="registrationNumber"
            label="Numer Rejestracyjny Pojazdu:"
            type="text"
            defaultValue={registrationNumber}
            onChange={handleRegistrationNumberChange}
            className={clsx(classes.marginRight, classes.marginBottom)}
            style={{ width: "48%" }}
          />
          <TextField
            id="phone"
            label="Telefon:"
            type="tel"
            defaultValue={phone}
            onChange={handlePhoneChange}
            className={clsx(classes.marginBottom)}
            style={{ width: "48%" }}
          />
          <TextField
            id="numberOfPallets"
            label="Ilość Palet:"
            type="number"
            defaultValue={numberOfPallets}
            onChange={handlenumberOfPalletsChange}
            className={clsx(classes.marginBottom)}
            fullWidth={true}
          />
          <TextField
            id="description"
            label="Uwagi:"
            type="text"
            defaultValue={description}
            onChange={handleDescriptionChange}
            fullWidth={true}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              saveData(
                window.id,
                windowTitle,
                clientId,
                startDate,
                endDate,
                carrierName,
                type,
                registrationNumber,
                phone,
                numberOfPallets,
                description,
                onClose,
                updateData
              );
            }}
            color="primary"
          >
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
