import * as React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ProductPicker from "./ProductPicker";

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleProductsUpdate = (value) => {
    props.onProductsUpdate(value);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Produkty
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>Produkty</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Wybierz produkty z listy i określ ich ilość
          </DialogContentText>
          <ProductPicker
            onUpdate={(value) => {
              handleProductsUpdate(value);
            }}
            pickedProducts={props.products}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Anuluj</Button>
          <Button onClick={handleClose}>Zakończ</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
