import * as React from "react";
import {
  useAnalyticsApi,
  useAuthorize,
  useDataChart,
  useSignOut,
  useViewSelector,
  useData,
} from "react-use-analytics-api";
import ActivityLineChart from "./ActivityLineChart";
const ANALYTICS_ID = process.env.ANALYTICS_ID;
export default function Chart() {
  const { ready, gapi, authorized, error } = useAnalyticsApi();
  const [viewId, setViewId] = React.useState();
  const [analyticsData, setAnalyticsData] = React.useState();
  const viewSelectorContainerId = "view-selector-container";
  useViewSelector(
    authorized ? gapi : undefined,
    viewSelectorContainerId,
    (viewId) => setViewId(viewId)
  );
  const query = {
    ids: viewId,
    "start-date": "yesterday",
    "end-date": "today",
    metrics: "ga:sessions",
    dimensions: "ga:hour",
  };
  // const chart = {
  //   container: "data-chart-container",
  //   type: "LINE",
  //   options: {
  //     title: "Sessions (28 Days)",
  //   },
  // };
  //useDataChart(authorized ? gapi : undefined, query, chart);
  const execute = useData(
    gapi,
    query,
    (response) => setAnalyticsData(response.rows),
    (response) => console.error("Data query error:", response)
  );
  React.useEffect(() => {
    if (authorized && viewId) {
      execute();
    }
  }, [authorized, viewId]);
  // Workaround for API limitation - see useAuthorize docs
  const authDiv = React.useRef(null);
  const [authorizeCalled, setAuthorizeCalled] = React.useState(false);
  const hasAuthElements =
    authDiv.current && authDiv?.current?.children?.length > 0;

  const authorize = useAuthorize(gapi, {
    clientId:
      "1077710404373-0asvnu0vejmitre64tc4fso9j4v65qeq.apps.googleusercontent.com",
    container: "authorize-container-id",
  });
  // const authorize = useAuthorize(gapi, {
  //   serverAuth: {
  //     access_token: "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",
  //   },
  // });
  const signOut = useSignOut(gapi);

  React.useEffect(() => {
    console.log(ready);
    if (ready && !error && !authorizeCalled) {
      console.log(ready);
      authorize();
      setAuthorizeCalled(true);
    }
    if (error) {
      console.log(error);
    }
  }, [ready, error, authorizeCalled, authorize]);

  return (
    <div>
      {!ready && <div>Loading...</div>}
      {ready && (
        <div>
          {authorized && (
            <div>
              <div style={{ marginTop: "30px" }}>
                <div className="data-chart" id="data-chart-container" />
                <ActivityLineChart data={analyticsData} />
              </div>
              <div id={viewSelectorContainerId} />
              <div>
                <button onClick={() => signOut()}>Wyloguj</button>
              </div>
            </div>
          )}
          {!authorized && (
            <div>
              <div ref={authDiv} id="authorize-container-id"></div>
              {!hasAuthElements && <div>🔄 Odśwież aplikację.</div>}
            </div>
          )}
        </div>
      )}
      {error && <div>{error.toString()}</div>}
    </div>
  );
}
