import React from "react";
import { useParams } from "react-router-dom";
import { Axios } from ".././middleware/axiosInstance";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { countries } from ".././locales.json";
require("dotenv").config();
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];

function checkToken(token, setWindows, setError) {
  let data = {
    token: token,
  };
  Axios({
    method: "GET",
    withCredentials: true,
    url: `/${API_HOST}/invitation/get`,
    params: data,
  })
    .then((res) => {
      setWindows(res.data.data.windows);
    })
    .catch((res) => {
      setError(true);
    });
}
function pickUp(window, token, setFinish, setError) {
  console.log("asdasd");
  let data = {
    token: token,
    window: window,
  };
  Axios({
    method: "POST",
    withCredentials: true,
    url: `/${API_HOST}/invitation/pickup`,
    data: data,
  }).then((res) => {
    if (res.data.err) {
      setError(true);
    } else {
      setFinish(true);
    }
  });
}
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}
const autocompleteStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      // Default transform is "translate(14px, 20px) scale(1)""
      // This lines up the label with the initial cursor position in the input
      // after changing its padding-left.
      transform: "translate(14px, 20px) scale(1);",
    },
  },
  inputRoot: {
    color: "white",
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 6,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
  },
  clearIndicator: {
    color: "white",
  },
  popupIndicator: {
    color: "white",
  },
}));
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  marginBottom: {
    marginBottom: 20,
  },
  marginRight: {
    marginRight: 20,
  },
  marginLeft: {
    marginLeft: 70,
  },
  inviteContainer: {
    position: "relative",
    float: "left",
    backgroundColor: "rgb(128, 197, 246)",
    borderRight: "4px solid rgb(0, 139, 236)",
    borderLeft: "4px solid rgb(0, 139, 236)",
    marginBottom: 10,
    marginRight: "1%",
    padding: 4,
    width: "49%",
  },
  inviteDate: {
    fontSize: 14,
    color: "#252525",
    float: "left",
    padding: 2,
    width: "100%",
  },
  inviteTime: {
    fontSize: 20,
    color: "#252525",
    float: "left",
    paddingLeft: 2,
    width: "100%",
  },
  inviteRamp: {
    fontSize: 14,
    color: "#252525",
    float: "left",
    paddingLeft: 2,
  },
  inviteIndentTop: {
    position: "absolute",
    transform: "translate(0px, -50%)",
    borderRadius: 10,
    zIndex: 2,
    width: 10,
    height: 10,
    backgroundColor: "white",
    left: 60,
    top: 0,
  },
  inviteIndentBottom: {
    position: "absolute",
    transform: "translate(0px, 50%)",
    borderRadius: 10,
    zIndex: 2,
    width: 10,
    height: 10,
    backgroundColor: "white",
    left: 60,
    bottom: 0,
  },
  leftContainer: {
    position: "absolute",
    height: "100%",
    width: 66,
    top: 0,
    left: 0,
    float: "left",
    backgroundColor: "#95d3ff",
    zIndex: 1,
  },
  icon: {
    color: "#252525",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "45%",
  },
  width100FL: {
    width: "100%",
    float: "left",
  },
  title: {
    width: "100%",
    paddingLeft: "11%",
  },
  appbar: {
    backgroundColor: "#ff6634",
    paddingLeft: "11%",
    paddingRight: "11%",
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  colorWhite: {
    color: "white",
  },
  confirmButton: {
    marginLeft: "12%",
    backgroundColor: "#ff6634",
  },
}));

export default function InvitationPickUp() {
  const [token] = React.useState(useParams());
  const [windows, setWindows] = React.useState(["Ładowanie..."]);
  const [selectedWindowId, setSelectedWindowId] = React.useState(0);
  const [selectedWindow, setSelectedWindow] = React.useState({});
  const [error, setError] = React.useState(false);
  const [finish, setFinish] = React.useState(false);
  const [lang] = React.useState(countries);
  const [choosenLang, setChoosenLang] = React.useState(lang[1]);
  const handleChange = (event) => {
    setSelectedWindowId(event.target.value);
    windows.forEach((w) => {
      if (`${w.id}` === event.target.value) {
        setSelectedWindow(w);
      }
    });
  };
  const handleLangChange = (event, value) => {
    setChoosenLang(value);
  };
  React.useEffect(() => {
    checkToken(token.token, setWindows, setError);
  }, [token]);
  const classes = useStyles();
  const autocompClass = autocompleteStyles();
  const windowList = windows.map((window) => {
    let date = new Date(window.start);
    date = date.toLocaleDateString(choosenLang.locale, {
      weekday: "long",
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    let start = new Date(window.start);
    start = start.toLocaleTimeString([choosenLang.locale], {
      hour: "2-digit",
      minute: "2-digit",
    });
    let end = new Date(window.end);
    end = end.toLocaleTimeString([choosenLang.locale], {
      hour: "2-digit",
      minute: "2-digit",
    });
    return (
      <Grid
        item
        xs={5}
        md={4}
        lg={3}
        key={window.id}
        className={classes.inviteContainer}
      >
        <div className={classes.inviteIndentTop}></div>
        <div className={classes.leftContainer}>
          <Radio
            checked={selectedWindowId === `${window.id}`}
            className={classes.icon}
            onChange={handleChange}
            value={window.id}
            name="radio-button"
            inputProps={{ "aria-label": `${window.id}` }}
          />
        </div>
        <div className={classes.marginLeft}>
          <span className={classes.inviteDate}>{date}</span>
          <span
            className={classes.inviteTime}
          >{`${start} - ${end} (UCT +1)`}</span>
          <span
            className={classes.inviteRamp}
          >{`${choosenLang.translation.localisation}: ${window.ramp?.name}`}</span>
        </div>
        <div className={classes.inviteIndentBottom}></div>
      </Grid>
    );
  });
  return (
    <div>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar>
          <Typography variant="h6" className={classes.width100FL}>
            {choosenLang.translation.advice} - Bialpak
          </Typography>
          <Autocomplete
            id="country-select-demo"
            style={{ width: 300, color: "white" }}
            options={lang}
            classes={autocompClass}
            onChange={handleLangChange}
            autoHighlight
            size="small"
            value={choosenLang}
            getOptionLabel={(option) => option.label}
            renderOption={(option) => (
              <React.Fragment>
                <span>{countryToFlag(option.code)}&nbsp;</span>
                {option.label}
              </React.Fragment>
            )}
            disableClearable={true}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`${choosenLang.translation.choose_language}`}
                InputLabelProps={{
                  style: { color: "#fff" },
                }}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <Grid
          container
          className={classes.container}
          justify="center"
          spacing={0}
        >
          {!(error || finish) && (
            <h2 className={classes.title}>
              {choosenLang.translation.time_slots_title}
            </h2>
          )}
          {!(error || finish) && windowList}
          {error && <h1>{choosenLang.translation.wrong_token}</h1>}
          {finish && <h1>{choosenLang.translation.thank_you}</h1>}
        </Grid>
        {!(error || finish) && (
          <Button
            variant="contained"
            className={classes.confirmButton}
            onClick={() => {
              pickUp(selectedWindow, token.token, setFinish, setError);
            }}
            color="primary"
          >
            {choosenLang.translation.confirm}
          </Button>
        )}
      </div>
    </div>
  );
}
