import React from "react";
import Scheduler, {
  SchedulerData,
  ViewTypes,
  CellUnits,
  DATE_FORMAT,
} from "react-big-scheduler";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import ".././SchedulerStyle.css";
//import WindowEditor from "./ClientWindowEditModal";
import moment from "moment";
import withDragDropContext from ".././withDnDContext";
import { Axios } from ".././middleware/axiosInstance";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import Button from "@material-ui/core/Button";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import PersonIcon from "@material-ui/icons/Person";
import Checkbox from "@material-ui/core/Checkbox";
import { Box, Typography } from "@material-ui/core";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];

function getWindows(hook) {
  let startDate = hook.state.viewModel.startDate;
  let endDate = hook.state.viewModel.endDate;
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/ramp/get/all`,
  }).then((res) => {
    hook.resources = res.data.data;
    hook.setState({ resourcesReturned: true });
    let newSchedulerData = hook.state.viewModel;
    newSchedulerData.setResources(res.data.data);
    hook.setState({
      viewModel: newSchedulerData,
    });
  });
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/window/get/all/reserved`,
    params: { start: startDate, end: endDate },
  }).then((res) => {
    hook.resources = res.data.data;
    hook.setState({ windowsReturned: true });
    hook.setState({ maxWindowId: res.data.maxValue });
    let newSchedulerData = hook.state.viewModel;
    let sorted = res.data.data;
    sorted.sort(function (a, b) {
      return new Date(a.start) - new Date(b.start);
    });
    newSchedulerData.setEvents(sorted);
    hook.setState({
      viewModel: newSchedulerData,
    });
  });
}
function updateWindows(hook, data) {
  Axios({
    method: "PUT",
    withCredentials: true,
    url: `${API_HOST}/window/update`,
    params: data,
  }).then((res) => {
    if (!res.data.err) {
      hook.setState({ databaseState: true });
      getWindows(hook);
    } else {
      hook.setState({ databaseState: false });
      console.log(res.data.msg);
    }
  });
}
function getClient(hook) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/user`,
  }).then((res) => {
    const id = res.data;
    hook.setState({ clientId: id });
    getWindows(hook);
  });
}
const getDateLabel = (schedulerData, viewType, startDate, endDate) => {
  let start = schedulerData.localeMoment(startDate);
  let end = schedulerData.localeMoment(endDate);
  let dateLabel = start.format("dddd DD.MM.YYYY");

  if (
    viewType === ViewTypes.Week ||
    (start != end &&
      (viewType === ViewTypes.Custom ||
        viewType === ViewTypes.Custom1 ||
        viewType === ViewTypes.Custom2))
  ) {
    dateLabel = `${start.format("D ")}-${end.format("D MMM YYYY")}`;
    if (start.month() !== end.month())
      dateLabel = `${start.format("D MMM ")}-${end.format(" D MMM YYYY")}`;
    if (start.year() !== end.year())
      dateLabel = `${start.format("D MMM, YYYY")}-${end.format(" D MMM YYYY")}`;
  } else if (viewType === ViewTypes.Month) {
    dateLabel = start.format("MMMM YYYY");
  } else if (viewType === ViewTypes.Quarter) {
    dateLabel = `${start.format("MMM D")}-${end.format("MMM D YYYY")}`;
  } else if (viewType === ViewTypes.Year) {
    dateLabel = start.format("YYYY");
  }

  return dateLabel;
};

let dotStyle = {
  float: "left",
  position: "absolute",
  left: "6px",
  width: "12px",
  height: "12px",
  top: "50%",
  "-webkit-transform": "translate(0, -50%)",
  transform: "translate(0, -50%)",
};
class SchedulerWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resourcesReturned: false,
      windowsReturned: false,
      editWindow: false,
      senderWindow: false,
      invitationModal: false,
      clientId: "",
    };
    this.resources = [];
    this.events = [];

    moment.locale("pl-pl");
    let schedulerData = new SchedulerData(
      new moment().format(DATE_FORMAT),
      ViewTypes.Day,
      props.width === "xs" ? true : false,
      false,
      {
        customCellWidth: 60,
        nonAgendaDayCellHeaderFormat: "D/M|HH:mm",
        views: [
          {
            viewName: "Dzień",
            viewType: ViewTypes.Day,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: "Tydzień",
            viewType: ViewTypes.Week,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: "Miesiąc",
            viewType: ViewTypes.Month,
            showAgenda: false,
            isEventPerspective: false,
          },
        ],
      },
      {
        getCustomDateFunc: this.getCustomDate,
        isNonWorkingTimeFunc: this.isNonWorkingTime,
        getSummaryFunc: this.getSummary,
        checkConflict: true,
      }
    );
    schedulerData.config.schedulerWidth = "100%";
    schedulerData.config.dayCellWidth = 60;
    schedulerData.config.besidesWidth = props.width === "xs" ? 60 : 200;
    schedulerData.config.agendaResourceTableWidth = "100px";
    schedulerData.config.dayStartFrom = 6;
    schedulerData.config.dayStopTo = 17;
    schedulerData.config.resourceName = "Lokalizacja";
    schedulerData.setResources(this.resources);
    schedulerData.setEvents(this.events);
    schedulerData.setLocaleMoment(moment);
    schedulerData.setMinuteStep(30);
    schedulerData.config.eventItemHeight = 30;
    schedulerData.config.eventItemLineHeight = 32;
    schedulerData.config.nonWorkingTimeHeadBgColor = "rgb(255 74 74 / 14%)";
    schedulerData.config.nonWorkingTimeBodyBgColor = "rgb(255 74 74 / 14%)";
    schedulerData.config.eventItemPopoverDateFormat = "MMMM Do YYYY, h:mm:ss a";
    schedulerData.behaviors.getDateLabelFunc = getDateLabel;
    this.state = {
      viewModel: schedulerData,
      windowEvent: { start: "placeholder", end: "placeholder" },
      clientData: { name: "Ładuję.." },
      invitationMode: false,
      invitationWindows: [],
      checkedInvitationWindowsIds: {},
      clientId: "0",
    };
  }

  componentDidMount() {
    getClient(this);
  }
  render() {
    const { viewModel, windowEvent } = this.state;
    return (
      <Container maxWidth="xl">
        <h2>Terminarz dostaw</h2>
        <Grid container>
          {this.state.resourcesReturned && (
            <Grid item xs={12} md={10} lg={10}>
              <Scheduler
                schedulerData={viewModel}
                prevClick={this.prevClick}
                nextClick={this.nextClick}
                onSelectDate={this.onSelectDate}
                onViewChange={this.onViewChange}
                //eventItemClick={this.eventClicked}
                //viewEventClick={this.ops1}
                //viewEventText="Usuń"
                //viewEvent2Text="Edytuj"
                viewEvent2Click={this.ops2}
                //updateEventStart={this.updateEventStart}
                //updateEventEnd={this.updateEventEnd}
                //moveEvent={this.moveEvent}
                //newEvent={this.newEvent}
                eventItemPopoverTemplateResolver={
                  this.eventItemPopoverTemplateResolver
                }
                eventItemTemplateResolver={this.eventItemTemplateResolver}
                toggleExpandFunc={this.toggleExpandFunc}
                conflictOccurred={this.conflictOccurred}
                //rightCustomHeader={rightCustomHeader(this)}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    );
  }

  updateData = () => {
    getWindows(this);
  };

  closeEditorDialog = () => {
    this.setState({ editWindow: false });
  };

  closeSenderDialog = () => {
    this.setState({ senderWindow: false });
  };

  closeInviteModal = () => {
    this.setState({ invitationModal: false });
  };

  prevClick = (schedulerData) => {
    schedulerData.prev();
    getWindows(this);
    this.setState({
      viewModel: schedulerData,
    });
  };

  nextClick = (schedulerData) => {
    schedulerData.next();
    getWindows(this);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    // let step = view.viewType === ViewTypes.Custom ? 30 : 15;
    // schedulerData.setMinuteStep(step);
    schedulerData.config.customCellWidth =
      view.viewType === ViewTypes.Custom ? 15 : 30;
    console.log(schedulerData.config.customCellWidth);
    getWindows(this);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    schedulerData.prev();
    schedulerData.next();
    this.setState({
      viewModel: schedulerData,
    });
    getWindows(this);
  };

  eventClicked = (schedulerData, event) => {
    if (!this.state.invitationMode) {
      console.log(this.invitationMode);
      this.setState({
        windowEvent: event,
      });
      this.setState({ editWindow: true });
    }
  };

  inviteSend = (schedulerData, event) => {
    this.setState({
      windowEvent: event,
    });
    this.setState({ senderWindow: true });
  };

  inviteCreate = () => {
    this.setState({ invitationModal: true });
  };

  setInvitationMode = (state) => {
    this.setState({
      invitationMode: state,
    });
  };

  handleInvitationWindow = (event, window) => {
    if (event.target.checked) {
      let checked = { ...this.state.checkedInvitationWindowsIds };
      checked[window.id] = true;
      let sorted = [...this.state.invitationWindows, window];
      sorted.sort(function (a, b) {
        return new Date(a.start) - new Date(b.start);
      });
      this.setState({
        invitationWindows: sorted,
        checkedInvitationWindowsIds: checked,
      });
    } else {
      let windows = this.state.invitationWindows;
      let newWindows = [];
      let newIds = {};
      windows.forEach((w) => {
        if (w.id !== window.id) {
          newWindows.push(w);
          newIds[w.id] = true;
        }
      });
      this.setState({
        invitationWindows: newWindows,
        checkedInvitationWindowsIds: newIds,
      });
    }
  };

  ops2 = (schedulerData, event) => {
    alert(
      `You just executed ops2 to event: {id: ${event.id}, title: ${event.title}}`
    );
  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    let newFreshId = 0;
    schedulerData.events.forEach((item) => {
      if (item.id >= newFreshId) newFreshId = item.id + 1;
    });

    let newEvent = {
      id: newFreshId,
      title: "Nowe okno",
      start: start,
      end: end,
      resourceId: slotId,
      //clientId: 0,
      type: "Rozładunek",
      bgColor: "#2ecc71",
      carrier: "",
    };
    schedulerData.addEvent(newEvent);
    this.setState({
      viewModel: schedulerData,
    });
  };

  updateEventStart = (schedulerData, event, newStart) => {
    schedulerData.updateEventStart(event, newStart);
    this.setState({
      viewModel: schedulerData,
    });
    updateWindows(this, { id: event.id, start: newStart });
  };

  updateEventEnd = (schedulerData, event, newEnd) => {
    schedulerData.updateEventEnd(event, newEnd);
    this.setState({
      viewModel: schedulerData,
    });
    updateWindows(this, { id: event.id, end: newEnd });
  };

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    schedulerData.moveEvent(event, slotId, slotName, start, end);
    this.setState({
      viewModel: schedulerData,
    });
    updateWindows(this, {
      id: event.id,
      start: start,
      end: end,
      resourceId: slotId,
    });
  };

  getCustomDate = (schedulerData, num, date = undefined) => {
    const { viewType } = schedulerData;
    let selectDate = schedulerData.startDate;

    schedulerData.setViewType(
      schedulerData.viewType,
      schedulerData.showAgenda,
      schedulerData.isEventPerspective
    );

    if (date !== undefined) selectDate = date;
    console.log("custom");
    let startDate =
        num === 0
          ? selectDate
          : schedulerData
              .localeMoment(selectDate)
              .add(2 * num, "days")
              .format(DATE_FORMAT),
      endDate = schedulerData
        .localeMoment(startDate)
        .add(1, "days")
        .format(DATE_FORMAT),
      cellUnit = CellUnits.Hour;
    if (viewType === ViewTypes.Custom) {
      schedulerData
        .localeMoment(selectDate)
        .add(2 * num, "days")
        .format(DATE_FORMAT);
      endDate = schedulerData
        .localeMoment(startDate)
        .add(1, "days")
        .format(DATE_FORMAT);
      cellUnit = CellUnits.Hour;
    } else if (viewType === ViewTypes.Custom1) {
      schedulerData
        .localeMoment(selectDate)
        .add(2 * num, "days")
        .format(DATE_FORMAT);
      endDate = schedulerData.localeMoment(startDate).format(DATE_FORMAT);
      cellUnit = CellUnits.Hour;
    }

    return {
      startDate,
      endDate,
      cellUnit,
    };
  };

  getSummary = (
    schedulerData,
    headerEvents,
    slotId,
    slotName,
    headerStart,
    headerEnd
  ) => {
    let text = headerEvents.length.toString();
    let color = "#d9d9d9";
    if (headerEvents.length > 0)
      color = headerEvents.length <= 1 ? "green" : "red";
    return { text: text, color: color, fontSize: "12px" };
  };

  conflictOccurred = (
    schedulerData,
    action,
    event,
    type,
    slotId,
    slotName,
    start,
    end
  ) => {
    alert(`Okna czasowe nakładają się`);
  };

  isNonWorkingTime = (schedulerData, time) => {
    const { localeMoment } = schedulerData;
    if (schedulerData.cellUnit === CellUnits.Hour) {
      let hour = localeMoment(time).hour();
      if (hour < 8 || hour > 15) return true;
    } else {
      let dayOfWeek = localeMoment(time).weekday();
      if (dayOfWeek === 5 || dayOfWeek === 6) return true;
    }

    return false;
  };

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData,
    });
  };
  eventItemPopoverTemplateResolver = (
    schedulerData,
    eventItem,
    title,
    start,
    end,
    statusColor
  ) => {
    let name = "Nie przydzielono";
    if (eventItem.client) {
      name = eventItem.client.name;
    }
    let clientColor = "#ddd";
    switch (eventItem.state) {
      case "Powiadomiono":
        clientColor = "#009688";
        break;
      case "Nie powiadomiono":
        clientColor = "#E91E63";
        break;
      case "Potwierdzone":
        clientColor = "#8BC34A";
        break;
      case "Zakończone":
        clientColor = "#009688";
        break;
      case "Zaproszono":
        clientColor = "#FFEB3B";
        break;
      default:
        clientColor = "#ddd";
        break;
    }
    let type = "Rozładunek";
    if (eventItem.type === "Załadunek") {
      type = "Załadunek";
    }
    let eventsProducts = [];
    if (eventItem.products) {
      eventItem.products.map((prod) => {
        eventsProducts.push(
          <Box>
            <Typography variant="body2" style={{ marginBottom: 10 }}>
              {prod.count} x {prod.name}
            </Typography>
          </Box>
        );
      });
    }
    return (
      // <React.Fragment>
      //     <h3>{title}</h3>
      //     <h5>{start.format("HH:mm")} - {end.format("HH:mm")}</h5>
      //     <img src="./icons8-ticket-96.png" />
      // </React.Fragment>
      <div style={{ width: "300px" }}>
        <Grid container>
          <Grid container item xs={12} spacing={13}>
            <PersonIcon style={{ marginRight: 10 }} />
            <span
              className="header2-text"
              style={{
                float: "left",
                display: "inline-flex",
                "align-items": "center",
              }}
              title={name}
            >
              Kontrahent:&nbsp;<span style={{ fontWeight: 600 }}>{name}</span>
            </span>
          </Grid>
          <Grid container item xs={12} spacing={13}>
            <CompareArrowsIcon style={{ marginRight: 10 }} />
            <span
              className="header2-text"
              style={{
                float: "left",
                display: "inline-flex",
                "align-items": "center",
              }}
              title={type}
            >
              Typ:&nbsp;<span style={{ fontWeight: 600 }}>{type}</span>
            </span>
          </Grid>
          {(eventItem.client || eventItem.state === "Zaproszono") && (
            <Grid container item xs={12} spacing={13}>
              <FiberManualRecordIcon
                style={{ color: clientColor, marginRight: 10 }}
              />
              <span
                className="header2-text"
                style={{
                  float: "left",
                  display: "inline-flex",
                  "align-items": "center",
                }}
                title={type}
              >
                Stan:&nbsp;
                <span style={{ fontWeight: 600 }}>{eventItem.state}</span>
              </span>
            </Grid>
          )}
          <Grid container style={{ marginTop: 10 }} item xs={12} spacing={13}>
            <span className="header1-text" style={{ marginBottom: 20 }}>
              {start.format("HH:mm")} - {end.format("HH:mm")}
            </span>
          </Grid>
          {eventsProducts}
        </Grid>
      </div>
    );
  };
  eventItemTemplateResolver = (
    schedulerData,
    event,
    bgColor,
    isStart,
    isEnd,
    mustAddCssClass,
    mustBeHeight,
    agendaMaxEventWidth
  ) => {
    let borderWidth = isStart ? "4" : "4";
    let borderColor = "rgba(0,139,236,1)",
      backgroundColor = "#80C5F6";
    let clientColor = "#ddd";
    if (event.isSalesWindow) {
      event.client = event.customer;
    }
    switch (event.state) {
      case "Powiadomiono":
        clientColor = "#009688";
        break;
      case "Nie powiadomiono":
        clientColor = "#E91E63";
        break;
      case "Potwierdzone":
        clientColor = "#8BC34A";
        break;
      case "Zakończone":
        clientColor = "#009688";
        break;
      case "Zaproszono":
        clientColor = "#FFEB3B";
        break;
      default:
        clientColor = "#ddd";
        break;
    }
    if (!!event.client) {
      borderColor = event.client ? "rgba(0,139,236,1)" : "#999";
      backgroundColor = event.client ? bgColor : "#cccccc";
    }
    if (!event.client && event.state !== "Zaproszono") {
      clientColor = "#ddd";
    }
    let divStyle = {
      borderLeft: borderWidth + "px solid " + borderColor,
      borderRight: borderWidth + "px solid " + borderColor,
      backgroundColor: backgroundColor,
      height: mustBeHeight,
    };
    if (!!agendaMaxEventWidth)
      divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
    let type = "R";
    if (event.type === "Załadunek") {
      type = "Z";
    }
    let clientName = "Wolne";
    if (event.client?.name) {
      clientName = event.client.name;
    }
    return (
      <div key={event.id} className={mustAddCssClass} style={divStyle}>
        {this.state.invitationMode && !event.client && (
          <Checkbox
            onChange={(clickEvent) => {
              this.handleInvitationWindow(clickEvent, event);
            }}
            checked={this.state.checkedInvitationWindowsIds[event.id]}
            inputProps={{ "aria-label": "primary checkbox" }}
            size="small"
            style={{ padding: 5 }}
          />
        )}
        {!this.state.invitationMode && (
          <div>
            <div
              className="status-dot"
              style={{ ...dotStyle, backgroundColor: clientColor }}
            />
            <span
              style={{ marginLeft: "18px", lineHeight: `${mustBeHeight}px` }}
            >{`[${type}] ${clientName}`}</span>
          </div>
        )}
      </div>
    );
  };
}
export default withWidth()(withDragDropContext(SchedulerWrapper));
