import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ScheduleIcon from "@material-ui/icons/Schedule";
import HomeIcon from "@material-ui/icons/Home";
import SettingsIcon from "@material-ui/icons/Settings";
import Divider from "@material-ui/core/Divider";
import { Axios } from "./middleware/axiosInstance";
import {
  AccessTime,
  Category,
  DepartureBoard,
  Event,
  Folder,
  Group,
  ListAlt,
  ShoppingCart,
} from "@material-ui/icons";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../config.json")["API_HOST"][env];
function getEmployees(setAccess) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/users/get/access`,
    params: { userType: {} },
  }).then((res) => {
    if (!res.data.err) {
      setAccess(res.data.data);
      console.log(res.data.data);
    } else {
      console.log(res.data.msg);
    }
  });
}
export default function Menu({ showBox = (f) => f }) {
  const [access, setAccess] = React.useState([]);
  React.useEffect(() => {
    getEmployees(setAccess);
  }, []);
  return (
    <div>
      <ListItem button onClick={() => showBox("home")}>
        <ListItemIcon>
          <HomeIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Start" />
      </ListItem>
      <Divider />
      {access.includes("windows_edit") && (
        <ListItem button onClick={() => showBox("windows")}>
          <ListItemIcon>
            <DepartureBoard color="primary" />
          </ListItemIcon>
          <ListItemText primary="Awizacja" />
        </ListItem>
      )}
      {access.includes("windows_preview") && (
        <ListItem button onClick={() => showBox("windows_preview")}>
          <ListItemIcon>
            <Event color="primary" />
          </ListItemIcon>
          <ListItemText primary="Terminarz" />
        </ListItem>
      )}
      {access.includes("delivery") && (
        <ListItem button onClick={() => showBox("deliveries")}>
          <ListItemIcon>
            <LocalShippingIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Dostawy" />
        </ListItem>
      )}
      <Divider />
      {access.includes("sales_clients") && (
        <ListItem button onClick={() => showBox("sales_clients")}>
          <ListItemIcon>
            <Group color="primary" />
          </ListItemIcon>
          <ListItemText primary="Sprzedaż" />
        </ListItem>
      )}
      {access.includes("sales_settings") && (
        <ListItem button onClick={() => showBox("sales_settings")}>
          <ListItemIcon>
            <ListAlt color="primary" />
          </ListItemIcon>
          <ListItemText primary="Produkty" />
        </ListItem>
      )}

      {access.includes("admin") && (
        <div>
          <Divider />
          <ListItem button onClick={() => showBox("admin")}>
            <ListItemIcon>
              <SettingsIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Administracja" />
          </ListItem>
        </div>
      )}
    </div>
  );
}
