import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Axios } from ".././middleware/axiosInstance";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../../config.json")["API_HOST"][env];

function getClients(setClients) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/client/get/all`,
  }).then((res) => {
    console.log(res.data);
    if (res.data.data[0]) {
      setClients(res.data.data);
    }
  });
}

function getClientData(clientId, setClientData) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/client/get`,
    params: { id: clientId },
  }).then((res) => {
    console.log(res.data);
    if (res.data.data[0]) {
      setClientData(res.data.data[0]);
    }
  });
}

function saveInvite(
  windows,
  client,
  email,
  emailCustomContent,
  close,
  updateData
) {
  let windowIds = [];
  windows.forEach((w) => {
    windowIds.push(w.id);
  });
  let invitation = {
    windowIds: windowIds,
    client: client,
    email: email,
    content: emailCustomContent,
  };
  console.log(invitation);
  Axios({
    method: "POST",
    withCredentials: true,
    url: `${API_HOST}/invitation/add`,
    data: invitation,
  }).then((res) => {
    console.log(res.data);
    updateData();
    close();
  });
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: 20,
  },
  marginRight: {
    marginRight: 20,
  },
  inviteContainer: {
    position: "relative",
    float: "left",
    backgroundColor: "rgb(128, 197, 246)",
    borderRight: "4px solid rgb(0, 139, 236)",
    borderLeft: "4px solid rgb(0, 139, 236)",
    marginBottom: 10,
    marginRight: "1%",
    padding: 4,
    width: "49%",
  },
  inviteDate: {
    fontSize: 14,
    color: "#252525",
    float: "left",
    padding: 2,
    width: "100%",
  },
  inviteTime: {
    fontSize: 20,
    color: "#252525",
    float: "left",
    paddingLeft: 2,
    width: "100%",
  },
  inviteRamp: {
    fontSize: 14,
    color: "#252525",
    float: "left",
    paddingLeft: 2,
  },
  inviteIndentTop: {
    position: "absolute",
    transform: "translate(0px, -50%)",
    borderRadius: 10,
    zIndex: 2,
    width: 10,
    height: 10,
    backgroundColor: "white",
    right: 60,
    top: 0,
  },
  inviteIndentBottom: {
    position: "absolute",
    transform: "translate(0px, 50%)",
    borderRadius: 10,
    zIndex: 2,
    width: 10,
    height: 10,
    backgroundColor: "white",
    right: 60,
    bottom: 0,
  },
  rightContainer: {
    position: "absolute",
    height: "100%",
    width: 66,
    top: 0,
    right: 0,
    float: "right",
    backgroundColor: "#95d3ff",
    zIndex: 1,
  },
  icon: {
    color: "white",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
  },
  width100FL: {
    width: "100%",
    float: "left",
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({
  invitationWindows,
  open,
  onClose = (f) => f,
  updateData = (f) => f,
}) {
  const [client, setClientData] = React.useState("Nie przydzielono");
  const [clients, setClients] = React.useState([]);
  const [windows, setWindows] = React.useState(invitationWindows);
  const [content, setContent] = React.useState("");
  const [email, setEmail] = React.useState("");
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleContentChange = (event) => {
    setContent(event.target.value);
  };
  const handleClientChange = (value) => {
    if (value) {
      setClientData(value);
      console.log(value.email);
      setEmail(value.email);
    }
  };
  React.useEffect(() => {
    setWindows(invitationWindows);
    getClients(setClients);
  }, [invitationWindows]);
  const classes = useStyles();
  const handleWindowDelete = (id) => {
    let newWindows = [];
    windows.forEach((window) => {
      if (!(window.id === id)) {
        newWindows.push(window);
      }
    });
    setWindows(newWindows);
  };
  const windowList = windows.map((window) => {
    let date = new Date(window.start);
    date = date.toLocaleDateString(undefined, {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    let start = new Date(window.start);
    start = start.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    let end = new Date(window.end);
    end = end.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    return (
      <div key={window.id} className={classes.inviteContainer}>
        <div className={classes.inviteIndentTop}></div>
        <div className={classes.rightContainer}>
          <IconButton
            color="primary"
            className={classes.icon}
            onClick={() => {
              handleWindowDelete(window.id);
            }}
            aria-label="remove window"
            component="span"
          >
            <DeleteIcon />
          </IconButton>
        </div>
        <span className={classes.inviteDate}>{date}</span>
        <span className={classes.inviteTime}>{`${start} - ${end}`}</span>
        <span
          className={classes.inviteRamp}
        >{`Lokalizacja: ${window.resourceId}`}</span>
        <div className={classes.inviteIndentBottom}></div>
      </div>
    );
  });
  return (
    <div>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          Kreator zaproszenia do awizacji:
        </DialogTitle>
        <DialogContent dividers>
          <h3 className={classes.width100FL}>Wybrane okna:</h3>
          {windowList}
          <h3 className={classes.width100FL}>Adresat:</h3>
          <Autocomplete
            //id="checkboxes-tags-demo"
            limitTags={3}
            onChange={(event, value) => handleClientChange(value)}
            options={clients}
            defaultValue={client}
            value={client}
            getOptionLabel={(option) => option.name}
            className={classes.marginBottom}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Kontrahent"
                placeholder={client.name}
              />
            )}
          />
          <TextField
            label="E-mail"
            fullWidth={true}
            className={classes.marginBottom}
            onChange={handleEmailChange}
            defaultValue={email}
            value={email}
          />
          <TextField
            label="Treść dodatkowa"
            fullWidth={true}
            className={classes.marginBottom}
            onChange={handleContentChange}
            defaultValue={content}
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              saveInvite(windows, client, email, content, onClose, updateData);
            }}
            color="primary"
          >
            Wyślij
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
