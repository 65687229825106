import React from "react";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import moment from "moment";
import { Axios } from "./middleware/axiosInstance";
import FormDialog from "./FormDialog";
import { Box, ButtonGroup, Divider, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../config.json")["API_HOST"][env];

function getRampData(rampId, setRampName) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/ramp/get`,
    params: { id: rampId },
  }).then((res) => {
    console.log(res.data);
    if (res.data.data[0]) {
      setRampName(res.data.data[0].name);
    }
  });
}
function getClientData(clientId, setClientData) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/client/get`,
    params: { _id: clientId },
  }).then((res) => {
    console.log(res.data);
    if (res.data.data[0]) {
      setClientData(res.data.data[0]);
    }
  });
}
function getClients(setClientsFull, setClients) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/client/get/all`,
  }).then((res) => {
    console.log(res.data);
    if (res.data.data[0]) {
      setClients(res.data.data);
      setClientsFull(
        res.data.data.map((c) => {
          return c.name;
        })
      );
    }
  });
}

function saveData(
  id,
  title,
  client,
  start,
  end,
  carrier,
  type,
  registrationNumber,
  phone,
  numberOfPallets,
  description,
  products,
  trackingLink,
  orderState,
  close,
  updateData
) {
  let window = {};
  window.id = id;
  window.clientId = client._id;
  window.start = start;
  window.end = end;
  window.title = title;
  window.carrier = carrier;
  window.type = type;
  window.registrationNumber = registrationNumber;
  window.phone = phone;
  window.numberOfPallets = numberOfPallets;
  window.products = products;
  window.description = description;
  window.trackingLink = trackingLink;
  window.state = orderState;
  console.log(window);
  Axios({
    method: "PUT",
    withCredentials: true,
    url: `${API_HOST}/window/update`,
    params: window,
  }).then((res) => {
    console.log(res.data);
    updateData();
    close();
  });
}

const types = [{ name: "Rozładunek" }, { name: "Załadunek" }];

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: 20,
  },
  marginRight: {
    marginRight: 20,
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const StyledToggleButton1 = withStyles({
  root: {
    fontFamily: "Arial",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    padding: "10px 10px",
    width: "100%",
    "&$selected": {
      backgroundColor: "rgba(33, 137, 214, 0.14)",
      "&:hover": {
        backgroundColor: "rgba(33, 137, 214, 0.14)",
      },
    },
  },
  selected: {},
})(ToggleButton);
const StyledToggleButton2 = withStyles({
  root: {
    fontFamily: "Arial",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    padding: "10px 10px",
    width: "100%",
    "&$selected": {
      backgroundColor: "rgba(255, 102, 52, 0.14)",
      "&:hover": {
        backgroundColor: "rgba(255, 102, 52, 0.14)",
      },
    },
  },
  selected: {},
})(ToggleButton);
const StyledToggleButton3 = withStyles({
  root: {
    fontFamily: "Arial",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    padding: "10px 10px",
    width: "100%",
    "&$selected": {
      backgroundColor: "rgba(127, 255, 136, 0.14)",
      "&:hover": {
        backgroundColor: "rgba(127, 255, 136, 0.14)",
      },
    },
  },
  selected: {},
})(ToggleButton);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({
  window,
  open,
  onClose = (f) => f,
  updateData = (f) => f,
}) {
  const theme = useTheme();
  const [state, setState] = React.useState({ checkedB: true });
  const [startDate, setStartDate] = React.useState("1");
  const [windowTitle, setWindowTitle] = React.useState("1");
  const [endDate, setEndDate] = React.useState("1");
  const [rampName, setRampName] = React.useState("Ładowanie...");

  const [orderState, setOrderState] = React.useState("Przyjęte");
  const [client, setClientData] = React.useState("Nie przydzielono");
  const [registrationNumber, setRegistrationNumber] = React.useState("");
  const [numberOfPallets, setnumberOfPallets] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [trackingLink, setTrackingLink] = React.useState("");
  const [phone, setPhone] = React.useState(0);
  const [clients, setClients] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [clientsFullData, setClientsFullData] = React.useState({});
  const [carrierName, setCarrierName] = React.useState("");
  const [type, setType] = React.useState("Rozładunek");
  const handleTitleChange = (event) => {
    setWindowTitle(event.target.value);
  };
  const handleStartChange = (event) => {
    setStartDate(event.target.value);
  };
  const handleEndChange = (event) => {
    setEndDate(event.target.value);
  };
  const handleClientChange = (value) => {
    if (value) {
      setClientData(value);
    }
  };
  const handleTypeChange = (value) => {
    if (value) {
      setType(value);
    }
  };
  const handleCarrierChange = (event) => {
    setCarrierName(event.target.value);
  };
  const handleOrderStateChange = (event, newState) => {
    setOrderState(newState);
  };
  const handlenumberOfPalletsChange = (event) => {
    setnumberOfPallets(event.target.value);
  };
  const handleRegistrationNumberChange = (event) => {
    setRegistrationNumber(event.target.value);
  };
  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleTrackingLinkChange = (event) => {
    setTrackingLink(event.target.value);
  };
  const handleProductsUpdate = (value) => {
    setProducts(value);
  };
  const defaultProps = {
    options: clients,
    getOptionLabel: (option) => option.name,
  };
  React.useEffect(() => {
    console.log(window);
    let startDate = moment
      .utc(window.start.slice(0, 16))
      .local()
      .format("YYYY-MM-DDTHH:mm"); //data prawidłowa dla danych pobranych z bazy. BigScheduler ma datę bez strefy czasowej.
    let endDate = moment
      .utc(window.end.slice(0, 16))
      .local()
      .format("YYYY-MM-DDTHH:mm");
    setStartDate(startDate);
    setEndDate(endDate);
    setWindowTitle(window.title);
    setRegistrationNumber(window.registrationNumber);
    setPhone(window.phone);
    setProducts(window.products);
    setnumberOfPallets(window.numberOfPallets);
    setDescription(window.description);
    setTrackingLink(window.trackingLink);
    setOrderState(window.state);
    getRampData(window.resourceId, setRampName);
    getClientData(window.clientId, setClientData);
    getClients(setClientsFullData, setClients);
  }, [window, window.start, window.end]);
  // React.useEffect(() => {
  //   if (!window.clientId) {
  //     setClientData("");
  //   } else {
  //     setClientData(client);
  //   }
  // }, [window.clientId, client]);
  const classes = useStyles();
  return (
    <div>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          Okno: {window.title}
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            id="standard"
            label="Nazwa"
            fullWidth={true}
            className={classes.marginBottom}
            onChange={handleTitleChange}
            defaultValue={window.title}
          />
          <TextField
            id="datetime-local"
            label="Start"
            type="datetime-local"
            className={clsx(classes.marginRight, classes.marginBottom)}
            defaultValue={startDate}
            onChange={handleStartChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="datetime-local"
            label="Koniec"
            type="datetime-local"
            defaultValue={endDate}
            onChange={handleEndChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {!window.isSalesWindow ? (
            <Autocomplete
              //id="checkboxes-tags-demo"
              limitTags={3}
              onChange={(event, value) => handleClientChange(value)}
              options={clients}
              defaultValue={window.client}
              getOptionLabel={(option) => option.name}
              className={classes.marginBottom}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Kontrahent"
                  placeholder={client.name}
                />
              )}
            />
          ) : (
            <div>
              <Divider style={{ marginTop: 15, marginBottom: 20 }} />
              <Typography
                variant="caption"
                style={{ color: theme.palette.grey[400] }}
              >
                Klient:
              </Typography>
              <Typography>{window.customer?.name}</Typography>
              <Divider style={{ marginTop: 15, marginBottom: 20 }} />
              <Grid container spacing={1} direction="column" alignItems="left">
                <Grid item>
                  <Typography
                    variant="caption"
                    style={{ color: theme.palette.grey[400] }}
                    gutterBottom
                  >
                    Status zamówienia:
                  </Typography>
                </Grid>
                <Grid item>
                  <ToggleButtonGroup
                    value={orderState}
                    exclusive
                    onChange={handleOrderStateChange}
                    aria-label="order state"
                    size="small"
                    color="primary"
                  >
                    <StyledToggleButton1 value="Przyjęte" aria-label="przyjęte">
                      <Typography
                        variant="body"
                        style={{ color: theme.palette.warning }}
                      >
                        Przyjęte
                      </Typography>
                    </StyledToggleButton1>
                    <StyledToggleButton2 value="Wysłane" aria-label="wysłane">
                      <Typography
                        variant="body"
                        style={{ color: theme.palette.primary }}
                      >
                        Wysłane
                      </Typography>
                    </StyledToggleButton2>
                    <StyledToggleButton3
                      value="Dostarczone"
                      aria-label="dostarczone"
                    >
                      <Typography
                        variant="body"
                        style={{ color: theme.palette.success }}
                      >
                        Dostarczone
                      </Typography>
                    </StyledToggleButton3>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </div>
          )}

          {!window.isSalesWindow ? (
            <div>
              <Autocomplete
                onChange={(event, value) => handleTypeChange(value)}
                options={types}
                defaultValue={window.type}
                getOptionLabel={(option) => option.name}
                className={classes.marginBottom}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Typ"
                    placeholder={window.type}
                  />
                )}
              />

              <TextField
                id="standard"
                label="Przewoźnik"
                fullWidth={true}
                className={classes.marginBottom}
                onChange={handleCarrierChange}
                defaultValue={window.carrier}
              />

              <TextField
                id="registrationNumber"
                label="Numer Rejestracyjny Pojazdu:"
                type="text"
                defaultValue={registrationNumber}
                onChange={handleRegistrationNumberChange}
                className={clsx(classes.marginRight, classes.marginBottom)}
                style={{ width: "45%" }}
              />
              <TextField
                id="phone"
                label="Telefon:"
                type="tel"
                defaultValue={phone}
                onChange={handlePhoneChange}
                className={clsx(classes.marginBottom)}
                style={{ width: "45%" }}
              />
              <TextField
                id="numberOfPallets"
                label="Ilość Palet:"
                type="number"
                defaultValue={numberOfPallets}
                onChange={handlenumberOfPalletsChange}
                className={clsx(classes.marginBottom)}
                fullWidth={true}
              />
            </div>
          ) : (
            <TextField
              id="trackingLink"
              label="Link do śledzenia przesyłki:"
              type="text"
              defaultValue={trackingLink}
              onChange={handleTrackingLinkChange}
              fullWidth={true}
              style={{ marginBottom: 20 }}
            />
          )}
          <TextField
            id="description"
            label="Uwagi:"
            type="text"
            defaultValue={description}
            onChange={handleDescriptionChange}
            fullWidth={true}
            style={{ marginBottom: 20 }}
          />
          <FormDialog
            onProductsUpdate={handleProductsUpdate}
            products={products}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              saveData(
                window.id,
                windowTitle,
                client,
                startDate,
                endDate,
                carrierName,
                type,
                registrationNumber,
                phone,
                numberOfPallets,
                description,
                products,
                trackingLink,
                orderState,
                onClose,
                updateData
              );
            }}
            color="primary"
          >
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
