import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ScheduleIcon from "@material-ui/icons/Schedule";
import HomeIcon from "@material-ui/icons/Home";
import SettingsIcon from "@material-ui/icons/Settings";
import { Axios } from "./middleware/axiosInstance";
import {
  AccessTime,
  Category,
  CategoryOutlined,
  DepartureBoard,
  DepartureBoardOutlined,
  Event,
  EventOutlined,
  HomeOutlined,
  LocalShippingOutlined,
  MoreHoriz,
  MoreHorizOutlined,
  SettingsOutlined,
  ShoppingCart,
  ShoppingCartOutlined,
} from "@material-ui/icons";
import { styled } from "@material-ui/styles";
import { Menu, MenuItem } from "@material-ui/core";
const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
  button: {
    minWidth: 0,
    padding: "0 !important",
  },
  ".Mui-selected": {
    padding: 0,
  },
});

const env = process.env.REACT_APP_ENV_HOST;
const API_HOST = require("../config.json")["API_HOST"][env];
function getEmployees(setAccessMain, setAccessAdd) {
  Axios({
    method: "GET",
    withCredentials: true,
    url: `${API_HOST}/users/get/access`,
    params: { userType: {} },
  }).then((res) => {
    if (!res.data.err) {
      let access = res.data.data;
      let startAccessModules;
      let endAccessModules;
      if (access.length > 5) {
        startAccessModules = access.slice(0, 3);
        endAccessModules = access.slice(3, access.length);
        setAccessMain(startAccessModules);
        setAccessAdd(endAccessModules);
      } else {
        setAccessMain(access);
      }

      console.log(res.data.data);
    } else {
      console.log(res.data.msg);
    }
  });
}
export default function SimpleBottomNavigation({ showBox = (f) => f }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [accessMain, setAccessMain] = React.useState([]);
  const [accessAdd, setAccessAdd] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMore = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  React.useEffect(() => {
    getEmployees(setAccessMain, setAccessAdd);
  }, []);
  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        label="Start"
        icon={<HomeOutlined color="primary" />}
        onClick={() => showBox("home")}
        className={classes.button}
      />
      {accessMain.includes("windows_edit") && (
        <BottomNavigationAction
          label="Awizacja"
          icon={<DepartureBoardOutlined color="primary" />}
          onClick={() => showBox("windows")}
          className={classes.button}
        />
      )}
      {accessMain.includes("windows_preview") && (
        <BottomNavigationAction
          label="Terminarz"
          icon={<EventOutlined color="primary" />}
          onClick={() => showBox("windows_preview")}
          className={classes.button}
        />
      )}
      {accessMain.includes("delivery") && (
        <BottomNavigationAction
          label="Dostawy"
          icon={<LocalShippingOutlined color="primary" />}
          onClick={() => showBox("deliveries")}
          className={classes.button}
        />
      )}
      {accessMain.includes("sales_clients") && (
        <BottomNavigationAction
          label="Sprzedaż"
          icon={<ShoppingCartOutlined color="primary" />}
          onClick={() => showBox("sales_clients")}
          className={classes.button}
        />
      )}
      {accessMain.includes("sales_settings") && (
        <BottomNavigationAction
          label="Produkty"
          icon={<CategoryOutlined color="primary" />}
          onClick={() => showBox("sales_settings")}
          className={classes.button}
        />
      )}
      {accessMain.includes("admin") && (
        <BottomNavigationAction
          label="Ustawienia"
          icon={<SettingsOutlined color="primary" />}
          onClick={() => showBox("admin")}
          className={classes.button}
        />
      )}
      {accessAdd.length > 0 && (
        <BottomNavigationAction
          label="Więcej"
          icon={<MoreHorizOutlined color="primary" />}
          onClick={handleOpenMenu}
          className={classes.button}
        />
      )}
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={openMore}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {accessAdd.includes("windows_edit") && (
          <MenuItem
            onClick={() => {
              showBox("windows");
              handleCloseMenu();
            }}
          >
            Awizacja
          </MenuItem>
        )}
        {accessAdd.includes("windows_preview") && (
          <MenuItem
            onClick={() => {
              showBox("windows_preview");
              handleCloseMenu();
            }}
          >
            Terminarz
          </MenuItem>
        )}
        {accessAdd.includes("delivery") && (
          <MenuItem
            onClick={() => {
              showBox("deliveries");
              handleCloseMenu();
            }}
          >
            Dostawy
          </MenuItem>
        )}
        {accessAdd.includes("sales_clients") && (
          <MenuItem
            onClick={() => {
              showBox("sales_clients");
              handleCloseMenu();
            }}
          >
            Sprzedaż
          </MenuItem>
        )}
        {accessAdd.includes("sales_settings") && (
          <MenuItem
            onClick={() => {
              showBox("sales_settings");
              handleCloseMenu();
            }}
          >
            Produkty
          </MenuItem>
        )}
        {accessAdd.includes("admin") && (
          <MenuItem
            onClick={() => {
              showBox("admin");
              handleCloseMenu();
            }}
          >
            Administracja
          </MenuItem>
        )}
      </Menu>
    </BottomNavigation>
  );
}
